/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Utilities for interacting with WebRTC Core Layer / Vendors
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Functions.Utils
 */

// Helper to determine if we should show the warning message before we request device permissions
export function shouldShowPermissionWarning(deviceInfos: MediaDeviceInfo[]): boolean {
	const cameraDevices = deviceInfos.filter((deviceInfo) => deviceInfo.kind === "videoinput");
	const microphoneDevices = deviceInfos.filter((deviceInfo) => deviceInfo.kind === "audioinput");

	const hasCamera = cameraDevices.length > 0;
	const hasMicrophone = microphoneDevices.length > 0;

	const cameraPermissionsAllowed = cameraDevices.filter((deviceInfo) => deviceInfo.label !== "").length > 0;
	const microphonePermissionsAllowed =
		microphoneDevices.filter((deviceInfo) => deviceInfo.label !== "").length > 0;

	// Browsers don't seem to ask for permissions if devices are missing
	const willAskForCamera = hasCamera && !cameraPermissionsAllowed;
	const willAskForMicrophone = hasMicrophone && !microphonePermissionsAllowed;

	return willAskForCamera || willAskForMicrophone;
}
