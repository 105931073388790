/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Handles messages from remote data tracks (one per remote participant)
 * @author Will Cooper
 * @module Epic.VideoApp.Components.VideoCall.DataTracks.DataTrackListener
 */

import { FC, memo, useCallback, useContext, useEffect, useRef } from "react";
import { DataTrack as IDataTrack } from "twilio-video";
import { useRequestParticipantInfo, useSendNamelessParticipantSyncRequest } from "~/hooks/messaging";
import { useRoomState } from "~/state";
import { secondsToMs } from "~/utils/dateTime";
import { VideoCallContext } from "~/web-core/components";
import { INamelessParticipantData } from "../hooks/useNamelessParticipantData";

interface IDataTrackProps {
	track: IDataTrack;
	participantIdentity: string;
	messageHandler: (message: string, participantIdentity: string) => void;
}

export const ParticipantInfoInterval = secondsToMs(1);

const DataTrackListener: FC<IDataTrackProps> = memo((props: IDataTrackProps) => {
	const { track, participantIdentity, messageHandler } = props;
	const { namelessParticipantData } = useContext(VideoCallContext);
	const sendNamelessParticipantSyncRequest = useSendNamelessParticipantSyncRequest();
	const namelessRef = useRef<INamelessParticipantData>(namelessParticipantData);
	const requestParticipantInfo = useRequestParticipantInfo();
	const participantInfo = useRoomState(
		(selectors) => selectors.getParticipantInfo(participantIdentity),
		[],
	);
	const participantInfoRef = useRef(participantInfo);

	const makeInfoRequest = useCallback(() => {
		// If we do not have a valid entry of participant info for a given user, make a request
		// Does not need a response
		if (!participantInfoRef.current) {
			requestParticipantInfo(participantIdentity);
		}

		// If nameless participant data hasn't been shared, make a request
		if (!namelessRef.current?.dataIsShared) {
			sendNamelessParticipantSyncRequest(namelessParticipantData);
		}
	}, [
		namelessParticipantData,
		participantIdentity,
		requestParticipantInfo,
		sendNamelessParticipantSyncRequest,
	]);

	const requestRef = useRef(makeInfoRequest);

	// Update reference when we get a value from a remote party
	useEffect(() => {
		participantInfoRef.current = participantInfo;
	}, [participantInfo]);

	// Track updates to the request function as well
	useEffect(() => {
		requestRef.current = makeInfoRequest;
	}, [makeInfoRequest]);

	useEffect(() => {
		namelessRef.current = namelessParticipantData;
	}, [namelessParticipantData]);

	// Check if we need to make a request for information from the remote participant who owns this data track.

	// Run this timer to periodically request info broadcast -- after we have participant info, stop.
	useEffect(() => {
		if (!participantInfoRef.current) {
			// Send an initial request
			requestRef.current();

			const newIntervalId = window.setInterval(() => {
				// Request until we have information, then clear the interval
				if (!participantInfoRef.current || !namelessRef.current?.dataIsShared) {
					requestRef.current();
				} else {
					clearInterval(newIntervalId);
				}
			}, ParticipantInfoInterval);
			return () => clearInterval(newIntervalId);
		}
	}, [namelessParticipantData.dataIsShared]);

	// Initialize the listener to receive messages from remote data track of this participant
	useEffect(() => {
		const handleMessage = (message: string): void => {
			//Pass message down to stateful interface
			messageHandler(message, participantIdentity);
		};
		track.on("message", handleMessage);
		return () => {
			track.off("message", handleMessage);
		};
	}, [messageHandler, participantIdentity, track]);

	return null; // This component does not return any HTML, so we will return 'null' instead.
});

export default DataTrackListener;
