/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Base interface for video call users
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Interfaces.User
 */

import { IEVCEmitter, IEVCUserEventMap } from "../events";
import { IStream } from "./stream";

/**
 * @interface IUser
 * @extends IEVCEmitter<IEVCUserEventMap>
 * @description Interface representing a video call user; to be extended by local and remote user interfaces.
 */
export interface IUser extends IEVCEmitter<IEVCUserEventMap> {
	/**
	 * @property deviceStream
	 * @type IStream
	 * @description The stream from the user's device.
	 */
	deviceStream: IStream;

	/**
	 * @property shareStream
	 * @type IStream | null
	 * @description The screen share stream that the user is sharing with others. It can be null if the user is not
	 * currently sharing their screen.
	 */
	shareStream: IStream | null;

	/**
	 * @method getUserIdentity
	 * @returns the unique identity of the user as a string.
	 */
	getUserIdentity: () => string;

	/**
	 * @method getUserGuid
	 * @returns the guid of the user as a string.
	 */
	getUserGuid: () => string;

	/**
	 * @method isSharingScreen
	 * @returns a boolean indicating whether the user is currently sharing their screen.
	 */
	isSharingScreen: () => boolean;

	/**
	 * @method getNetworkQualityLevel
	 * @returns the user's current network quality level as a number.
	 */
	getNetworkQualityLevel: () => number;
}
