/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Context to surface the session and local device stream to app components
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Components.VideoSessionProvider
 */

import { useDispatch } from "@epic/react-redux-booster";
import { navigate } from "@reach/router";
import React, { FC, useCallback, useEffect, useState } from "react";
import { getEndpointUrl } from "~/app/routes";
import { combinedActions, hardwareTestActions, useRoomState } from "~/state";
import { localTrackActions, useLocalTrackState } from "~/state/localTracks";
import { IStreamDevices } from "~/types";
import { warn } from "~/utils/logging";
import { initializeVendor } from "../functions/initializeVendor";
import { shouldShowPermissionWarning } from "../functions/utils";
import { useStoreError } from "../hooks/useStoreError";
import { useStream } from "../hooks/useStream";
import { ILocalUser } from "../interfaces";
import { ILocalStream } from "../interfaces/localStream";
import { ISession } from "../interfaces/session";
import DevicePermissionWarningOverlay from "./DevicePermissionWarningOverlay";

export interface IVideoContext {
	stream: ILocalStream | undefined;
	session: ISession | undefined;
}

export const VideoContext = React.createContext<IVideoContext>({
	stream: undefined,
	session: undefined,
});

/**
 * Context provider to surface the local user's stream and session to app components
 */
export const VideoSessionProvider: FC = (props) => {
	const { children } = props;

	const dispatch = useDispatch();
	const useLowBandwidth = useRoomState((selectors) => selectors.getIsLowBandwidthMode(), []);
	const vendor = useRoomState((selectors) => selectors.getVendor(), []);

	const trackAcquisitionStatus = useLocalTrackState(
		(selectors) => selectors.getLocalTrackAcquisitionStatus(),
		[],
	);

	const storeError = useStoreError();
	const [showWarning, setShowWarning] = useState<boolean>(false);
	const [user, setUser] = useState<ILocalUser>();
	const [session, setSession] = useState<ISession>();

	const stream = useStream(user, "camera") as ILocalStream;

	const initializeCallback = useCallback(async () => {
		try {
			const session = await initializeVendor(useLowBandwidth, vendor);
			setSession(session);
			setUser(session.localUser);

			const localStream = session.localUser.deviceStream;
			const deviceInitError = localStream.getDeviceInitializationError();

			// Check if there was an initialization error before setting specific device state
			if (deviceInitError) {
				dispatch(
					hardwareTestActions.setInitialHardwareState({
						deviceUpdates: deviceInitError.deviceUpdates,
						error: deviceInitError.error,
					}),
				);
				storeError(deviceInitError.error);
			} else {
				const initialDeviceInfo: IStreamDevices = {
					isAudioEnabled: localStream.isEnabled("audio"),
					isVideoEnabled: localStream.isEnabled("video"),
				};

				dispatch(combinedActions.setInitialHardwareTestState(initialDeviceInfo));
			}
		} catch (error) {
			warn(error);
			void navigate(getEndpointUrl("/Error"));
		}

		dispatch(localTrackActions.setLocalTrackAcquisitionStatus("finished"));
		setShowWarning(false);
	}, [dispatch, storeError, useLowBandwidth, vendor]);

	useEffect(() => {
		const runInitialize = async (): Promise<void> => {
			let showWarning = false;
			if (trackAcquisitionStatus === "notStarted") {
				try {
					// Use enumerateDevices to load permissions data
					const deviceInfos = await navigator.mediaDevices.enumerateDevices();
					showWarning = shouldShowPermissionWarning(deviceInfos);
					setShowWarning(showWarning);
				} catch (error) {
					// Continue if we get an error
				}
			}
			if (trackAcquisitionStatus === "notStarted" && !showWarning) {
				await initializeCallback();
			}
		};

		void runInitialize();
	}, [dispatch, initializeCallback, trackAcquisitionStatus]);

	// Use effect will let us try initialize whenever the stream updates
	useEffect(() => {
		if (!stream) {
			return;
		}

		const deviceInitError = stream.getDeviceInitializationError();

		if (deviceInitError) {
			return;
		}

		const streamDeviceInfo: IStreamDevices = {
			isAudioEnabled: stream.isEnabled("audio"),
			isVideoEnabled: stream.isEnabled("video"),
		};

		dispatch(combinedActions.setInitialHardwareTestState(streamDeviceInfo));
	}, [dispatch, session, stream]);

	return (
		<VideoContext.Provider
			value={{
				stream,
				session,
			}}
		>
			{showWarning && <DevicePermissionWarningOverlay onContinue={initializeCallback} />}
			{children}
		</VideoContext.Provider>
	);
};

VideoSessionProvider.displayName = "VideoSessionProvider";
