/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Interface for sending messages that will allow participants to join the full visit
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.Messaging.Moderation.UseSendVisitAccessMessage
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useContext } from "react";
import { useStrings } from "~/hooks/useStrings";
import { alertActions, useAuthState, useModerationState } from "~/state";
import { IModeratorPayload, IVisitAccessMessage, MessageActionType, MessageType } from "~/types";
import { stringFormat } from "~/utils/strings";
import { VideoContext } from "~/web-core/components";
import { sendMessage } from "../../../utils/sendMessage";

export interface ISendAccessMessage {
	(recipientIdentity: string, displayName: string): Promise<void>;
}

enum TokenNames {
	admitFailed = "AdmitFailed",
}

/**
 * A function that defines the data message send operation for allowing waiting participants to join the call
 * @returns a function to send access messages for the visit
 */
export function useSendVisitAccessMessage(): ISendAccessMessage {
	const { session } = useContext(VideoContext);
	const dispatch = useDispatch();
	const strings = useStrings("useSendVisitAccessMessage", Object.values(TokenNames));

	// Check flags in state for deny audio / deny video
	const audioLock = useModerationState((selectors) => selectors.getAudioAdmitOption(), []);
	const videoLock = useModerationState((selectors) => selectors.getVideoAdmitOption(), []);
	const screenShareLock = useModerationState((selectors) => selectors.getScreenShareAdmitOption(), []);
	const JWT = useAuthState((selectors) => selectors.getJWT(), []);

	const requestVisitAccess = useCallback(
		async (recipientIdentity: string, displayName: string) => {
			if (!JWT || !session) {
				return;
			}

			if (!(await session.enableUserMedia(recipientIdentity, JWT))) {
				dispatch(
					alertActions.postGenericAlert(stringFormat(strings[TokenNames.admitFailed], displayName)),
				);
				return;
			}

			const payload: IModeratorPayload = { audioLock, videoLock, screenShareLock };
			const message: IVisitAccessMessage = {
				action: MessageActionType.request,
				payload: payload,
				recipients: [recipientIdentity],
				type: MessageType.visitAccess,
				needsACK: false,
			};

			sendMessage(session, message);
		},
		[JWT, audioLock, dispatch, screenShareLock, session, strings, videoLock],
	);

	return requestVisitAccess;
}
