/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Options used for connecting to a video call
 * @author Chance Overberg
 * @module Epic.VideoApp.WebCore.Types.ConnectOptions
 */

import { DebuggingLogLevel } from "~/state/room";
import { ICallRoomInfo } from "~/types";

export interface IConnectOptions {
	info: ICallRoomInfo;
	logLevel: DebuggingLogLevel;
	isLowBandwidth: boolean;
	isInWaitingRoom: boolean;
	jwt?: string;
}
