/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file hook to get audio device message
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareSetup.Hooks.UseAudioDeviceMessage
 */

import { useContext, useEffect, useRef, useState } from "react";
import { useHardwareTestActions, useMicrophoneDevices, useStrings } from "~/hooks";
import { useHardwareTestState } from "~/state";
import { DeviceStatus, DeviceStatusSubtype, DisplayContext, Timeout } from "~/types";
import { VideoContext } from "~/web-core/components/VideoSessionProvider";
import { useAudioLevel } from "~/web-core/hooks/useAudioLevel";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { HardwareSetupDisplayContext } from "../HardwareSetup";

/** Local Constants */
const VOLUME_LEVEL_DETECTION_THRESHOLD = 1; // 1/10 volume
const VOLUME_DETECTION_RECENT_TIMEOUT = 5000; // 5 seconds

/**
 * Hook used to get an audio device message.
 * @returns a string describing the state of the audio device
 */
export function useAudioDeviceMessage(): string {
	const displayContext = useContext(HardwareSetupDisplayContext);
	const { stream } = useContext(VideoContext);
	const status = useHardwareTestState(
		(selectors) => selectors.getMicrophoneStatus(displayContext === DisplayContext.lobby),
		[],
	);
	const statusSubtype = useHardwareTestState((selectors) => selectors.getMicrophoneError(), []);
	const micCount = useMicrophoneDevices().length;
	const volumeLevel = useAudioLevel(stream);
	const [hasDetectedVolume, setDetectedVolume] = useState<boolean>(false);
	const [noRecentVolume, setNoRecentVolume] = useState<boolean>(false);
	const timeoutRef = useRef<Timeout>();
	const isEnabled = useIsStreamEnabled("audio", stream);

	useEffect(() => {
		if (volumeLevel >= VOLUME_LEVEL_DETECTION_THRESHOLD) {
			setDetectedVolume(true);
			setNoRecentVolume(false);
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
				timeoutRef.current = undefined;
			}
		}

		if (volumeLevel < VOLUME_LEVEL_DETECTION_THRESHOLD && isEnabled) {
			if (!timeoutRef.current) {
				timeoutRef.current = setTimeout(() => {
					setNoRecentVolume(true);
				}, VOLUME_DETECTION_RECENT_TIMEOUT);
			}
		}
	}, [isEnabled, volumeLevel]);

	const { updateMicTestStatus } = useHardwareTestActions();

	useEffect(() => {
		if (stream) {
			updateMicTestStatus(!noRecentVolume, hasDetectedVolume, isEnabled);
		}
	}, [stream, hasDetectedVolume, noRecentVolume, updateMicTestStatus, isEnabled]);

	const tokenNames = [
		"Microphone",
		"MicrophoneQuiet",
		"MuteMicrophone",
		"NoAudioDetected",
		"NoMicrophoneDetected",
		"CouldNotConnect",
		"NoPermissions",
	];
	const strings = useStrings("MicSetup", tokenNames);

	let message = "";

	// Set generic error messages
	if (status !== DeviceStatus.success && status !== DeviceStatus.testing) {
		message = micCount > 0 ? strings["CouldNotConnect"] : strings["NoMicrophoneDetected"];
	}
	// Override with a more-specific error/warning message as appropriate
	if ([DeviceStatus.warning, DeviceStatus.error].includes(status)) {
		switch (statusSubtype) {
			case DeviceStatusSubtype.neverStarted:
			case DeviceStatusSubtype.testInterrupted:
				message = strings["NoAudioDetected"];
				break;
			case DeviceStatusSubtype.stoppedWorking:
				message = strings["MicrophoneQuiet"];
				break;
			case DeviceStatusSubtype.permissionsError:
				message = strings["NoPermissions"];
				break;
		}
	}

	return message;
}
