/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Hook to automatically select hardware devices for the user
 * @author Colin Walters
 * @module Epic.VideoApp.Hooks.UseAutomaticDeviceSelection
 */

// some of our supported browsers don't support the entire mediaDevices API. That's fine.
/* eslint-disable compat/compat */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useEffect, useRef } from "react";
import { combinedActions, useHardwareTestState, useSpeakerState } from "~/state";
import { localTrackActions } from "~/state/localTracks";
import { HardwareTestError } from "~/types";
import {
	IGetDevicePreference,
	getCameraPreference,
	getMicPreference,
	getSpeakerPreference,
	isInfraredLabeledVideoDevice,
	isOSDefinedAudioDevice,
} from "~/utils/device";
import { isIOS } from "~/utils/os";
import { useSelectedCameraId } from ".";
import { useAudioTrackActions, useVideoTrackActions } from "./localTracks";
import { useSelectedMicId } from "./useSelectedMicId";

export function useAutomaticDeviceSelection(): (devices: MediaDeviceInfo[]) => void {
	const selectedCameraId = useSelectedCameraId() || "";
	const selectedMicId = useSelectedMicId();
	const selectedSpeakerId = useSpeakerState((selectors) => selectors.getSelectedSpeakerId(), []);

	const { switchVideoDevice, removeLocalVideoTrack } = useVideoTrackActions();
	const { switchAudioDevice, removeLocalAudioTrack } = useAudioTrackActions();
	const dispatch = useDispatch();

	const previousMicIds = useRef<string[]>([]);
	const previousSpeakerIds = useRef<string[]>([]);

	const hardwareTestError = useHardwareTestState((selectors) => selectors.getHardwareTestError(), []);

	// subscribe to updates as long as this component is rendered
	useEffect(() => {
		// for iOS, just mark the initial tracks as having been auto-selected to avoid https://bugs.webkit.org/show_bug.cgi?id=226921
		if (isIOS()) {
			dispatch(combinedActions.setHasAutoSelectedDevicesIOS());
			return;
		}
	}, [dispatch]);

	const autoSelectDevices = useCallback(
		(devices: MediaDeviceInfo[]): void => {
			if (!devices) {
				return;
			}

			// filter infrared cameras
			devices = devices.filter((device) => device.deviceId && !isInfraredLabeledVideoDevice(device));

			// split available devices into their different types
			const { cameras, microphones, speakers } = sortDevices(devices);

			// CAMERA
			const newSelectedCamera = autoSelectCamera(cameras, selectedCameraId);
			if (!newSelectedCamera) {
				if (hardwareTestError !== HardwareTestError.permissionsError) {
					removeLocalVideoTrack();
				} else {
					// Have to set the device to auto selected otherwise the call can't be joined
					dispatch(localTrackActions.setHasAutoSelectedVideo());
				}
			} else if (newSelectedCamera.deviceId !== selectedCameraId) {
				void switchVideoDevice(newSelectedCamera);
			} else {
				dispatch(localTrackActions.setHasAutoSelectedVideo());
			}

			// MICROPHONE
			const newSelectedMic = autoSelectAudioDevice(
				microphones,
				previousMicIds.current,
				getMicPreference,
				selectedMicId,
			);
			previousMicIds.current = microphones.map((mic) => mic.deviceId);

			if (!newSelectedMic) {
				if (hardwareTestError !== HardwareTestError.permissionsError) {
					removeLocalAudioTrack();
				} else {
					// Have to set device to auto selected otherwise the call can't be joined
					dispatch(localTrackActions.setHasAutoSelectedAudio());
				}
			} else if (newSelectedMic.deviceId !== selectedMicId) {
				void switchAudioDevice(newSelectedMic);
			} else {
				dispatch(localTrackActions.setCanCreateAudioContext(true));
				dispatch(localTrackActions.setHasAutoSelectedAudio());
			}

			// SPEAKER
			const newSelectedSpeaker = autoSelectAudioDevice(
				speakers,
				previousSpeakerIds.current,
				getSpeakerPreference,
				selectedSpeakerId || undefined,
			);
			previousSpeakerIds.current = speakers.map((speaker) => speaker.deviceId);

			if (!newSelectedSpeaker || newSelectedSpeaker.deviceId !== selectedSpeakerId) {
				dispatch(combinedActions.setSelectedSpeaker(newSelectedSpeaker));
			}
		},
		[
			selectedCameraId,
			selectedMicId,
			selectedSpeakerId,
			hardwareTestError,
			removeLocalVideoTrack,
			switchVideoDevice,
			dispatch,
			removeLocalAudioTrack,
			switchAudioDevice,
		],
	);

	return autoSelectDevices;
}

interface IDevicesByType {
	cameras: MediaDeviceInfo[];
	microphones: MediaDeviceInfo[];
	speakers: MediaDeviceInfo[];
}

/**
 * Group devices into cameras, microphones, and speakers
 *
 * @param devices list of media devices to be divided into types
 * @returns IDeviceOptions with devices split into types
 */
function sortDevices(devices: MediaDeviceInfo[]): IDevicesByType {
	devices = devices.filter((device) => device.deviceId);
	const cameras = devices.filter((device) => device.kind === "videoinput");
	const microphones = devices.filter((device) => device.kind === "audioinput");
	const speakers = devices.filter((device) => device.kind === "audiooutput");

	return { cameras, microphones, speakers };
}

/**
 * Determine which camera should be auto-selected
 *
 * @param cameras list of available "videoinput" devices
 * @param prevSelectedId deviceId of the previously selected camera
 * @returns device that should be auto-selected, or null to indicate no device should be selected
 */
function autoSelectCamera(cameras: MediaDeviceInfo[], prevSelectedId?: string): MediaDeviceInfo | null {
	// if we don't have options, return null to clear the local video track
	if (!cameras || !cameras.length) {
		return null;
	}

	// if any of the cameras are the most recent manual selection (from cookie), select that device
	const preferredCamera = getPreferredDevice(cameras, getCameraPreference);
	if (preferredCamera) {
		return preferredCamera;
	}

	// if the old camera is still there, continue to use it
	const selectedDevice = cameras.find((camera) => camera.deviceId === prevSelectedId);
	if (selectedDevice) {
		return selectedDevice;
	}

	// select any camera labelled as "front"
	const frontCamera = cameras.find((camera) => camera.label.toLocaleLowerCase().includes("front"));
	if (frontCamera) {
		return frontCamera;
	}

	// otherwise, select the first camera
	return cameras[0];
}

/**
 * Determine which audio device should be auto-selected
 *
 * @param devices list of available "audioinput" or "audiooutput" devices
 * @param prevDeviceIds list of devices that were previously available
 * @param getPreferredDeviceFromStorage function used to retrieve the preferred device
 * @param prevSelectedId deviceId of the previously selected device
 * @returns device that should be auto-selected, or null to indicate no device should be selected
 */
function autoSelectAudioDevice(
	devices: MediaDeviceInfo[],
	prevDeviceIds: string[],
	getPreferredDeviceFromStorage: IGetDevicePreference,
	prevSelectedId?: string,
): MediaDeviceInfo | null {
	// if we don't have options, return null to clear the previous selection
	if (!devices) {
		return null;
	}

	// filter out OS-defined devices
	const allowedDevices = devices.filter((device) => !isOSDefinedAudioDevice(device));
	if (!allowedDevices.length) {
		return null;
	}

	// determine which devices (if any) are new to the list, on first call this will be all of them
	const newDevices = allowedDevices.filter((device) => !prevDeviceIds.includes(device.deviceId));
	if (newDevices.length) {
		// NOTE: by only looking for preferred devices when they are newly added, a newly added headset/bluetooth
		// device will take precedence over a manually selected audio device. This is expected behavior.

		// if the user's preferred device was newly added, select that automatically
		const preferredDevice = getPreferredDevice(newDevices, getPreferredDeviceFromStorage);
		if (preferredDevice) {
			return preferredDevice;
		}

		// if there are any newly added headset or bluetooth devices, select those automatically
		const newHeadsetOrBluetoothDevice = getHeadsetOrBluetooth(newDevices);
		if (newHeadsetOrBluetoothDevice) {
			return newHeadsetOrBluetoothDevice;
		}
	}

	// if the previously selected device still exists, continue to use it
	const selectedDevice = allowedDevices.find((device) => device.deviceId === prevSelectedId);
	if (selectedDevice) {
		return selectedDevice;
	}

	// check if any of the other devices are headsets or bluetooth
	const headsetOrBluetoothDevice = getHeadsetOrBluetooth(allowedDevices);
	if (headsetOrBluetoothDevice) {
		return headsetOrBluetoothDevice;
	}

	// check if any of the remaining devices are the OS's designated "communications" or "default" devices
	const communicationsOrDefaultDevice = getCommunicationsOrDefaultDevice(devices);
	if (communicationsOrDefaultDevice) {
		return communicationsOrDefaultDevice;
	}

	// otherwise, select the first allowed device
	return allowedDevices[0];
}

/**
 * Find a device that matches the device returned by the getPreferredDeviceFromStorage function
 *
 * @param devices list of devices
 * @param getPreferredDeviceFromStorage function used to retrieve the preferred device cookie
 */
function getPreferredDevice(
	devices: MediaDeviceInfo[],
	getPreferredDeviceFromStorage: IGetDevicePreference,
): MediaDeviceInfo | null {
	// get the label of the preferred device
	const storedPreference = getPreferredDeviceFromStorage();
	if (!storedPreference) {
		return null;
	}
	// find a device matching the preferred device
	const preferredDevice =
		devices.find((device) => device.deviceId === storedPreference.id) ||
		devices.find((device) => device.label.toUpperCase() === storedPreference.label.toUpperCase());

	if (preferredDevice) {
		return preferredDevice;
	}
	return null;
}

/**
 * Find a device labeled as a "headset" or "bluetooth" device
 * Due to an issue with the Android device "Headset Earpiece", we're no longer looking for devices with "headset" in their label
 *
 * @param audioDevices list of "audioinput" or "audiooutput" devices
 * @returns device with "headset" or "bluetooth" in its label
 */
function getHeadsetOrBluetooth(audioDevices: MediaDeviceInfo[]): MediaDeviceInfo | null {
	if (!audioDevices) {
		return null;
	}
	// check for any devices labeled "bluetooth"
	const bluetoothDevice = audioDevices.find((device) => device.label.toLowerCase().includes("bluetooth"));
	if (bluetoothDevice) {
		return bluetoothDevice;
	}
	// no "headset" or "bluetooth" device was found
	return null;
}

/**
 * Find a device that is the OS-defined "communications" or "default" device
 *
 * @param audioDevices list of "audioinput" or "audiooutput" devices, this list should NOT have filtered out "communications" or "default" devices
 * @returns device that is the OS-defined "communications" or "default" device
 */
function getCommunicationsOrDefaultDevice(audioDevices: MediaDeviceInfo[]): MediaDeviceInfo | null {
	if (!audioDevices) {
		return null;
	}

	// get the filtered list of devices that show up in selectors
	const allowedDevices = audioDevices.filter((device) => !isOSDefinedAudioDevice(device));

	// get "communications" device
	const commDevice = audioDevices.find((device) => device.deviceId === "communications");
	if (commDevice) {
		// find the allowed device that matches the "communications" device
		const commMatch = getMatchingDevice(allowedDevices, commDevice);
		if (commMatch) {
			return commMatch;
		}
	}

	// get "default" device
	const defaultDevice = audioDevices.find((device) => device.deviceId === "default");
	if (defaultDevice) {
		// find the allowed device that matches the "default" device
		const defaultMatch = getMatchingDevice(allowedDevices, defaultDevice);
		if (defaultMatch) {
			return defaultMatch;
		}
	}

	// no "communications" or "default" device was found
	return null;
}

/**
 * Find a device whose label is a substring of the matchDevice label
 *
 * @param devices list of devices to search for a match within
 * @param matchDevice device to look for a match of
 * @returns a device in devices whose label was included in the matchDevice label, or null if no device is found
 */
function getMatchingDevice(devices: MediaDeviceInfo[], matchDevice: MediaDeviceInfo): MediaDeviceInfo | null {
	const foundDevice = devices.find((device) =>
		matchDevice.label.toLowerCase().includes(device.label.toLowerCase()),
	);
	return foundDevice || null;
}
