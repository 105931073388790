/**
 * @copyright Copyright 2021-2023 Epic Systems Corporation
 * @file screen sharing banner component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Banner.ScreenShareBanner
 */

import React, { FC, useCallback, useContext } from "react";
import { ShareContext } from "~/components/ScreenShareContext";
import ActionButton from "~/components/Utilities/ActionButton";
import { useStrings } from "~/hooks";
import { useLocalScreenShareAudioToggle } from "~/hooks/useLocalScreenShareAudioToggle";
import { isMacSafari } from "~/utils/os";
import { SharedStringTokens } from "~/utils/strings";
import { VideoContext } from "~/web-core/components";
import { useStream } from "~/web-core/hooks/useStream";
import styles from "./Banner.module.scss";
import BannerBase from "./BannerBase";

/** String tokens used by the ScreenShareBanner component */
enum TokenNames {
	header = "Header",
	changeButtonLabel = "ChangeButtonLabel",
	stopButtonLabel = "StopButtonLabel",
	muteButtonLabel = "MuteButtonLabel",
	unmuteButtonLabel = "UnmuteButtonLabel",
}

export enum ScreenShareBannerTestIds {
	stopSharebutton = "StopShareButton",
	changeScreenButton = "ChangeScreenButton",
	muteShareAudiobutton = "MuteShareAudioButton",
}

/**
 * The ScreenShareBanner component
 */
const ScreenShareBanner: FC = () => {
	const { requestScreenShareTrack, removeScreenShareTrack } = useContext(ShareContext);
	const { session } = useContext(VideoContext);

	const strings = useStrings("ScreenShareBanner", Object.values(TokenNames), [
		SharedStringTokens.warningIconAriaLabel,
	]);

	const stream = useStream(session?.localUser, "screen");
	const hasAudio = stream?.hasAudio();
	const [isScreenShareAudioEnabled, toggleScreenShareAudio] = useLocalScreenShareAudioToggle();

	// indicate to requestScreenShareTrack that we're replacing an existing track
	const requestScreenShareTrackWrapper = useCallback(() => {
		void requestScreenShareTrack?.(true);
	}, [requestScreenShareTrack]);

	const removeScreenShareTrackWrapper = useCallback(() => {
		void removeScreenShareTrack?.();
	}, [removeScreenShareTrack]);

	const toggleScreenShareAudioWrapper = useCallback(() => {
		void toggleScreenShareAudio();
	}, [toggleScreenShareAudio]);

	const audioMuteLabel = isScreenShareAudioEnabled
		? strings[TokenNames.muteButtonLabel]
		: strings[TokenNames.unmuteButtonLabel];

	return (
		<BannerBase
			header={strings[TokenNames.header]}
			iconProps={{
				fill: "#daa520",
				"aria-label": strings[SharedStringTokens.warningIconAriaLabel],
				"aria-hidden": false,
			}}
		>
			<div className={styles["buttonsWrapper"]}>
				{hasAudio && (
					<ActionButton
						onClick={toggleScreenShareAudioWrapper}
						data-testid={ScreenShareBannerTestIds.muteShareAudiobutton}
						priority="secondary"
						tone="neutral"
					>
						{audioMuteLabel}
					</ActionButton>
				)}
				{!isMacSafari && (
					<ActionButton
						onClick={requestScreenShareTrackWrapper}
						data-testid={ScreenShareBannerTestIds.changeScreenButton}
						priority="secondary"
						tone="neutral"
					>
						{strings[TokenNames.changeButtonLabel]}
					</ActionButton>
				)}
				<ActionButton
					onClick={removeScreenShareTrackWrapper}
					data-testid={ScreenShareBannerTestIds.stopSharebutton}
					priority="primary"
					tone="neutral"
				>
					{strings[TokenNames.stopButtonLabel]}
				</ActionButton>
			</div>
		</BannerBase>
	);
};

ScreenShareBanner.displayName = "ScreenShareBanner";

export default ScreenShareBanner;
