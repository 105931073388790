/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file hook to get a camera device message
 * @author Max Harkins
 * @module Epic.VideoApp.Components.HardwareSetup.Hooks.UseCameraDeviceMessage
 */

import { useContext } from "react";
import { useCameraDevices, useStrings } from "~/hooks";
import { useHardwareTestState } from "~/state";
import { DeviceStatus, DeviceStatusSubtype, DisplayContext } from "~/types";
import { HardwareSetupDisplayContext } from "../HardwareSetup";

enum TokenNames {
	couldNotConnect = "CouldNotConnect",
	noCameraDetected = "NoCameraDetected",
	noPermissions = "NoPermissions",
}

/**
 * Hook used to get a camera device message (may be empty)
 *
 * Returns: the message as a string, and a callback function which should be triggered when the audio is tested
 */
export function useCameraDeviceMessage(): string {
	const displayContext = useContext(HardwareSetupDisplayContext);
	const status = useHardwareTestState(
		(selectors) => selectors.getCameraStatus(displayContext === DisplayContext.lobby),
		[],
	);
	const statusSubtype = useHardwareTestState((selectors) => selectors.getCameraError(), []);

	const cameraCount = useCameraDevices().length;

	const strings = useStrings("CameraSetup", Object.values(TokenNames));

	let message = "";
	if (status !== DeviceStatus.success && status !== DeviceStatus.testing) {
		message =
			cameraCount > 0 ? strings[TokenNames.couldNotConnect] : strings[TokenNames.noCameraDetected];
	}

	// Override with a more-specific error/warning message as appropriate
	if ([DeviceStatus.warning, DeviceStatus.error].includes(status)) {
		switch (statusSubtype) {
			case DeviceStatusSubtype.permissionsError:
				message = strings[TokenNames.noPermissions];
				break;
		}
	}

	return message;
}
