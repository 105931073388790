/**
 * @copyright Copyright 2020-2024 Epic Systems Corporation
 * @file disable camera button
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Buttons.DisableCameraButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useLocalVideoToggle, useStrings } from "~/hooks";
import { useGetFormattedHotkeyString } from "~/hooks/useGetFormattedHotkeyString";
import { uiActions, useUserState } from "~/state";
import { getCameraIcon } from "~/utils/device";
import styles from "../ControlsHeader.module.scss";
import ControlButton from "./ControlButton";

/**
 * Props for DisableCameraButton component
 */
interface IProps {
	/** Whether or not the button should be rendered without a tooltip */
	noTooltip?: boolean;
}

enum TokenNames {
	disabled = "CameraDisabled",
	disableCamera = "DisableCamera",
	enableCamera = "EnableCamera",
}

/**
 * Disable camera button
 * @param props The props ;)
 */
const DisableCameraButton: FC<IProps> = (props) => {
	const { noTooltip } = props;
	const [enabled, toggleEnabled] = useLocalVideoToggle();
	const dispatch = useDispatch();

	const strings = useStrings("DisableCameraButton", Object.values(TokenNames));

	// wrapper to ignore the onClick handler's event param
	const onClick = useCallback(() => toggleEnabled(), [toggleEnabled]);
	const onLockedClick = useCallback(
		(event?: React.MouseEvent<HTMLButtonElement>) => {
			event?.nativeEvent.stopImmediatePropagation();
			dispatch(uiActions.setRequestMenuOpen(true));
		},
		[dispatch],
	);
	const cameraLocked = useUserState((selectors) => selectors.getCameraLock(), []);

	let extraClassName;
	if (cameraLocked) {
		extraClassName = styles["locked"];
	} else if (!enabled) {
		extraClassName = styles["disabled"];
	}

	const tooltipText = strings[getTooltipToken(enabled, cameraLocked)];
	const keyboardShortcut = ["alt", "r"];
	const toolTipTextFormatted = useGetFormattedHotkeyString(tooltipText, keyboardShortcut);

	return (
		<ControlButton
			icon={getCameraIcon(enabled, cameraLocked)}
			onClick={cameraLocked ? onLockedClick : onClick}
			ariaLabel={toolTipTextFormatted}
			pressed={!enabled}
			keyboardShortcut={keyboardShortcut}
			tooltipText={noTooltip ? undefined : toolTipTextFormatted}
			buttonClassName={extraClassName}
		/>
	);
};

function getTooltipToken(enabled: boolean, locked: boolean): string {
	if (locked) {
		return TokenNames.disabled;
	}
	return enabled ? TokenNames.disableCamera : TokenNames.enableCamera;
}

DisableCameraButton.displayName = "DisableCameraButton";

export default DisableCameraButton;
