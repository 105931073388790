/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Waiting Card and Local Preview for when no others are in the call
 * @author Will Cooper
 * @module Epic.VideoApp.Components.VideoCall.WaitingRoom
 */

import React, { ReactElement, useContext } from "react";
import { useRoomState } from "~/state";
import { VideoContext } from "~/web-core/components";
import FloatingParticipant from "../Participants/FloatingParticipant";
import EmbeddedWaitingRoom from "./EmbeddedWaitingRoom";
import WaitingCard from "./WaitingCard";

/**
 * The WaitingRoom component
 */
const WaitingRoom = (): ReactElement | null => {
	const waitingRoomUrl = useRoomState((selectors) => selectors.getWaitingRoomUrl(), []);
	const { session } = useContext(VideoContext);

	return (
		<>
			{waitingRoomUrl ? <EmbeddedWaitingRoom url={waitingRoomUrl} /> : <WaitingCard />}
			{session?.localUser && (
				<FloatingParticipant participant={session.localUser} isLocal disablePinning />
			)}
		</>
	);
};

WaitingRoom.displayName = "WaitingRoom";

export default WaitingRoom;
