/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file Wrapper component for each in-call participant. Holds a nameplate and the participants tracks.
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.CallParticipant
 */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useDispatch } from "@epic/react-redux-booster";
import React, { ReactElement, useCallback, useContext, useMemo } from "react";
import { uiActions } from "~/state";
import { IDimensions } from "~/types";
import { resolveClassName } from "~/utils/className";
import { getVideoSizeWithMargin } from "~/utils/general";
import { VideoCallContext } from "~/web-core/components";
import { IUser } from "~/web-core/interfaces";
import { Priority } from "~/web-core/types";
import styles from "./CallParticipant.module.scss";
import ParticipantNameBar from "./ParticipantNameBar";
import UserVideo from "./UserVideo";

export enum CallParticipantTestIds {
	self = "CallParticipant",
}

interface IParticipantProps {
	participant: IUser;
	videoSize?: IDimensions;
	hideParticipant?: boolean;
	isLocal?: boolean;
	screenOnly?: boolean;
	displayName?: string;
	inGrid?: boolean;
}
/**
 * The CallParticipant component
 */
const CallParticipant = (props: IParticipantProps): ReactElement | null => {
	const { participant, hideParticipant, isLocal, screenOnly, videoSize, displayName, inGrid } = props;
	const dispatch = useDispatch();
	const { screenShareParticipant } = useContext(VideoCallContext);

	const participantClassName = resolveClassName(styles, {
		hide: hideParticipant,
		gridParticipant: inGrid,
		screenSharePreview: isLocal && screenOnly,
		participantWrapper: !inGrid,
	});
	const videoType = screenOnly ? "screen" : "camera";

	const onClick = useCallback(() => {
		dispatch(
			uiActions.toggleVideoPinned({
				pinnedVideo: { identity: participant.getUserIdentity(), videoType },
				updateSource: "Default",
			}),
		);
	}, [dispatch, participant, videoType]);

	const style =
		!!videoSize && inGrid
			? {
					height: getVideoSizeWithMargin(videoSize.height),
					width: getVideoSizeWithMargin(videoSize.width),
			  }
			: undefined;

	const priority: Priority | null = useMemo(() => {
		if (screenShareParticipant?.getUserIdentity() === participant.getUserIdentity()) {
			// We want both a shared screen and the camera feed of the screen sharer to have higher priority than the average video
			return "standard";
		}

		// Any other feed is not the main participant or not sharing a screen, so they will be rendered at low priority
		return "low";
	}, [screenShareParticipant, participant]);

	return (
		<div
			className={participantClassName}
			onClick={onClick}
			style={style}
			data-testid={CallParticipantTestIds.self}
		>
			<ParticipantNameBar
				participant={participant}
				isLocal={isLocal}
				isHidden={hideParticipant}
				videoType={videoType}
				location={!inGrid ? "participant-strip" : undefined}
			/>
			{participant && (
				<UserVideo
					participant={participant}
					isLocal={isLocal}
					videoType={videoType}
					videoSize={videoSize}
					priority={priority}
					displayName={displayName}
				/>
			)}
		</div>
	);
};

CallParticipant.displayName = "CallParticipant";

export default React.memo(CallParticipant);
