/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Class for client errors for video vendors
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Interfaces.VendorError
 */

export class VendorError extends Error {
	code: number;
	message: string;
	updateConnectionStatus?: boolean | undefined;

	constructor(message: string, code: number, name?: string, updateConnectionStatus?: boolean) {
		super(message);
		this.code = code;
		this.message = message;
		this.name = name ?? "";
		this.updateConnectionStatus = updateConnectionStatus;
	}
}
