/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file After Call Screen once a user leaves
 * @author Will Cooper
 * @module Epic.VideoApp.Features.DisconnectionPage.DisconnectionPage
 */

import { useDispatch } from "@epic/react-redux-booster";
import { RouteComponentProps } from "@reach/router";
import React, { FC, useEffect, useMemo } from "react";
import CardContainer from "~/components/Cards/CardContainer";
import ErrorCard from "~/components/Cards/ErrorCard";
import CopyrightLinkButton from "~/components/Copyright/CopyrightLinkButton";
import FeedbackIntroQuestion from "~/components/FeedbackSurvey/FeedbackIntroQuestion";
import OptionalFeedbackButton from "~/components/FeedbackSurvey/OptionalFeedbackButton";
import SurveyCard from "~/components/FeedbackSurvey/SurveyCard";
import SimpleHeader from "~/components/Header/SimpleHeader";
import FeatureWrapper from "~/components/StandardLayout/FeatureWrapper";
import { useDocumentTitle, useStrings } from "~/hooks";
import { roomActions, useErrorPageState, useUserState } from "~/state";
import { useFeedbackSurveyState } from "~/state/feedbackSurvey";
import { FeedbackPageStep } from "~/types/survey";
import { SharedStringTokens } from "~/utils/strings";
import { tryCloseEmbeddedMobileView } from "~/utils/windowGlobals";
import { ErrorTokenNames } from "../generic-error/GenericError";

/** String tokens used by the DisconnectionPage component */
enum TokenNames {
	title = "Title",
	bodyText = "BodyText",
	postSurveyMessage = "PostSurveyMessage",
	feedbackPrompt = "FeedbackPrompt",
	feedbackHeading = "FeedbackHeading",
}

/**
 * A simple screen after the user disconnects
 */
const DisconnectionPage: FC<RouteComponentProps> = () => {
	const userType = useUserState((selectors) => selectors.getUserType(), []);
	const feedbackStep: FeedbackPageStep = useFeedbackSurveyState(
		(selectors) => selectors.getFeedbackStep(),
		[],
	);

	const strings = useStrings("DisconnectionPage", Object.values(TokenNames), [
		SharedStringTokens.defaultPageTitle,
	]);
	const errorStrings = useStrings("GenericError", Object.values(ErrorTokenNames));
	useDocumentTitle(strings[SharedStringTokens.defaultPageTitle]);

	// Set isDisconnecting again if user refreshes on the /Disconnected page
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(roomActions.setIsDisconnecting());
	}, [dispatch]);

	// Handle ending the feedback survey flow
	useEffect(() => {
		if (feedbackStep === FeedbackPageStep.hidden || feedbackStep === FeedbackPageStep.completed) {
			// Close the view if we are in an embedded mobile app and leaving the call
			tryCloseEmbeddedMobileView();
		}
	}, [dispatch, feedbackStep, userType]);

	const titleTextToken = useErrorPageState((selectors) => selectors.getErrorPageTitle(), []);
	const bodyTextToken = useErrorPageState((selectors) => selectors.getErrorPageText(), []);

	const titleText = titleTextToken ? errorStrings[titleTextToken] : strings[TokenNames.title];
	const bodyText = bodyTextToken ? errorStrings[bodyTextToken] : strings[TokenNames.bodyText];

	const DisconnectionCardComponent = useMemo(() => {
		switch (feedbackStep) {
			case FeedbackPageStep.completed:
				return <ErrorCard titleText={strings[TokenNames.postSurveyMessage]} bodyText={bodyText} />;
			case FeedbackPageStep.survey:
				return <SurveyCard />;
			case FeedbackPageStep.intro:
				return bodyTextToken ? (
					<CardContainer>
						<ErrorCard titleText={titleText} bodyText={bodyText} skipPositioning />
						<ErrorCard titleText={strings[TokenNames.feedbackPrompt]} skipFocus skipPositioning>
							<p>{strings[TokenNames.feedbackHeading]}</p>
							<FeedbackIntroQuestion />
						</ErrorCard>
					</CardContainer>
				) : (
					<ErrorCard titleText={strings[TokenNames.feedbackPrompt]}>
						<p>{strings[TokenNames.feedbackHeading]}</p>
						<FeedbackIntroQuestion />
					</ErrorCard>
				);
			case FeedbackPageStep.optional:
				return (
					<ErrorCard titleText={titleText} bodyText={bodyText}>
						<OptionalFeedbackButton />
					</ErrorCard>
				);

			case FeedbackPageStep.hidden:
			default:
				return <ErrorCard titleText={titleText} bodyText={bodyText} />;
		}
	}, [bodyText, bodyTextToken, feedbackStep, strings, titleText]);

	return (
		<FeatureWrapper>
			<SimpleHeader />
			<main>
				{DisconnectionCardComponent}
				<CopyrightLinkButton />
			</main>
		</FeatureWrapper>
	);
};

DisconnectionPage.displayName = "DisconnectionPage";

export default DisconnectionPage;
