/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file hook to retrieve the currently selected camera ID from the LocalVideoTrack or disabled camera in shared state
 * @author Colin Walters
 * @module Epic.VideoApp.Hooks.UseSelectedCameraId
 */

import { useContext } from "react";
import { useLocalTrackState } from "~/state";
import { VideoContext } from "~/web-core/components/VideoSessionProvider";
import { useMediaTrack } from "~/web-core/hooks/useMediaTrack";

/**
 * Get the selected camera ID from shared state (either from the LocalVideoTrack or disabled camera)
 */
export function useSelectedCameraId(): string | null {
	const { stream } = useContext(VideoContext);

	const isVideoEnabled = stream?.getMediaStreamTrack("video")?.enabled;
	const disabledCamera = useLocalTrackState((selectors) => selectors.getDisabledCamera(), []);
	const mediaStreamTrack = useMediaTrack(stream, "video");
	const videoId = mediaStreamTrack?.getSettings().deviceId || null;

	return isVideoEnabled ? videoId : disabledCamera?.deviceId || null;
}
