/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to get the name of a participant
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Participants.Hooks.UseParticipantName
 */

import { useContext, useMemo } from "react";
import { INamelessParticipantData } from "~/components/VideoCall/hooks/useNamelessParticipantData";
import { useStrings } from "~/hooks";
import { useRoomState } from "~/state";
import { stringFormat } from "~/utils/strings";
import { VideoCallContext } from "~/web-core/components";
import { IUser } from "~/web-core/interfaces";

/**
 * Object containing all the default name data for the current call
 */
interface IParticipantDefaultNames {
	/** Map of participant identity to defaulted name (from video call context) */
	defaultNames: Record<string, string>;

	/** Default name to use if a name isn't found in any of the above (from useStrings) */
	defaultName: string;
}

enum TokenNames {
	genericName = "DefaultName_Generic",
	numberedName = "DefaultName_Numbered",
}

/**
 * Get the display name of a participant to be used in call
 * @param participant call participant to get display name of
 * @param isLocal whether or not the participant is the local participant
 * @returns the display name of the participant, or an empty string if the current participant is not found
 */
export function useParticipantName(participant: IUser | null, isLocal?: boolean): string {
	const identity = participant?.getUserIdentity() ?? "";
	const localDisplayName = useRoomState((selectors) => selectors.getLocalDisplayName(), []);
	const participantInfo = useRoomState((selectors) => selectors.getParticipantInfo(identity), [identity]);
	const remoteDisplayName = participantInfo?.displayName;
	const { namelessParticipantData } = useContext(VideoCallContext);

	const actualName = isLocal ? localDisplayName : remoteDisplayName;

	const strings = useStrings("useParticipantName", Object.values(TokenNames), [], "en-us");
	const allDefaultNames = useMemo(
		() =>
			getAllDefaultParticipantNames(
				namelessParticipantData,
				strings[TokenNames.numberedName],
				strings[TokenNames.genericName],
			),
		[namelessParticipantData, strings],
	);

	const displayName = useMemo(() => {
		if (!participant) {
			return "";
		}

		if (actualName) {
			return actualName;
		}

		return getParticipantDefaultName(participant?.getUserIdentity() ?? "", allDefaultNames);
	}, [actualName, allDefaultNames, participant]);

	return displayName;
}

/**
 * Get all possible default name data for the current call.
 * @param namelessParticipantData nameless participant data from the call context
 * @param numberedDefaultName string to use as the numbered default name
 * @param genericDefaultName string to use as the generic default name
 * @returns object containing all default name data for the current call
 */
function getAllDefaultParticipantNames(
	namelessParticipantData: INamelessParticipantData,
	numberedDefaultName: string,
	genericDefaultName: string,
): IParticipantDefaultNames {
	const defaultNames: Record<string, string> = {};

	namelessParticipantData.idList.forEach((identity: string, index: number) => {
		defaultNames[identity] = stringFormat(numberedDefaultName, index + 1);
	});

	return { defaultNames, defaultName: genericDefaultName };
}

/**
 * Helper function used to resolve a participant's default display name
 * @param participantIdentity the local or remote participant identity
 * @param names object containing all name data for the current call
 * @returns the name that should be defaulted for the user
 */
function getParticipantDefaultName(participantIdentity: string, names: IParticipantDefaultNames): string {
	const { defaultNames, defaultName } = names;
	return defaultNames[participantIdentity] || defaultName;
}
