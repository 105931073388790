/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file listen for MediaStreamTrack mute/unmute events; restart parent LocalTrack on unmute
 * @author Gavin Lefebvre
 * @module Epic.VideoApp.Hooks.LocalTracks.Workarounds.UseIOSMutedWorkaround
 */

import { useContext, useEffect } from "react";
import { LocalAudioTrack } from "twilio-video";
import { VideoContext } from "~/web-core/components";

/**
 * @description Hook to attach listeners for "mute" and "unmute" events (currently to LocalAudioTrack, can be extended to LocalVideoTrack
 * as well.) Mute events indicate the lack of or inability to access media data when it would otherwise be expected (readonly)
 * an currently, iOS15 needs some particular recovery steps to restore functionality after a mute/unmute sequence.
 * @param track the LocalAudioTrack on which to listen for mute/unmute events - non-affected devices should provide 'null'
 */
export function useIOSMutedWorkaround(track: LocalAudioTrack | null): void {
	const { session } = useContext(VideoContext);
	useEffect(() => {
		if (!track) {
			return;
		}

		/**	If we get an unmuted event after Siri/call interruption are complete (inconsistent), trigger a media refresh
		 */
		const handleUnmuted = (): void => {
			session?.refreshMedia();
		};

		track.on("unmuted", handleUnmuted);
		return () => {
			track.off("unmuted", handleUnmuted);
		};
	}, [track, session]);
}
