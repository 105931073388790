/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file hook to toggle local video track enable/disable
 * @author Colin Walters
 * @module Epic.VideoApp.Hooks.UseLocalVideoToggle
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useContext } from "react";
import { useLocalTrackState, useUserState } from "~/state";
import { localTrackActions } from "~/state/localTracks";
import { ToggleState } from "~/types";
import { boolMatchesToggleState } from "~/utils/general";
import { isWkWebView } from "~/utils/os";
import { VideoContext } from "~/web-core/components/VideoSessionProvider";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { TwilioLocalStream } from "~/web-core/vendor/twilio/implementations";
import { useVideoTrackActions } from "./localTracks";
import { useCameraDevices } from "./useDevices";

/**
 * Hook to get the status of if the local video track is enabled and toggle enable/disable
 * @returns tuple with the current enabled/disabled status and function to toggle that status
 */
export function useLocalVideoToggle(): [
	boolean,
	(forceState?: ToggleState, newBackground?: string, keepPublished?: boolean) => Promise<void>,
] {
	const { switchVideoDevice, removeLocalVideoTrack } = useVideoTrackActions();
	const dispatch = useDispatch();

	const cameras = useCameraDevices();
	const isToggling = useLocalTrackState((selectors) => selectors.getTogglingCamera(), []) === true;
	const isAcquiringTracks =
		useLocalTrackState((selectors) => selectors.getLocalTrackAcquisitionStatus(), []) !== "finished";

	// Don't use hook for now; instead, we just look for enabled property per render
	const { stream, session } = useContext(VideoContext);
	const isEnabled = useIsStreamEnabled("video", stream);
	const disabledCamera = useLocalTrackState((selectors) => selectors.getDisabledCamera(), []);
	const cameraLocked = useUserState((selectors) => selectors.getCameraLock(), []);

	const switchCamera = useCallback(
		async (newBackground?: string) => {
			// get the camera from the device list to ensure it still exists, if it doesn't a new camera will be selected
			// passing a deviceId that no longer exists would result in an OverConstrained error
			const camera = cameras.find((camera) => camera.deviceId === disabledCamera?.deviceId);
			await switchVideoDevice(camera, {
				manual: true,
				enable: true,
				newBackground: newBackground,
			}).finally(() => {
				dispatch(localTrackActions.setTogglingCamera(false));
			});
		},
		[cameras, disabledCamera, dispatch, switchVideoDevice],
	);

	const toggleCameraEnabled = useCallback(
		async (forceState?: ToggleState, newBackground?: string) => {
			if (
				isAcquiringTracks ||
				boolMatchesToggleState(isEnabled, forceState) ||
				cameraLocked ||
				isToggling
			) {
				return;
			}
			if (isEnabled) {
				dispatch(localTrackActions.setTogglingCamera(true));
				if (isWkWebView) {
					/**	Generally we want to disable or enable the LocalVideoTrack in a WkWebView (rather than unpublish)
					 *  but if the underlying MediaStreamTrack is in an "unrecoverable" state, we'll effectively restart
					 * 	the LocalVideoTrack before enabling	 */
					if (forceState === "on" && stream?.getMediaStreamTrack("video")?.muted) {
						session?.refreshMedia();
						await switchCamera(newBackground);
					}

					/**	Use isEnabled to communicate to the UI and remote parties to show the DisabledCamera
						flow. Otherwise negotiation needs to reoccur and seems to be a big performance hit in WkWebView
						https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Signaling_and_video_calling#starting_negotiation
					*/
					stream?.toggleState("video", !stream.isEnabled("video"));
					dispatch(localTrackActions.setTogglingCamera(false));
				} else {
					// remove / unpublish the local video track, the old device's ID will be remembered in state
					removeLocalVideoTrack(true);
					setTimeout(() => {
						dispatch(localTrackActions.setTogglingCamera(false));
					}, 1000);
				}
			} else {
				dispatch(localTrackActions.setTogglingCamera(true));
				if (isWkWebView && forceState === "on") {
					if (stream instanceof TwilioLocalStream && stream.localVideoTrack?.isMuted) {
						session?.refreshMedia();
						await switchCamera(newBackground);
					}
					stream?.toggleState("video", true);
					dispatch(localTrackActions.setTogglingCamera(false));
				} else {
					await switchCamera(newBackground);
				}
			}
		},
		[
			isAcquiringTracks,
			isEnabled,
			cameraLocked,
			isToggling,
			dispatch,
			stream,
			session,
			switchCamera,
			removeLocalVideoTrack,
		],
	);

	return [isEnabled, toggleCameraEnabled];
}
