/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file types for the core stream interface
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Types.Stream
 */

export type DeviceKind = "audio" | "video";

export type DeviceType = "camera" | "mic" | "speaker";

export type VideoType = "camera" | "screen";

export type Priority = "low" | "standard" | "high";

export interface ISwitchDeviceResponse {
	result: boolean;
	switchedDevices: boolean;
	error: Error | undefined;
}
