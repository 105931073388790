/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to change a user's camera
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Hooks.UseChangeCamera
 */

import { useCallback, useRef } from "react";
import { useCameraDevices, useSelectedCameraId } from "~/hooks";
import { useVideoTrackActions } from "~/hooks/localTracks";

/**
 * Get a function that will swap to a user's other camera
 * @returns function that when called will change the user's camera to another available device
 */
export function useChangeCamera(): () => void {
	const cameras = useCameraDevices();
	const selectedCameraId = useSelectedCameraId();
	const { switchVideoDevice } = useVideoTrackActions();
	const isSwitching = useRef(false);

	const changeCamera = useCallback(() => {
		if (isSwitching.current) {
			return;
		}
		isSwitching.current = true;
		const newCamera = cameras.find((camera) => camera.deviceId !== selectedCameraId);
		void switchVideoDevice(newCamera, { manual: true }).finally(() => {
			isSwitching.current = false;
		});
	}, [cameras, selectedCameraId, switchVideoDevice]);

	return changeCamera;
}
