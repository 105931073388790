/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Picture in Picture utilities
 * @author Arun Vasireddy
 * @module Epic.VideoApp.Utils.PictureInPicture
 */

import { isSafari } from "./browser";
import { warn } from "./logging";
import { isIOS, isMobile } from "./os";

/* eslint-disable compat/compat */

/**
 * Determine if the picture in picture feature is supported
 * @returns true if browser supports PiP feature and if user is not on mobile device or desktop safari
 */
export function isPictureInPictureSupported(): boolean {
	const isMacSafari = !isIOS() && isSafari(); // PiP is disabled in desktop safari. See QAN 7195941.
	return !isMobile() && !isMacSafari && document.pictureInPictureEnabled;
}

/**
 * Checks if a video element is playing in the picture-in-picture window
 */
export function isPiPWindowOpen(): boolean {
	return document.pictureInPictureElement !== null;
}

/**
 * Checks if the given video element is being played in the PiP window
 */
export function isElementInPiP(videoEl: HTMLVideoElement | null): boolean {
	if (videoEl === null) {
		return false;
	}
	return document.pictureInPictureElement === videoEl;
}

/**
 * Close the PiP window if it's up
 */
export async function exitPictureInPictureMode(): Promise<void> {
	if (isPiPWindowOpen()) {
		await document.exitPictureInPicture().catch((error) => {
			warn("Failed to leave Picture in Picture mode", error);
		});
	}
}

/**
 * Moves the given video element to the PiP window
 */
export async function enterPictureInPictureMode(videoEl?: HTMLVideoElement | null): Promise<boolean> {
	if (!videoEl) {
		return false;
	}

	let isPiPSuccessful = true;
	await videoEl.requestPictureInPicture().catch((error) => {
		warn("Failed to launch Picture in Picture mode", error);
		isPiPSuccessful = false;
	});
	return isPiPSuccessful;
}
