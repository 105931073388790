/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file A generic popup that can contain a message and children
 * @author Sasha Chackalackal
 * @module Epic.VideoApp.Components.Utilities.GenericPopup
 */

import React, { FC, useRef } from "react";
import { useStrings } from "~/hooks";
import { ITestable, StyleContext } from "~/types";
import styles from "../Alerts/Alerts.module.scss";
import FocusTrap from "../Alerts/FocusTrap";
import ActionButton from "./ActionButton";
import { BackgroundStyleContextProvider } from "./BackgroundStyleContext";
import KeyboardShortcut from "./KeyboardShortcut";

export enum GenericPopupTestIds {
	self = "GenericPopup",
	message = "GenericPopupMessage",
	confirmButton = "GenericPopupConfirmButton",
}

enum TokenNames {
	ok = "OK",
}

interface IProps extends ITestable {
	message?: string;
	confirmText?: string;
	confirmHotkey?: string;
	isOpen: boolean;
	onClose: () => void;
	ariaLabel?: string;
}

/**
 * Generic popup component
 *
 * Renders a popup with the given message and additional children. Includes an action button that can be bound to close the popup.
 * CopyrightLinkButton contains a usage example
 */
const GenericPopup: FC<IProps> = (props) => {
	const { message, confirmText, confirmHotkey, isOpen, onClose, ariaLabel, children } = props;

	const confirmRef = useRef<HTMLButtonElement>(null);

	// Reusing string from <GenericAlert /> to prevent the need for re-translation
	const strings = useStrings("GenericAlert", Object.values(TokenNames));

	return isOpen ? (
		<BackgroundStyleContextProvider style={StyleContext.light}>
			<div className={styles["modalContainer"]}>
				<div data-testid={GenericPopupTestIds.self} className={styles["modalCard"]}>
					{children}
					<span
						id="popupMessage"
						data-testid={GenericPopupTestIds.message}
						className={styles["bodyText"]}
						aria-label={ariaLabel ?? message}
					>
						{message ?? ""}
					</span>
					<div className={styles["buttons"]}>
						<FocusTrap firstElement={confirmRef} lastElement={confirmRef}>
							<ActionButton
								tone="neutral"
								priority="primary"
								onClick={onClose}
								buttonRef={confirmRef}
								aria-label={confirmText ?? ""}
								aria-labelledby="popupMessage"
								data-testid={GenericPopupTestIds.confirmButton}
							>
								{confirmHotkey ? (
									<KeyboardShortcut
										callback={confirm}
										keyCombination={["alt", confirmHotkey]}
										forModalAlert
										text={confirmText ?? strings[TokenNames.ok]}
									/>
								) : (
									confirmText ?? strings[TokenNames.ok]
								)}
							</ActionButton>
						</FocusTrap>
					</div>
				</div>
			</div>
		</BackgroundStyleContextProvider>
	) : null;
};

GenericPopup.displayName = "GenericPopup";

export default GenericPopup;
