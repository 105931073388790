/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Non-stateful messaging functions
 * @author Will Cooper
 * @module Epic.VideoApp.Utils.Messaging
 */

import {
	EpicUserType,
	IAccessRequestPayload,
	IDataTrackMessage,
	IModeratorPayload,
	MessageActionType,
	MessageType,
} from "../types";
import { log } from "./logging";

/**
 * Validates a message has the proper metadata and payload contents based on its type before sending it out
 * @returns true if the message is in the proper format, false otherwise
 */
export function isValidDataTrackMessage(message: IDataTrackMessage): boolean {
	// Verify data packet is non-null
	if (!message || (!message.messageSID && message.needsACK)) {
		return false;
	}

	// Verify message type and payload contents include all expected values
	switch (message.type) {
		case MessageType.participantInfo:
			if (!message.payload || !Object.values(EpicUserType).includes(message.payload.userType)) {
				return false;
			}
			break;
		case MessageType.infoRequest:
			if (message.payload) {
				return false;
			}
			break;
		case MessageType.namelessParticipantSyncRequest:
			if (
				message.payload.namelessParticipantData.idList.length !== 0 &&
				message.payload.namelessParticipantData.idList.length !== 1
			) {
				// Sync request needs to have a list of exactly 0 or 1 members
				return false;
			}
			if (message.payload.namelessParticipantData.dataIsShared === true) {
				// Data shouldn't be already shared
				return false;
			}
			break;
		case MessageType.namelessParticipantSyncResponse:
			if (message.payload.namelessParticipantData.timestamp === 0) {
				// Responses can't be sent with a timestamp of 0
				return false;
			}
			if (message.payload.namelessParticipantData.dataIsShared === false) {
				// Data should be flagged as shared already
				return false;
			}
			break;
		case MessageType.moderate:
			if (!validateModeratorMessage(message.payload)) {
				return false;
			}
			break;
		case MessageType.visitAccess:
		case MessageType.imageCaptured:
			if (!message.recipients) {
				return false;
			}
			break;
		case MessageType.accessRequest:
			if (!validateAccessRequest(message.payload)) {
				return false;
			}
			break;
		default:
			return false;
	}

	//Verify message action and recipient format match
	if (!verifyMessageFormatAndAction(message)) {
		return false;
	}

	return true;
}

function verifyMessageFormatAndAction(message: IDataTrackMessage): boolean {
	if (
		(message.action === MessageActionType.broadcast && message.recipients) ||
		(message.action === MessageActionType.request && !message.recipients)
	) {
		return false;
	}
	return true;
}

/**
 * Helper function to validate the payload of a moderator message
 * @param payload - Current message
 * @returns - True if the payload matches a moderator action
 */
function validateModeratorMessage(payload: IModeratorPayload): boolean {
	if (
		!payload ||
		(payload.audioLock === undefined &&
			payload.videoLock === undefined &&
			payload.screenShareLock === undefined)
	) {
		return false;
	}
	return true;
}

/**
 * Helper function to validate the payload of a request message
 * @param payload - Current message
 * @returns True if the payload contains a display name
 */
function validateAccessRequest(payload: IAccessRequestPayload): boolean {
	if (!payload) {
		return false;
	}
	return true;
}

/**
 * Verifies the the JSON string can be parsed into a data track object before we attempt to access its properties
 * @returns the parsed object, or null if the JSON parse failed
 */
export function tryParseJSON(json: string): IDataTrackMessage | null {
	try {
		const data: IDataTrackMessage = JSON.parse(json) as IDataTrackMessage;
		if (data && typeof data === "object") {
			return data;
		}
	} catch (e) {
		log(e);
		log("Failed to parse JSON from inter-client communication");
	}
	return null;
}
