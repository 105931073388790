/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file mute button
 * @author Colin Walters
 * @module Epic.VideoApp.Components.Header.Buttons.MuteButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useLocalAudioToggle, useStrings } from "~/hooks";
import { useGetFormattedHotkeyString } from "~/hooks/useGetFormattedHotkeyString";
import { uiActions, useUserState } from "~/state";
import { getMicIcon } from "~/utils/device";
import styles from "../ControlsHeader.module.scss";
import ControlButton from "./ControlButton";

/**
 * Props for MuteButton component
 */
interface IProps {
	/** Whether or not the button should be rendered without a tooltip */
	noTooltip?: boolean;
}

enum TokenNames {
	muteHotkey = "MuteMicrophoneHotkey",
	unmuteHotkey = "UnmuteMicrophoneHotkey",
	ariaLabel = "MuteMicrophone",
	disabled = "MicrophoneDisabled",
	mute = "MuteMicrophone",
	unmute = "UnmuteMicrophone",
}

/**
 * The MuteButton component
 * @param props The props ;)
 */
const MuteButton: FC<IProps> = (props) => {
	const { noTooltip } = props;
	const [enabled, toggleEnabled] = useLocalAudioToggle();
	const dispatch = useDispatch();

	const strings = useStrings("MuteButton", Object.values(TokenNames));

	// wrapper to ignore the onClick handler's event param
	const onClick = useCallback(() => toggleEnabled(), [toggleEnabled]);

	const onLockedClick = useCallback(
		(event?: React.MouseEvent<HTMLButtonElement>) => {
			event?.nativeEvent.stopImmediatePropagation();
			dispatch(uiActions.setRequestMenuOpen(true));
		},
		[dispatch],
	);

	const muteLocked = useUserState((selectors) => selectors.getMicLock(), []);

	let extraClassName;
	if (muteLocked) {
		extraClassName = styles["locked"];
	} else if (!enabled) {
		extraClassName = styles["disabled"];
	}

	const tooltipText = strings[getTooltipToken(enabled, muteLocked)];
	const keyboardShortcut = ["alt", "m"];
	const tooltipFormatted = useGetFormattedHotkeyString(tooltipText, keyboardShortcut);

	return (
		<ControlButton
			icon={getMicIcon(enabled, muteLocked)}
			onClick={muteLocked ? onLockedClick : onClick}
			ariaLabel={tooltipFormatted}
			pressed={!enabled}
			keyboardShortcut={keyboardShortcut}
			tooltipText={noTooltip ? undefined : tooltipFormatted}
			buttonClassName={extraClassName}
		/>
	);
};

function getTooltipToken(enabled: boolean, locked: boolean): string {
	if (locked) {
		return TokenNames.disabled;
	}
	return enabled ? TokenNames.mute : TokenNames.unmute;
}

MuteButton.displayName = "MuteButton";

export default MuteButton;
