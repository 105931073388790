/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file background preview component
 * @author Liam Liden
 * @module Epic.VideoApp.Components.BackgroundSelector.BackgroundPreview
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useEffect } from "react";
import { useStrings } from "~/hooks";
import { backgroundProcessorsActions, useBackgroundProcessorsState } from "~/state/backgroundProcessors";
import VideoStream from "~/web-core/components/VideoStream";
import { ILocalStream } from "~/web-core/interfaces";
import Spinner from "../Loading/Spinner";
import DisabledCameraIndicator from "../Participants/Video/DisabledCameraIndicator";
import styles from "./BackgroundPreview.module.scss";

/**
 * Props for the BackgroundPreview component
 */
interface IProps {
	/** The video track to render in the preview */
	stream: ILocalStream | null;

	/** Whether or not the video disabled icon should be displayed */
	disabled?: boolean;
}

export enum TokenNames {
	preview = "Preview",
}

/**
 * The BackgroundPreview component
 * @param props The props ;)
 */
const BackgroundPreview: FC<IProps> = (props) => {
	const { stream, disabled, children } = props;
	const dispatch = useDispatch();

	const publishedProcessor = useBackgroundProcessorsState(
		(selectors) => selectors.getPublishedBackgroundProcessor(),
		[],
	);

	const strings = useStrings("BackgroundPreview", Object.values(TokenNames));

	// Apply preview processor when published processor is changed or the preview track is created
	useEffect(() => {
		return () => {
			// Dispatch publishedProcessor to preview processor state in case user exited menu without applying previously
			dispatch(backgroundProcessorsActions.setPreviewBackgroundProcessor(publishedProcessor));
		};
	}, [dispatch, publishedProcessor]);

	return (
		<div className={styles["backgroundPreview"]}>
			<div className={styles["previewWrapper"]}>
				{disabled ? (
					<div className={styles["videoPlaceholder"]}>
						<DisabledCameraIndicator defaultSize={75} />
					</div>
				) : stream ? (
					<>
						<VideoStream stream={stream} videoType="camera" isLocal disablePictureInPicture />
						<span className={styles["bannerText"]}>{strings[TokenNames.preview]}</span>
					</>
				) : (
					<Spinner />
				)}
			</div>
			{children}
		</div>
	);
};

BackgroundPreview.displayName = "BackgroundPreview";

export default BackgroundPreview;
