/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Initialization function to create Twilio-specific session and user objects
 * @author Chance Overberg
 * @module Epic.VideoApp.WebCore.Vendor.Twilio.Functions.InitializeTwilioAsVendor
 */

import { CreateLocalTrackOptions, TwilioError, createLocalTracks } from "twilio-video";
import { TwilioLocalStream, TwilioLocalUser, TwilioSession } from "../implementations";
import { isTwilioError, processTwilioError } from "./processTwilioError";

/**
 * Initialize the Twilio session and user objects
 * @param videoOptions video track creation options
 * @param audioOptions audio track creation options
 * @returns a TwilioSession object with a TwilioLocalUser that has a TwilioLocalStream
 */
export async function initializeTwilioAsVendor(
	videoOptions: CreateLocalTrackOptions,
	audioOptions: CreateLocalTrackOptions,
): Promise<TwilioSession> {
	let twilioStream;

	try {
		const tracks = await createLocalTracks({ video: videoOptions, audio: audioOptions });
		twilioStream = new TwilioLocalStream(tracks);
	} catch (error) {
		twilioStream = new TwilioLocalStream([]);

		if (isTwilioError(error)) {
			const updates = processTwilioError(error as TwilioError);
			twilioStream.deviceInitializationError = {
				deviceUpdates: updates,
				error: error as TwilioError,
			};
		}
	}

	const twilioUser: TwilioLocalUser = new TwilioLocalUser(twilioStream);
	const twilioSession: TwilioSession = new TwilioSession(twilioUser);

	return twilioSession;
}
