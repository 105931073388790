/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Browser support utilities
 * @author Matt Panico
 * @module Epic.VideoApp.Utils.Browser
 */

/* eslint-disable @typescript-eslint/prefer-includes */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { detectOS, isIOS, isWkWebView, OS } from "./os";
import { isEmbeddedMobileView } from "./windowGlobals";

export type Browser =
	| "chrome"
	| "firefox"
	| "edge"
	| "safari"
	| "opera"
	| "legacy-edge"
	| "samsung-internet"
	| "ie"
	| "google-search-app"
	| "unknown";

/**
 * Determine the browser that the user is using
 * @returns the detected browser
 */
export function detectBrowser(): Browser {
	const { userAgent } = navigator;
	// these checks match the order in UserAgentServices.ParseBrowserFromUserAgent
	if (/chrome\//i.test(userAgent)) {
		if (/edg\//i.test(userAgent)) {
			return "edge";
		}
		if (/edge\//i.test(userAgent)) {
			return "legacy-edge";
		}
		if (/samsungbrowser\//i.test(userAgent)) {
			return "samsung-internet";
		}
		if (/opr\//i.test(userAgent)) {
			return "opera";
		}
		return "chrome";
	}
	if (/crios\//i.test(userAgent)) {
		return "chrome";
	}
	if (/firefox\//i.test(userAgent) || /fxios\//i.test(userAgent)) {
		return "firefox";
	}
	if (/edgios\//i.test(userAgent)) {
		return "edge";
	}
	if (/opera\//i.test(userAgent) || /opt\//i.test(userAgent)) {
		return "opera";
	}
	if (/version\//i.test(userAgent)) {
		return "safari";
	}
	if (/rv:11.0/i.test(userAgent) || /msie/i.test(userAgent)) {
		return "ie";
	}
	if (/gsa\//i.test(userAgent)) {
		return "google-search-app";
	}
	return "unknown";
}

/**
 * Determine if the current browser is Legacy Edge
 * @returns true if the current browser is Legacy Edge, false otherwise
 */
export function isLegacyEdge(): boolean {
	return detectBrowser() === "legacy-edge";
}

/**
 * Determine if the current browser is Modern/Chromium Edge
 * @returns true if the userAgent reports as edge, false otherwise;
 */
export function isChromiumEdge(): boolean {
	return detectBrowser() === "edge";
}
/**
 * Determine if the current browser is Safari
 * @returns true if the current browser is Safari, false otherwise
 */
export function isSafari(): boolean {
	return detectBrowser() === "safari";
}

/**
 * Determine if the current browser is Firefox
 * @returns true if the current browser is Firefox, false otherwise
 */
export function isFirefox(): boolean {
	return detectBrowser() === "firefox";
}

/**
 * Determine if the current browser is Opera
 * @returns true if the current browser is Opera, false otherwise
 */
function isOpera(): boolean {
	return detectBrowser() === "opera";
}

/**
 * Determine if the current browser is Samsung Internet
 * @returns true if the current browser is Samsung Internet, false otherwise
 */
export function isSamsungInternet(): boolean {
	return detectBrowser() === "samsung-internet";
}

/**
 * Determine if the current browser appears to be an embedded iOS browser.
 * This is a WKWebView that is not Chrome and has our embedded mobile functions stored in the window.
 *
 * For these devices, detectBrowser returns "unknown" based on UA string
 *
 * @returns true if the current browser is an Embedded IOS browser, false otherwise
 */
export function isEmbeddedIOS(): boolean {
	return isWkWebView && !isChrome() && isEmbeddedMobileView();
}

/**
 * Determine if the current browser supports client hints
 * https://developer.mozilla.org/en-US/docs/Web/API/User-Agent_Client_Hints_API
 * @returns true if the current browser supports hints, false otherwise
 */
export function clientSupportsUAHints(): boolean {
	return !!navigator.userAgentData;
}

/**
 * Browser strings from the brands property that support client hints
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Sec-CH-UA
 */
type UAHintsBrowsers = "chromium" | "google chrome" | "microsoft edge" | "opera";

/**
 * Checks if a client is using chrome with client hints
 *
 * @returns whether or not a client is using chrome
 * */
export function isChrome(): boolean {
	if (clientSupportsUAHints()) {
		return browserUAHints("google chrome");
	}

	return detectBrowser() === "chrome";
}

/**
 * Checks what browser a client is using with user agents hints brand property
 *
 * @param browser Which browser to check the UAHints data against
 *
 * @returns True if one of the brands in the brands array matches the input browser
 * */
function browserUAHints(browser: UAHintsBrowsers): boolean {
	const { userAgentData } = navigator;
	if (!userAgentData) {
		return false;
	}

	const trimBrowser = browser.trim().toLowerCase();
	return (
		userAgentData.brands.filter((brands) => brands.brand.trim().toLowerCase() === trimBrowser).length >= 1
	);
}

/**
 * Check if a browser matches browser and version with UAHints
 *
 * @param browser string of the browser to compare to
 * @param version number of the browser version to compare to
 * @param filter optional filter function to compare the browser version and the given version. Defaults to equality if not provided.
 * The version argument will be passed as the second argument to filter() if filter() is provided.
 *
 * @returns true if the userAgentData matches the browser and version with applied filtering on version, false otherwise,
 * or if user agent client hints are not available.
 */
export function browserVersionUAHints(
	browser: string,
	version: number,
	filter?: (uaVersion: number, checkVersion: number) => boolean,
): boolean {
	const { userAgentData } = navigator;
	if (!userAgentData) {
		return false;
	}
	const trimBrowser = browser.trim().toLowerCase();
	return userAgentData.brands
		.map((value) => {
			return (
				value.brand.trim().toLowerCase() === trimBrowser &&
				applyFilter(parseInt(value.version, 10), version, filter)
			);
		})
		.some(Boolean);
}

/**
 * Check if two numbers match using a filtering function if given, otherwise using equality.
 *
 * @param version1 First version to compare
 * @param version2 Second version to compare
 * @param filter optional filter function to compare the browser version and the given version.
 * Defaults to equality if not provided. Version1 and version2 will be passed to filter in that order.
 *
 * @returns true if the version match, false otherwise.
 */
function applyFilter(
	version1: number,
	version2: number,
	filter?: (version1: number, version2: number) => boolean,
): boolean {
	if (filter) {
		return filter(version1, version2);
	}
	return version1 === version2;
}

/**
 * Determine if the current browser supports WebRTC
 * @returns true if WebRTC appears to be supported on the current browser, false otherwise
 */
export function isWebRTCSupported(): boolean {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const nav = navigator as any;

	// nav.mediaDevices check is for non-safari browsers on iOS devices
	// On versions prior to 14.3, that api is only exposed to safari.
	if (!nav.mediaDevices) {
		return false;
	}

	return !!(
		nav.getUserMedia ||
		nav.webkitGetUserMedia ||
		nav.mozGetUserMedia ||
		nav.msGetUserMedia ||
		window.RTCPeerConnection
	);
}

/**
 * Determine if the current browser is supported
 * @returns true if the browsers is supported, false otherwise
 */
export function isSupportedBrowser(): boolean {
	if (!isWebRTCSupported()) {
		return false;
	}
	if (isLegacyEdge()) {
		return false;
	}
	// Previously, on iOS we only supported browsers supported by Twilio. Because iOS WebRTC
	// support has expanded, browsers with WebRTC APIs are allowed, but show a not recommend banner.
	// Here we block Edge and Opera because they experienced frequent crashes in our testing.
	// Note: Edge did not see crashes on iOS versions before 15, but we block all version to avoid losing support upon upgrade.
	if (detectOS() === OS.ios && (isOpera() || isChromiumEdge())) {
		return false;
	}

	return true;
}

/** Whether or not HTML 5 is supported on the current browser */
export const IS_HTML5_SUPPORTED = typeof HTMLCanvasElement.prototype.getContext === "function";

/** Whether or not HTMLAudioElement.setSinkId is supported on the current browser */
export const IS_SET_SINK_ID_SUPPORTED = typeof HTMLAudioElement.prototype.setSinkId === "function";

export type SafariDetectableVersions = "14+" | "14.5+" | "15+" | "15.2+" | "15.4+" | "15.6+" | "16+";

/**
 * Perform feature detection in the browser environment at various inflection points where the
 * version is expected to be the earliest implementation of some feature (and prior versions will
 * be undefined for such behavior)
 * @param version a SafariDetectableVersion that's been added to the type above and has
 * an implemented feature detection algorithm
 * @returns true if the browser is able to meet the feature detection; false if undefined
 */
export function safariDetectedAtVersion(version: SafariDetectableVersions): boolean {
	// must be Safari or Safari-derived engine
	if (!(isSafari() || isIOS())) {
		return false;
	}

	// https://developer.apple.com/documentation/safari-release-notes/safari-16-release-notes
	// 16.0+ was valid on iPhones, whereas desktop MacOS & iPadOS released at 16.1+ (16.0+ was only on their betas)
	// so we'll consolidate this to "16+" to mark this as our major version differentiator
	if (version === "16+") {
		return CSS.supports("text-align-last", "center");
	}

	// https://developer.apple.com/documentation/safari-release-notes/safari-15_6-release-notes
	// https://webkit.org/blog/13009/new-webkit-features-in-safari-15-6/
	if (version === "15.6+") {
		return CSS.supports("selector(:modal)");
	}

	// https://developer.apple.com/documentation/safari-release-notes/safari-15_4-release-notes
	if (version === "15.4+") {
		return "hasOwn" in window.Object;
	}

	// https://developer.apple.com/documentation/safari-release-notes/safari-15_2-release-notes
	if (version === "15.2+") {
		return "FileSystemHandle" in window;
	}

	// https://developer.apple.com/documentation/safari-release-notes/safari-15-release-notes
	if (version === "15+") {
		return "BigInt64Array" in window;
	}

	// https://caniuse.com/?compare=ios_saf+14.0-14.4,ios_saf+14.5-14.8&compareCats=JS,JS%20API
	if (version === "14.5+") {
		return "AudioContext" in window;
	}

	// https://caniuse.com/?compare=ios_saf+13.4-13.7,ios_saf+14.0-14.4&compareCats=JS,JS%20API
	if (version === "14+") {
		return "BigInt" in window;
	}

	return false;
}

/**
 * Determine if the current window is being rendered within an iframe
 * @returns true if the page is in an iframe, false otherwise
 */
export function isInIframe(): boolean {
	try {
		return window.self !== window.top;
	} catch {
		// browsers can block access to window.top based on same origin policy (https://developer.mozilla.org/en-US/docs/Web/Security/Same-origin_policy)
		return true;
	}
}

/**
 * An async function determining whether this browser is impacted from the Chromium memory leak that affects background effects
 * @returns true between Chromium versions 122.0 and 122.0.6261.112, false for other browsers and Chromium versions
 */
export async function onMemoryLeakAffectedChromiumVersion(): Promise<boolean> {
	const isChromiumVersion122 = browserVersionUAHints(
		"Chromium",
		122,
		(uaVersion: number, checkVersion: number) => {
			return uaVersion === checkVersion;
		},
	);

	if (!isChromiumVersion122) {
		return false;
	}

	if (typeof navigator.userAgentData !== "undefined") {
		const ua = await navigator.userAgentData.getHighEntropyValues(["fullVersionList"]);
		const chromiumVersionInfo = ua.fullVersionList?.find((item) => item.brand === "Chromium");
		if (!chromiumVersionInfo) {
			return true;
		}
		const chromiumVersion = chromiumVersionInfo.version;
		return compareGranularVersions(chromiumVersion, "122.0.6261.112") < 0;
	} else {
		return true;
	}
}

/**
 * Given two versions in format X.Y.A.B consisting of natural numbers, compares which one is larger
 * @param firstVersion Chromium based browser version string. For example: 122.0.66.33
 * @param secondVersion Chromium based browser version string. For example: 122.0.66.33
 * @returns Returns positive if first one is larger, negative if second one is larger, zero if they are fully equal
 */
export function compareGranularVersions(firstVersion: string, secondVersion: string): number {
	const firstVersionSections = firstVersion.split(".").map(Number);
	const secondVersionSections = secondVersion.split(".").map(Number);

	for (let i = 0; i < Math.max(firstVersionSections.length, secondVersionSections.length); i++) {
		const firstSubversion = firstVersionSections[i] || 0;
		const secondSubversion = secondVersionSections[i] || 0;

		if (firstSubversion < secondSubversion) {
			return -1;
		} else if (firstSubversion > secondSubversion) {
			return 1;
		}
	}
	return 0;
}
