/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Interface for a remote video user
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Interfaces.RemoteUser
 */

import { IUser } from "./user";

/**
 * @interface IRemoteUser
 * @extends IUser
 * @description Interface for a remote user in the video application. It extends the IUser interface
 * and has an isRemoteUser property to distinguish it from the IUser interface.
 */
export interface IRemoteUser extends IUser {
	/**
	 * @description This silly method is to make IRemoteUser different from IUser. Typescript otherwise will treat the
	 * two interfaces as the same, which hinders conditional typing (e.g. in useStream)
	 */
	isRemoteUser(): true;
}
