/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Shared state for local tracks
 * @author Matt Panico
 * @module Epic.VideoApp.State.LocalTracks
 */

import { buildSharedState } from "@epic/react-redux-booster";
import store from "~/app/store";
import { TrackAcquisitionStatus } from "~/types";

/// TYPES ///

export interface ILocalTrackState {
	// Initial track acquisition status
	readonly localTrackAcquisitionStatus: TrackAcquisitionStatus;

	// Shell camera device stored when user manually disables their camera
	// reducers should ensure this never has a value at the same time as videoTrack
	readonly disabledCamera: IDisabledCamera | null;

	readonly videoDeviceId: string | null;

	// Indicates if the camera is currently being toggled
	readonly togglingCamera: boolean;

	readonly audioDeviceId: string | null;

	// Indication of whether or not auto selection has been attempted
	readonly hasAutoSelected: { video: boolean; audio: boolean };

	// is AudioContext currently running
	readonly audioContextRunning: boolean;

	// Can we start creating audio context? useSingletonAudioContext uses this flag to not create
	// audio context until getUserMedia call succeeds at least once for either audio or video.
	readonly canCreateAudioContext: boolean;
}

export interface IDisabledCamera {
	readonly deviceId: string | null;
}

/// INIT ///

export function getInitialState(): ILocalTrackState {
	return {
		localTrackAcquisitionStatus: "notStarted",
		disabledCamera: null,
		togglingCamera: false,
		hasAutoSelected: { video: false, audio: false },
		audioContextRunning: false,
		canCreateAudioContext: false,
		videoDeviceId: null,
		audioDeviceId: null,
	};
}

/// REDUCERS - only for use by /hooks/localTracks ///

function setLocalTrackAcquisitionStatus(
	state: ILocalTrackState,
	status: TrackAcquisitionStatus,
): ILocalTrackState {
	const newState: ILocalTrackState = {
		...state,
		localTrackAcquisitionStatus: status,
	};
	return newState;
}

/**
 * Change the device that should be acquired on enabling the camera
 */
function setDisabledCameraId(state: ILocalTrackState, deviceId: string | null): ILocalTrackState {
	return { ...state, disabledCamera: { deviceId } };
}

export function setTogglingCamera(state: ILocalTrackState, isToggling: boolean): ILocalTrackState {
	return { ...state, togglingCamera: isToggling };
}

export function setHasAutoSelectedVideo(state: ILocalTrackState): ILocalTrackState {
	const { hasAutoSelected: newHasAutoSelected } = state;
	newHasAutoSelected.video = true;
	return { ...state, hasAutoSelected: newHasAutoSelected };
}

export function setHasAutoSelectedAudio(state: ILocalTrackState): ILocalTrackState {
	const { hasAutoSelected: newHasAutoSelected } = state;
	newHasAutoSelected.audio = true;
	return { ...state, hasAutoSelected: newHasAutoSelected };
}

function setAudioContextRunning(state: ILocalTrackState, running: boolean): ILocalTrackState {
	return { ...state, audioContextRunning: running };
}

function setCanCreateAudioContext(state: ILocalTrackState, canCreate: boolean): ILocalTrackState {
	if (state.canCreateAudioContext === canCreate) {
		return state; // No need to create new state if it's not changing.
	}
	return { ...state, canCreateAudioContext: canCreate };
}

export function switchVideoDevice(state: ILocalTrackState, device?: MediaDeviceInfo): ILocalTrackState {
	const newDeviceId = device?.deviceId ?? state.videoDeviceId;
	return {
		...state,
		canCreateAudioContext: true,
		disabledCamera: { deviceId: null },
		videoDeviceId: newDeviceId,
	};
}

/// SELECTORS ///

function getLocalTrackAcquisitionStatus(state: ILocalTrackState): TrackAcquisitionStatus {
	return state.localTrackAcquisitionStatus;
}

function getDisabledCamera(state: ILocalTrackState): IDisabledCamera | null {
	return state.disabledCamera;
}

function getTogglingCamera(state: ILocalTrackState): boolean {
	return state.togglingCamera;
}

function getAudioDeviceId(state: ILocalTrackState): string | null {
	return state.audioDeviceId;
}

function getHasAutoSelected(state: ILocalTrackState): boolean {
	const { hasAutoSelected } = state;
	return hasAutoSelected.video && hasAutoSelected.audio;
}

function getIsAudioContextRunning(state: ILocalTrackState): boolean {
	return state.audioContextRunning;
}

function getCanCreateAudioContext(state: ILocalTrackState): boolean {
	return state.canCreateAudioContext;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		setLocalTrackAcquisitionStatus,
		setDisabledCameraId,
		setTogglingCamera,
		setHasAutoSelectedVideo,
		setHasAutoSelectedAudio,
		setAudioContextRunning,
		setCanCreateAudioContext,
	},
	selectors: {
		getLocalTrackAcquisitionStatus,
		getDisabledCamera,
		getTogglingCamera,
		getHasAutoSelected,
		getIsAudioContextRunning,
		getCanCreateAudioContext,
		getAudioDeviceId,
	},
});
store.addSharedState(builtState.sharedState, "localTracks");

export const {
	actionCreators: localTrackActions,
	useSharedState: useLocalTrackState,
	sharedState: state,
} = builtState;
