/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file hook to get which participants should be hidden in the participant strip
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.UseHiddenUsers
 */

import { useEffect, useRef, useState } from "react";
import { useMaxVisibleRemoteParticipants } from "~/components/VideoCall/hooks/useMaxVisibleRemoteParticipants";
import { getUniqueValues } from "~/utils/general";
import { ILocalUser, IUser } from "~/web-core/interfaces";
import { IRemoteUser } from "../web-core/interfaces/remoteUser";

/**
 * Get a list of participant identities that should be hidden from the UI. Participant priorities are:
 * 	1. Currently pinned participant.
 *	2. Participant shown in the PiP window
 * 	3. Participant that is currently sharing their screen.
 * 	4. Participants that have been recently pinned or the dominant speaker (in order of who became one of those roles most recently).
 * 	5. Other participants.
 * @param participants list of the room's remote participants
 * @param pinned currently pinned participant, null if no participant is pinned
 * @param screenShare participant that is sharing their screen, null if no participant is sharing
 * @param pipParticipantId identity of the participant currently shown in the Picture in Picture window
 * @param dominantSpeaker current dominant speaker, null if no dominant speaker
 * @returns list of participant identities that should be hidden from the participant strip
 */
export function useHiddenUsers(
	participants: IRemoteUser[],
	pinned: IUser | null,
	pipParticipantId: string | null,
	remoteScreenShareParticipant: IRemoteUser | null,
	dominantSpeaker: IRemoteUser | null,
	localUser: ILocalUser | null,
): string[] {
	const [hiddenParticipants, setHiddenParticipants] = useState<string[]>([]);
	const [activeParticipantHistory, setActiveParticipantHistory] = useState<string[]>([]);
	const maxVisibleRemoteParticipants = useMaxVisibleRemoteParticipants();
	const currentPinned = useRef(pinned);
	const screenShare = remoteScreenShareParticipant || localUser?.shareStream?.isEnabled("video");

	// keep the currently pinned participant updated and update active participant history
	useEffect(() => {
		if (pinned) {
			currentPinned.current = pinned;
			setActiveParticipantHistory((prevVal) => {
				const newHistory = [pinned.getUserIdentity(), ...prevVal];
				return getUniqueValues(newHistory);
			});
		}
	}, [pinned]);

	// update active participant history
	useEffect(() => {
		if (dominantSpeaker) {
			setActiveParticipantHistory((prevVal) => {
				const newHistory = [dominantSpeaker.getUserIdentity(), ...prevVal];
				return getUniqueValues(newHistory);
			});
		}
	}, [dominantSpeaker]);

	useEffect(() => {
		// if there are less feeds than we have the capacity to show, don't hide anyone
		const feeds = screenShare ? participants.length + 1 : participants.length;
		if (feeds <= maxVisibleRemoteParticipants) {
			setHiddenParticipants([]);
			return;
		}

		// see function header for participant priorities
		let participantsToShow: string[] = [];
		if (currentPinned.current) {
			participantsToShow.push(currentPinned.current.getUserIdentity());
		}
		if (pipParticipantId) {
			participantsToShow.push(pipParticipantId);
		}
		if (remoteScreenShareParticipant) {
			participantsToShow.push(remoteScreenShareParticipant.getUserIdentity());
		}
		participantsToShow.push(...activeParticipantHistory);
		participantsToShow.push(...participants.map((p) => p.getUserIdentity()));

		// filter down to a list of unique entries that are still in the call (keeping the same priority)
		// this will also filter out the local participant if they were pinned
		participantsToShow = getUniqueValues(participantsToShow).filter((id) =>
			participants.some((p) => p.getUserIdentity() === id),
		);

		// determine which participants to show, taking an extra feed for screen sharing into account
		const numShowing = screenShare ? maxVisibleRemoteParticipants - 1 : maxVisibleRemoteParticipants;
		participantsToShow = participantsToShow.slice(0, numShowing);

		setHiddenParticipants(
			participants
				.filter((p) => !participantsToShow.includes(p.getUserIdentity()))
				.map((p) => p.getUserIdentity()),
		);
	}, [
		participants,
		screenShare,
		pipParticipantId,
		activeParticipantHistory,
		maxVisibleRemoteParticipants,
		remoteScreenShareParticipant,
	]);

	return hiddenParticipants;
}
