/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file button to test the user's audio output
 * @author Colin Walters
 * @module Epic.VideoApp.Components.HardwareSetup.Devices.TestSpeakerButton
 */

import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import ActionButton from "~/components/Utilities/ActionButton";
import { useSpeakerDevices, useStrings } from "~/hooks";
import { useLocalTrackState, useSpeakerState, useUserState } from "~/state";
import { ITestable } from "~/types";
import { IS_HTML5_SUPPORTED, IS_SET_SINK_ID_SUPPORTED } from "~/utils/browser";
import { makeResourceLink } from "~/utils/general";

enum TokenNames {
	buttonLabel = "ButtonLabel",
}

export enum TestSpeakerButtonTestIds {
	self = "TestSpeakerButton",
}

/**
 * Props for TestSpeakerButton Component
 */
interface IProps extends ITestable {
	/** Function to be called after the test sound is played */
	onAudioPlayed?: () => void;
}

/**
 * The TestSpeakerButton component
 * @param props The props ;)
 */
const TestSpeakerButton: FC<IProps> = (props) => {
	const { onAudioPlayed, ...additionalProps } = props;
	const canTestSpeaker = useUserState((sel) => sel.getUserPermission("canTestSpeaker"), []);
	const trackAcquisitionStatus = useLocalTrackState((sel) => sel.getLocalTrackAcquisitionStatus(), []);

	const selectedSpeakerId = useSpeakerState((sel) => sel.getSelectedSpeakerId(), []);
	const audioElement = useRef<HTMLAudioElement>(null);
	const [isPlaying, setIsPlaying] = useState(false);

	const speakerCount = useSpeakerDevices().length;
	const disabled = useMemo(() => IS_SET_SINK_ID_SUPPORTED && !speakerCount, [speakerCount]);

	const strings = useStrings("TestSpeakerButton", Object.values(TokenNames));

	const playTestSound = useCallback(async () => {
		if (!audioElement.current) {
			return;
		}
		if (selectedSpeakerId) {
			await audioElement.current.setSinkId?.(selectedSpeakerId);
		}

		if (isPlaying) {
			audioElement.current.pause();
			setIsPlaying(false);
			return;
		}

		audioElement.current.currentTime = 0;
		setIsPlaying(true);
		audioElement.current.onended = () => {
			setIsPlaying(false);
		};
		await audioElement.current.play();
		onAudioPlayed?.();
	}, [selectedSpeakerId, isPlaying, onAudioPlayed]);

	if (!IS_HTML5_SUPPORTED || !canTestSpeaker || trackAcquisitionStatus !== "finished") {
		return null;
	}

	return (
		<>
			<ActionButton
				onClick={playTestSound}
				tone="neutral"
				priority="secondary"
				disabled={disabled}
				active={isPlaying}
				slim
			>
				{strings[TokenNames.buttonLabel]}
			</ActionButton>
			<audio
				ref={audioElement}
				src={makeResourceLink("AudioFile", "test_sound_1A.mp3")}
				{...additionalProps}
			/>
		</>
	);
};

TestSpeakerButton.displayName = "TestSpeakerButton";

export default TestSpeakerButton;
