/**
 * @copyright Copyright 2023-2024 Epic Systems Corporation
 * @file hook to determine the audio level of an audio source
 * @author Chance Overberg
 * @module Epic.VideoApp.WebCore.Hooks.UseAudioLevel
 */

import { useVolumeLevels } from "~/hooks/useVolumeLevels";
import { useRoomState } from "~/state";
import { IStream } from "~/web-core/interfaces";
import { TwilioLocalStream, TwilioRemoteStream } from "~/web-core/vendor/twilio/implementations";
import { VideoVendor } from "../types";

/**
 * Hook to determine the audio level of an audio source
 * @param stream the IStream that holds the audio source to monitor
 * @returns the volume level of the audio source
 */
export function useAudioLevel(stream?: IStream): number {
	const vendor = useRoomState((selectors) => selectors.getVendor(), []);

	let audioTrack = null;

	switch (vendor) {
		case VideoVendor.twilio:
			if (stream instanceof TwilioLocalStream) {
				audioTrack = stream.localAudioTrack ?? null;
			} else if (stream instanceof TwilioRemoteStream) {
				audioTrack = stream.remoteAudioTrack ?? null;
			}
			break;
	}

	const { volumeLevel } = useVolumeLevels(audioTrack);

	return volumeLevel;
}
