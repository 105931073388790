/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file A modal alert that gives information to safely share screens in clinical contexts
 * @author Trevor Roussel
 * @module Epic.VideoApp.Components.Alerts.ScreenShareWarningAlert
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useRef, useState } from "react";
import { useStrings } from "~/hooks";
import { useUserState, userActions } from "~/state";
import { EpicUserType, GuardOptionProperties } from "~/types";
import { resolveClassName } from "~/utils/className";
import { isMacSafari } from "~/utils/os";
import { stringFormat } from "~/utils/strings";
import { saveUpdatedGuardOptions } from "~/utils/userGuardOptions";
import { IUser } from "~/web-core/interfaces";
import { useParticipantName } from "../Participants/hooks/useParticipantName";
import styles from "./Alerts.module.scss";
import ScreenShareWarningAlertButtons from "./Buttons/ScreenShareWarningAlertButtons";
import FocusTrap from "./FocusTrap";

enum TokenNames {
	header = "CautionHeader",
	message = "InstructionMessage",
	macMessage = "MacInstructionMessage",
	dismissTime = "DismissTime",
	hideForever = "HideForever",
	interruptParticipant = "InterruptParticipant",
	interruptParticipantWithName = "InterruptParticipantWithName",
}

interface IProps {
	screenShareParticipant: IUser | null;
}

export enum ScreenShareWarningAlertTestIds {
	self = "ScreenShareWarningAlert",
	continueShare = "ContinueShareButton",
	cancel = "CancelButton",
	dismissBox = "DismissCheckbox",
}

/**
 * ScreenShareWarning alert component
 *
 * Warns the user about considerations when screen sharing and allows the user to continue sharing or cancel.
 * Can also set a userGuardOption to temporarily dismiss the popup to that user.
 * @param props The props ;)
 */
const ScreenShareWarningAlert: FC<IProps> = (props) => {
	const { screenShareParticipant } = props;
	const dispatch = useDispatch();
	const strings = useStrings("ScreenShareWarning", Object.values(TokenNames));

	const dismissTime = useUserState((selectors) => selectors.getGuardDismissalTime(), []);
	const userType = useUserState((selectors) => selectors.getUserType(), []);
	const userKey = useUserState((selectors) => selectors.getUserKey(), []);

	const [alertDismissed, setAlertDismissed] = useState(false);

	const handleDismiss = useCallback(() => {
		setAlertDismissed(!alertDismissed);
	}, [alertDismissed]);

	const saveDismissOption = useCallback(() => {
		if (alertDismissed) {
			dispatch(userActions.setDismissedScreenShareWarning(true));
			saveUpdatedGuardOptions(userKey, GuardOptionProperties.dismissedScreenShareWarning);
		}
	}, [alertDismissed, dispatch, userKey]);

	const shareParticipantName = useParticipantName(screenShareParticipant);

	// String to be appended to the title when the screen share is interrupting another participant
	const interruptString = screenShareParticipant
		? shareParticipantName
			? stringFormat(strings[TokenNames.interruptParticipantWithName], shareParticipantName)
			: strings[TokenNames.interruptParticipant]
		: null;

	const continueRef = useRef<HTMLButtonElement>(null);
	const cancelRef = useRef<HTMLButtonElement>(null);
	const dismissRef = useRef<HTMLInputElement>(null);

	const bodyString = isMacSafari ? strings[TokenNames.macMessage] : strings[TokenNames.message];

	const dismissString =
		dismissTime < 0
			? strings[TokenNames.hideForever]
			: stringFormat(strings[TokenNames.dismissTime], dismissTime);

	const bodyClassName = resolveClassName(styles, { bodyText: true, secondary: true });
	const modalCardClassName = resolveClassName(styles, { modalCard: true, "modalCard-wide": true });

	return (
		<div data-testid={ScreenShareWarningAlertTestIds.self} className={modalCardClassName}>
			<div className={styles["title"]}>{strings[TokenNames.header]}</div>
			{interruptString && <div className={styles["title"]}>{interruptString}</div>}
			<div className={bodyClassName}>{bodyString}</div>
			{/* To handle styling for both the dismiss option case and no dismiss option,
			 * we include a version of the alert without splitButtons container */}
			{dismissTime !== 0 && userType === EpicUserType.emp ? (
				<div className={styles["splitButtons"]}>
					<FocusTrap firstElement={dismissRef} lastElement={cancelRef} defaultElement={continueRef}>
						<div className={styles["checkbox"]}>
							<input
								id="dismissInput"
								type="checkbox"
								aria-checked={alertDismissed}
								onClick={handleDismiss}
								ref={dismissRef}
								data-testid={ScreenShareWarningAlertTestIds.dismissBox}
							/>
							<label htmlFor="dismissInput">{dismissString}</label>
						</div>
						<ScreenShareWarningAlertButtons
							saveDismissOption={saveDismissOption}
							continueRef={continueRef}
							cancelRef={cancelRef}
						/>
					</FocusTrap>
				</div>
			) : (
				<FocusTrap firstElement={continueRef} lastElement={cancelRef} defaultElement={continueRef}>
					<ScreenShareWarningAlertButtons
						saveDismissOption={saveDismissOption}
						continueRef={continueRef}
						cancelRef={cancelRef}
					/>
				</FocusTrap>
			)}
		</div>
	);
};

ScreenShareWarningAlert.displayName = "ScreenShareWarningAlert";

export default ScreenShareWarningAlert;
