/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Component to manage moderator exclusive toasts.
 * @author Will Cooper
 * @module Epic.VideoApp.Components.VideoCall.ModeratorAlerts
 */

import { useDispatch } from "@epic/react-redux-booster";
import { FC, useContext, useEffect } from "react";
import { alertActions, moderationActions, useModerationState } from "~/state";
import { Toast } from "~/types";
import { VideoCallContext, VideoContext } from "~/web-core/components";
import { IEVCParticipantConnectionEvent } from "~/web-core/events";
import { useParticipantName } from "../Participants/hooks/useParticipantName";

enum TokenNames {
	audioRequest = "AudioRequest",
	videoRequest = "VideoRequest",
	screenShareRequest = "ScreenShareRequest",
	audioAndVideoRequest = "AudioAndVideoRequest",
	multipleRequests = "MultipleRequests",
	singleParticipantMuliRequest = "SingleParticipantMultiRequest",
}

/**
 * The ModeratorAlerts component
 */
const ModeratorAlerts: FC = () => {
	// Check underlying state for identity and individual moderator status
	const listOfRequesters = useModerationState((selectors) => selectors.getRequestingParticipants(), []);
	const dispatch = useDispatch();
	const { participants } = useContext(VideoCallContext);
	const firstRequester = listOfRequesters[0];
	const firstRequestUser = participants.find((p) => p.getUserIdentity() === firstRequester?.identity);
	const firstParticipantName = useParticipantName(firstRequestUser || null);
	const { session } = useContext(VideoContext);

	/**
	 * useEffect to manage toasts related to participants requesting media access from the moderator
	 */
	useEffect(() => {
		const listLength = listOfRequesters.length;
		if (listLength > 0) {
			// Setup a new toast
			if (listLength === 1) {
				// Post a toast letting the moderator know a specific user is requesting access (becomes an action button)
				const request = listOfRequesters[0];
				const newToast: Toast = {
					type: "single-participant-request",
					messageToken: getRequestMessageToken(request.audio, request.video, request.screenShare),
					messageArgs: [firstParticipantName],
					extraInfo: { identity: firstRequester?.identity },
				};
				dispatch(alertActions.replaceToast({ newToast, replaceType: "multi-participant-request" }));
			} else {
				// Post a toast letting the moderator know multiple users have requested (opens to general participant menu)
				const newToast: Toast = {
					type: "multi-participant-request",
					messageToken: TokenNames.multipleRequests,
					messageArgs: [listLength.toString()],
				};
				dispatch(
					alertActions.replaceToast({
						newToast,
						replaceType: "single-participant-request",
					}),
				);
			}
		} else {
			// Clear the toasts
			dispatch(
				alertActions.clearMultipleToasts(["single-participant-request", "multi-participant-request"]),
			);
		}
	}, [dispatch, firstParticipantName, firstRequester, listOfRequesters]);

	useEffect(() => {
		// Remove backing alert data when a participant leaves the call.
		const handleParticipantDisconnected = (
			args: IEVCParticipantConnectionEvent<"participantDisconnected">,
		): void => {
			if (listOfRequesters.some((current) => current.identity === args.participant.getUserIdentity())) {
				dispatch(moderationActions.clearParticipantRequest(args.participant.getUserIdentity()));
			}
		};

		session?.on("participantDisconnected", handleParticipantDisconnected);

		return (): void => {
			session?.off("participantDisconnected", handleParticipantDisconnected);
		};
	}, [dispatch, listOfRequesters, session]);

	return null;
};

function getRequestMessageToken(audio: boolean, video: boolean, screenShare: boolean): string {
	if (screenShare && (audio || video)) {
		return TokenNames.singleParticipantMuliRequest;
	} else if (audio && video) {
		return TokenNames.audioAndVideoRequest;
	} else if (audio) {
		return TokenNames.audioRequest;
	} else if (video) {
		return TokenNames.videoRequest;
	} else if (screenShare) {
		return TokenNames.screenShareRequest;
	}

	return "";
}

ModeratorAlerts.displayName = "ModeratorAlerts";

export default ModeratorAlerts;
