/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Component to render the video grid and to properly pass dimensions to the child component
 * @author Will Cooper
 * @module Epic.VideoApp.Components.VideoCall.Grid.GridWrapper
 */

import React, { FC, useMemo } from "react";
import { useHeaderDisplayMode } from "~/components/Header/hooks/useHeaderDisplayMode";
import { useWindowSize } from "~/hooks";
import { useUIState } from "~/state";
import { resolveClassName } from "~/utils/className";
import VideoGrid from "./VideoGrid";
import styles from "./VideoGrid.module.scss";

// Keep in sync with $toggle-bar-height-adjustment in _dimensions.scss
const ButtonTrayHeight = 55;
const gridPagingHeight = 50;
const headerHeight = 61;
const mobileInflectionPoint = 850;
const smallerSideBarInflectionPoint = 1000;
const smallSidebarWidth = 300;
const largeSidebarWidth = 500;

/**
 * The GridWrapper component
 */
const GridWrapper: FC = () => {
	const { height: windowHeight, width: windowWidth } = useWindowSize();
	const buttonTrayClosed = useUIState((selectors) => selectors.getButtonTrayCollapsed(), []);
	const headerDisplayMode = useHeaderDisplayMode();
	const isSidebarOpen = useUIState((selectors) => selectors.getSidebarStatus() === "open", []);

	const adjustForTray = useMemo(() => {
		return !buttonTrayClosed && headerDisplayMode === "button-tray";
	}, [buttonTrayClosed, headerDisplayMode]);

	const gridHeight = useMemo(() => {
		return windowHeight - gridPagingHeight - headerHeight - (adjustForTray ? ButtonTrayHeight : 0);
	}, [adjustForTray, windowHeight]);

	const gridWidth = useMemo(() => {
		if (isSidebarOpen) {
			//Sidebar full screen
			if (windowWidth < mobileInflectionPoint) {
				return windowWidth;
			}

			// SIdebar in smaller width view
			if (windowWidth < smallerSideBarInflectionPoint) {
				return windowWidth - smallSidebarWidth;
			}

			//Sidebar in larger width view
			return windowWidth - largeSidebarWidth;
		}

		return windowWidth;
	}, [isSidebarOpen, windowWidth]);

	const gridWrapperClassName = resolveClassName(styles, {
		gridWrapper: true,
		toggled: adjustForTray,
		sideBarOpen: isSidebarOpen,
	});

	return (
		<div className={gridWrapperClassName}>
			<VideoGrid gridHeight={gridHeight} gridWidth={gridWidth} />
		</div>
	);
};

GridWrapper.displayName = "GridWrapper";

export default GridWrapper;
