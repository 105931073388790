/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Participant menu row for individual users waiting to be admitted to the visit
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.ParticipantList.WaitingParticipantRow
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import ActionButton from "~/components/Utilities/ActionButton";
import { IBasicAuditEvent, useAuditFeatureUse, useStrings } from "~/hooks";
import { useSendVisitAccessMessage } from "~/hooks/messaging";
import { alertActions, useModerationState } from "~/state";
import { AlertType, EventType } from "~/types";
import { stringFormat } from "~/utils/strings";
import { IUser } from "~/web-core/interfaces";
import styles from "./ParticipantList.module.scss";

export enum TokenNames {
	admit = "Admit",
	decline = "Decline",
	prevent = "Prevent",
	cancel = "Cancel",
	confirmationMessage = "ConfirmationMessage",
}

/**
 * Props for ParticipantRow Component
 */
interface IProps {
	/** Participant to show in the row */
	participant: IUser;

	/** Participant's display name */
	displayName: string;
}

/**
 * The WaitingParticipantRow component
 * @param props The props ;)
 */
const WaitingParticipantRow: FC<IProps> = (props) => {
	const { participant, displayName } = props;
	const dispatch = useDispatch();
	const allowAccess = useSendVisitAccessMessage();
	const audioLock = useModerationState((selectors) => selectors.getAudioAdmitOption(), []);
	const videoLock = useModerationState((selectors) => selectors.getVideoAdmitOption(), []);
	const screenShareLock = useModerationState((selectors) => selectors.getScreenShareAdmitOption(), []);
	const auditFeatureUse = useAuditFeatureUse();

	const strings = useStrings("WaitingParticipantRow", Object.values(TokenNames));

	const onAllow = useCallback(async () => {
		const events: IBasicAuditEvent[] = [];
		await allowAccess(participant.getUserIdentity(), displayName);

		// Collect audit events for mute or camera disable events
		if (audioLock) {
			events.push({
				feature: EventType.microphoneLocked,
				targetParticipantId: participant.getUserIdentity(),
			});
		}
		if (videoLock) {
			events.push({
				feature: EventType.cameraLocked,
				targetParticipantId: participant.getUserIdentity(),
			});
		}
		if (screenShareLock) {
			events.push({
				feature: EventType.screenSharingLocked,
				targetParticipantId: participant.getUserIdentity(),
			});
		}

		if (events.length > 0) {
			await auditFeatureUse(events);
		}
	}, [allowAccess, audioLock, auditFeatureUse, displayName, participant, screenShareLock, videoLock]);

	const onReject = useCallback(() => {
		// generate alert pop-up to confirm that the participant should be removed. That will be the source of the web request to remove them
		dispatch(
			alertActions.postChoiceAlert({
				alertData: { identity: participant.getUserIdentity() },
				type: AlertType.removeParticipant,
				message: stringFormat(strings[TokenNames.confirmationMessage], displayName),
				confirmText: strings[TokenNames.prevent],
				confirmHotkey: "p",
				cancelText: strings[TokenNames.cancel],
				cancelHotkey: "c",
			}),
		);
	}, [dispatch, displayName, participant, strings]);

	return (
		<div className={styles["participantRow"]}>
			<div>
				<div className={styles["nameWrapper"]} tabIndex={0}>
					<span>{displayName}</span>
				</div>
				<div className={styles["actionButtonRow"]}>
					<ActionButton
						onClick={onAllow}
						tone="neutral"
						priority="primary"
						text={strings[TokenNames.admit]}
						aria-label={strings[TokenNames.admit] + " " + displayName}
					/>
					<ActionButton
						onClick={onReject}
						text={strings[TokenNames.decline]}
						tone="negative"
						priority="secondary"
						aria-label={strings[TokenNames.decline] + " " + displayName}
					/>
				</div>
			</div>
		</div>
	);
};

WaitingParticipantRow.displayName = "WaitingParticipantRow";

export default React.memo(WaitingParticipantRow);
