/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file provide window.AudioContext to DevicesContext ONLY
 * @author Gavin Lefebvre
 * @module Epic.VideoApp.Components.VideoCall.Hooks.UseSingletonAudioContext
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocalAudioRestartInterval, useRestartContextWhenTrackStarts } from "~/hooks/localTracks";
import { localTrackActions, useLocalTrackState } from "~/state/localTracks";
import { getAudioContext } from "~/utils/audio";
import { secondsToMs } from "~/utils/dateTime";
import { debug } from "~/utils/logging";
import { iOSDetectedAtVersion, iOSVerAudioGainWorkarounds, iOSWebAudioWorkarounds } from "~/utils/os";
import { VideoContext } from "~/web-core/components";
import { TwilioLocalStream } from "~/web-core/vendor/twilio/implementations";

export interface IAudioContextState {
	audioContext: AudioContext | null;
	restartAudioContext: () => Promise<void>;
	overrideIOSRoute: boolean;
}

/** CHANGE VALUE BELOW DURING INTERNAL TESTING for Verbose AudioContext Logging -*-*-*-*-*-*-*-*-*-* */
const printDebug = false;

const IOS15_DELTA_DELTA_INTERVAL = 1000;
const IOS15_DELTA_DELTA_THRESHOLD = 175;

export function useSingletonAudioContext(): IAudioContextState {
	const acquisitionStatus = useLocalTrackState((sel) => sel.getLocalTrackAcquisitionStatus(), []);
	const canCreateAudioContext = useLocalTrackState((sel) => sel.getCanCreateAudioContext(), []);

	const { stream } = useContext(VideoContext);

	// When supporting non-Twilio vendors - expose either MediaStreamTracks or another interface for usage here
	const localVideoTrack = (stream as TwilioLocalStream)?.localVideoTrack ?? null;
	const localAudioTrack = (stream as TwilioLocalStream)?.localAudioTrack ?? null;
	const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
	const [overrideIOSRoute, setOverrideIOSRoute] = useState(false);

	// to avoid transmitted audio eventually becoming distorted on iOS15 versions after 15.4, provide the LocalAudioTrack to this hook
	useLocalAudioRestartInterval(
		iOSDetectedAtVersion("15.4+") && !iOSDetectedAtVersion("16+") ? localAudioTrack : null,
	);

	/** whenever our localAudioTrack fires a started event, we have a new MediaStreamTrack, and on iOS the sample rate can change
	 *  as devices change (i.e. pairing a BT device) restarting AudioContext triggers browser logic to match sample rates */
	useRestartContextWhenTrackStarts(iOSWebAudioWorkarounds ? localAudioTrack : null, audioContext);

	/** Somehow certain iOS devices fail to tear down WebAudio chains even though no reference can be found via the console
	 * queryObject(GainNode) or similar command. New chains seem to be created on LocalVideoTrack changes and are connected,
	 * resulting in perceived echo. Restarting the AudioContext when the LocalVideoTrack changes ensures each graph connects once. */
	useRestartContextWhenTrackStarts(iOSWebAudioWorkarounds ? localVideoTrack : null, audioContext);

	const isAudioContextRunning = useLocalTrackState((sel) => sel.getIsAudioContextRunning(), []);
	const isContextRunningRef = useRef(isAudioContextRunning);

	const contextReferenceTimeRef = useRef(0);
	const deltaTrackerRef = useRef<number[]>([]);
	const dispatch = useDispatch();

	const initOrRestartAudioContextState = useCallback((): void => {
		setAudioContext((prevContext) => {
			if (prevContext) {
				// cleanup when context closes - new context can't use old nodes
				if (prevContext.state === "closed") {
					prevContext.onstatechange = null;
					if (printDebug) {
						console.log("AudioContext closed at browser time", Date.now().toString());
					}
					dispatch(localTrackActions.setAudioContextRunning(false));
					return null;
				}
				return prevContext;
			}
			const context = getAudioContext();

			contextReferenceTimeRef.current = Date.now();
			if (printDebug) {
				console.log("New AudioContext created at browser time", contextReferenceTimeRef.current);
			}
			return context;
		});
	}, [dispatch]);

	useEffect(() => {
		isContextRunningRef.current = isAudioContextRunning;
	}, [isAudioContextRunning]);

	/** iOS 15.0-15.3: whenever we get a new AudioContext, set up an interval to compare its internal time vs. browser time.
	 *	This will manage a queue of the 3  most recent deltas between the browser's time and the AudioContext time,
	 *	which we can use to reliably detect when our AudioContext is at risk in order to fall back to HTML5 audio
	 *	(which will be quieter, but the timing mismatch is caused by an external audio device, so that's ok).
	 *	Similarly, if the external device disconnects, the delta values will stabilize and we close our tainted
	 *	AudioContext, creating a new one that we'll use for Web Audio output (until we detect clock deviation and
	 *	start the cycle over again).
	 */
	useEffect(() => {
		if (!iOSVerAudioGainWorkarounds || !audioContext) {
			return;
		}
		const handle = window.setInterval(() => {
			if (!audioContext) {
				window.clearInterval(handle);
				return;
			}
			if (isAudioContextRunning) {
				const currentContextTime = Math.round(secondsToMs(audioContext.currentTime));
				const currentBrowserTime = Date.now() - contextReferenceTimeRef.current;
				const contextBrowserDelta = currentContextTime - currentBrowserTime;
				if (printDebug) {
					console.log(
						"Audio time:",
						currentContextTime,
						"Browser Time:",
						currentBrowserTime,
						"Delta:",
						contextBrowserDelta,
						"(all values in milliseconds)",
						`<< Currently using ${overrideIOSRoute ? "HTML5" : "WebAudio"} >>`,
					);
				}
				/** the delta should lean towards the negative since audioContext.currentTime is "the sample frame
					immediately following the last sample-frame in the block of audio most recently processed by the
					context’s rendering graph." (i.e. the next)
				*/
				deltaTrackerRef.current.push(contextBrowserDelta);
				if (deltaTrackerRef.current.length > 3) {
					const oldDelta = deltaTrackerRef.current.shift();
					if (oldDelta) {
						const deltaDelta = Math.abs(oldDelta - contextBrowserDelta);
						if (deltaDelta > IOS15_DELTA_DELTA_THRESHOLD && !overrideIOSRoute) {
							console.log("Timing deviation detected; falling back to HTML5");
							deltaTrackerRef.current = [];
							void audioContext.close();
							setOverrideIOSRoute(true);
						} else if (deltaDelta <= IOS15_DELTA_DELTA_THRESHOLD && overrideIOSRoute) {
							console.log("Timing appears aligned; using Web Audio output");
							deltaTrackerRef.current = [];
							void audioContext.close();
							setOverrideIOSRoute(false);
						}
					}
				}
			}
		}, IOS15_DELTA_DELTA_INTERVAL);
		return () => {
			window.clearInterval(handle);
		};
	}, [audioContext, isAudioContextRunning, overrideIOSRoute]);

	// exported function to trigger creation of a new AudioContext
	const restartAudioContext = useCallback(async (): Promise<void> => {
		if (audioContext && audioContext.state !== "closed") {
			try {
				audioContext.onstatechange = null;
				await audioContext.close();
			} catch (reason: any) {
				debug(reason);
			}
		}
		initOrRestartAudioContextState();
	}, [audioContext, initOrRestartAudioContextState]);

	const updateRunningStateAndTimeRef = useCallback(
		(isRunningNow: boolean, contextTimeInSeconds: number): void => {
			/** update our contextReferenceTimeRef any time state changes. Will only be calculated against when
			 * 	the context is actually running 	*/
			if (isRunningNow !== isContextRunningRef.current) {
				contextReferenceTimeRef.current = Date.now() - Math.round(secondsToMs(contextTimeInSeconds));
				if (printDebug) {
					console.log(
						`AudioContext now ${isRunningNow ? "" : "not"} running at browser time`,
						contextReferenceTimeRef.current,
					);
				}
			}
			dispatch(localTrackActions.setAudioContextRunning(isRunningNow));
		},
		[dispatch],
	);

	useEffect(() => {
		/** don't start the context until we have acquired initial tracks and can create audio context - typically WebAudio requires
			user interaction to start the AudioContext, but an approved/resolved getUserMedia call works as well
		*/
		if (acquisitionStatus !== "finished" || !canCreateAudioContext) {
			return;
		}
		/** don't run the rest of this effect without an AudioContext */
		if (!audioContext) {
			dispatch(localTrackActions.setAudioContextRunning(false));
			initOrRestartAudioContextState();
			return;
		}

		/**	helper function to attempt to resume non-running AudioContext, creating a new one if this fails */
		const resumeOrRestartContext = async (notRunningContext: AudioContext): Promise<void> => {
			/**	otherwise, look to resume; if that fails, use the restart function  */
			try {
				await notRunningContext.resume();
			} catch {
				await restartAudioContext();
			}
		};

		/** callback function that runs for each AudioContext 'statechange' event */
		async function onContextStateChange(this: BaseAudioContext, _ev: Event): Promise<void> {
			const ctx = this as AudioContext;
			if (printDebug) {
				console.log("AudioContext state changed to: ", ctx.state);
			}
			// once the context is closed, it can't be resumed nor nodes reused, so spawn a new one
			if (ctx.state === "closed") {
				initOrRestartAudioContextState();
				return;
			}

			updateRunningStateAndTimeRef(ctx.state === "running", ctx.currentTime);
			if (ctx.state !== "running") {
				await resumeOrRestartContext(ctx);
			}
		}
		audioContext.onstatechange = onContextStateChange;

		updateRunningStateAndTimeRef(audioContext.state === "running", audioContext.currentTime);

		// if context starts as not-running, it should be resumable
		if (audioContext.state !== "running") {
			void resumeOrRestartContext(audioContext);
		}

		return () => {
			audioContext.onstatechange = null;

			if (audioContext.state !== "closed") {
				void audioContext.close();
				dispatch(localTrackActions.setAudioContextRunning(false));
			}
		};
	}, [
		audioContext,
		acquisitionStatus,
		canCreateAudioContext,
		dispatch,
		initOrRestartAudioContextState,
		updateRunningStateAndTimeRef,
		restartAudioContext,
	]);

	return {
		audioContext,
		restartAudioContext,
		overrideIOSRoute,
	};
}
