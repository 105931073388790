/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to handle actions resulting from a received track message
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.Messaging.UseReceivedDataTrackMessage
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback } from "react";
import { useImageCapturedHandler } from "~/components/ImageCapture/hooks/useImageCapturedHandler";
import { combinedActions, moderationActions, useUserState } from "~/state";
import {
	EpicUserType,
	IAccessRequestPayload,
	IDataTrackMessage,
	INamelessParticipantSyncRequestPayload,
	INamelessParticipantSyncResponsePayload,
	IParticipantUpdatePayload,
	MessageActionType,
	MessageType,
} from "~/types";
import { tryParseJSON } from "~/utils/messaging";
import { useJoinVisitHandler } from "./moderation/useJoinVisitHandler";
import { useModeratedMessageHandler } from "./moderation/useModeratedMessageHandler";
import { useBroadcastParticipantInfo } from "./useBroadcastParticipantInfo";

/**
 * Processes a Data Track message from a remote participant.
 * The message will include a type that informs us how to process its contents.
 * This hook will both process the message and handle any necessary response action.
 * @returns a function to handle an incoming JSON data track messages
 */
export function useReceivedDataTrackMessage(
	localParticipantId: string,
	handleNamelessSyncRequest: (payload: INamelessParticipantSyncRequestPayload) => void,
	handleNamelessSyncResponse: (payload: INamelessParticipantSyncResponsePayload) => void,
): (json: string, senderIdentity: string) => void {
	const dispatch = useDispatch();
	const broadcastInfo = useBroadcastParticipantInfo();
	const joinVisitCallback = useJoinVisitHandler();
	const canModerate = useUserState((selectors) => selectors.getUserPermission("canModerateVisit"), []);
	const handleModerateMessage = useModeratedMessageHandler();
	const handleImageCaptured = useImageCapturedHandler();

	const handleMediaAccessRequest = useCallback(
		(payload: IAccessRequestPayload, identity: string) => {
			if (!canModerate) {
				return;
			}

			// Switch this to a dispatch that updates the shared participant state to show who has made requests
			dispatch(
				moderationActions.postParticipantRequest({
					identity: identity,
					audio: payload.audioRequest,
					video: payload.videoRequest,
					screenShare: payload.screenShareRequest,
				}),
			);
		},
		[canModerate, dispatch],
	);

	const setParticipantInfoFromMessage = useCallback(
		(messagePayload: IParticipantUpdatePayload, identity: string) => {
			// Verify the message payload includes all of the data we need to perform our state actions
			if (!messagePayload || !Object.values(EpicUserType).includes(messagePayload.userType)) {
				return;
			}

			//Set the message sender's display name and other info
			dispatch(
				combinedActions.setParticipantInfo({
					identity: identity,
					displayName: messagePayload.displayName,
					userType: messagePayload.userType,
					cameraLocked: messagePayload.cameraLocked,
					micLocked: messagePayload.micLocked,
					screenShareAllowed: messagePayload.screenShareAllowed,
					screenShareLocked: messagePayload.screenShareLocked,
					deviceSupportsScreenShare: messagePayload.deviceSupportsScreenShare,
					inWaitingRoom: messagePayload.inWaitingRoom,
				}),
			);
			return;
		},
		[dispatch],
	);

	const messageReceived = useCallback(
		async (json: string, senderIdentity: string) => {
			// Verify the message can be mapped to an object type
			// Parsing individual fields follows
			const message: IDataTrackMessage | null = tryParseJSON(json);

			// If initial parse failed, nothing else we can do
			if (!message) {
				return;
			}
			// Check action type to verify this client should be processing this.
			// If this is a request not intended for this client, stop processing
			if (message.action === MessageActionType.request) {
				if (
					!message.recipients ||
					!message.recipients.find((recipient: string) => recipient === localParticipantId)
				) {
					return;
				}
			} else {
				// Don't include recipients on non-request messages
				// Consider the request ill-formatted
				if (message.recipients) {
					return;
				}
			}

			if (senderIdentity === localParticipantId) {
				return;
			}

			// Insert future message types and error handling
			// Verify individual payloads in message resolution functions
			switch (message.type) {
				case MessageType.participantInfo:
					setParticipantInfoFromMessage(message.payload, senderIdentity);
					break;
				case MessageType.namelessParticipantSyncRequest:
					handleNamelessSyncRequest(message.payload);
					break;
				case MessageType.namelessParticipantSyncResponse:
					handleNamelessSyncResponse(message.payload);
					break;
				case MessageType.infoRequest:
					broadcastInfo();
					break;
				case MessageType.moderate:
					await handleModerateMessage(message.payload);
					break;
				case MessageType.visitAccess:
					await joinVisitCallback(message.payload);
					break;
				case MessageType.accessRequest:
					handleMediaAccessRequest(message.payload, senderIdentity);
					break;
				case MessageType.imageCaptured:
					handleImageCaptured(message.payload);
					break;
				default:
					break;
			}
		},
		[
			localParticipantId,
			setParticipantInfoFromMessage,
			handleNamelessSyncRequest,
			handleNamelessSyncResponse,
			broadcastInfo,
			handleModerateMessage,
			joinVisitCallback,
			handleMediaAccessRequest,
			handleImageCaptured,
		],
	);

	return messageReceived;
}
