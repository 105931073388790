/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file background selector
 * @author Liam Liden
 * @module Epic.VideoApp.Components.BackgroundSelector.BackgroundSelector
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useSelectedCameraId, useStrings } from "~/hooks";
import {
	alertActions,
	useBackgroundProcessorsState,
	useHardwareTestState,
	useLocalTrackState,
} from "~/state";
import { DeviceStatus } from "~/types";
import { warn } from "~/utils/logging";
import { VideoContext } from "~/web-core/components";
import { ILocalStream } from "~/web-core/interfaces";
import ApplyBackgroundButton from "./ApplyBackgroundButton";
import BackgroundGallery from "./BackgroundGallery";
import BackgroundPreview from "./BackgroundPreview";
import styles from "./BackgroundSelector.module.scss";

enum TokenNames {
	previewFailed = "PreviewFailed",
}

/**
 * The BackgroundSelector component
 */
const BackgroundSelector: FC = () => {
	const dispatch = useDispatch();
	const { session } = useContext(VideoContext);
	const localUser = session?.localUser;
	const [previewStream, setPreviewStream] = useState<ILocalStream | null>(null);

	const cameraStatus = useHardwareTestState((selectors) => selectors.getCameraStatus(false), []);
	const disabledCamera = useLocalTrackState((selectors) => selectors.getDisabledCamera(), []);

	const publishedProcessor =
		useBackgroundProcessorsState((selectors) => selectors.getPublishedProcessorFromMap(), []) ?? null;

	const strings = useStrings("BackgroundSelector", Object.values(TokenNames));
	const stringsRef = useRef(strings);

	const camera = useSelectedCameraId();

	const failedTrackCreationRef = useRef(false);

	useEffect(() => {
		if (previewStream) {
			return;
		}
		// If user closes BG Effects menu during preview track creation, make sure to NOT set state and detach + stop track to avoid memory leaks
		let isMounted = true;

		const createPreviewTrack = async (): Promise<void> => {
			if (!localUser) {
				return;
			}
			const previewStream = await localUser.createDevicePreviewStream(disabledCamera?.deviceId ?? null);
			if (publishedProcessor) {
				await localUser.applyVideoBackground(publishedProcessor, previewStream);
			}
			setPreviewStream(previewStream);
			try {
				if (!isMounted) {
					// User closed menu (unmounted) before track was created so cleanup
					previewStream?.cleanUp();
				}
			} catch (error) {
				// If track creation fails, we will use the disabled camera indicator rather than a spinner
				failedTrackCreationRef.current = true;
				dispatch(alertActions.postGenericAlert(stringsRef.current[TokenNames.previewFailed]));
				warn("Preview track creation failed", error);
			}
		};
		if (cameraStatus === DeviceStatus.success) {
			void createPreviewTrack();
		}

		return () => {
			isMounted = false;
		};
	}, [dispatch, publishedProcessor, cameraStatus, camera, previewStream, localUser, disabledCamera]);

	// Cleanup previewVideoTrack on unmount (exiting BG Effects menu)
	// If unmount happens before previewVideoTrack is created, this won't cleanup correctly (why we need isMounted in above useEffect)
	useEffect(() => {
		return () => {
			previewStream?.cleanUp();
		};
	}, [previewStream]);

	return (
		<div className={styles["backgroundSelector"]}>
			{previewStream && (
				<BackgroundPreview
					stream={previewStream}
					disabled={cameraStatus !== DeviceStatus.success || failedTrackCreationRef.current}
				/>
			)}
			{previewStream && <BackgroundGallery previewStream={previewStream} />}
			<ApplyBackgroundButton />
		</div>
	);
};

BackgroundSelector.displayName = "BackgroundSelector";

export default BackgroundSelector;
