/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Confirmation alert that will appear when a moderator attempts to end the visit for all connected parties
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Alerts.EndVisitConfirmationAlert
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import { alertActions, useAuthState } from "~/state";
import { IChoiceAlert } from "~/types";
import { makeRequest } from "~/utils/request";
import ChoiceAlertBase from "./ChoiceAlertBase";

interface IProps {
	alert: IChoiceAlert;
}

enum TokenNames {
	errorMessage = "ErrorMessage",
}

export enum EndVisitConfirmationAlertTestIds {
	self = "EndVisitConfirmationAlert",
}

/**
 * EndVisitConfirmation alert component
 *
 * Renders a final confirmation message to allow the user to choose to end the visit for all or return to the visit
 */
const EndVisitConfirmationAlert: FC<IProps> = (props: IProps) => {
	const { alert } = props;
	const dispatch = useDispatch();
	const JWT = useAuthState((selectors) => selectors.getJWT(), []);
	const strings = useStrings("EndVisitAlert", Object.values(TokenNames));

	const endCall = useCallback(() => {
		async function endCallForAll(): Promise<void> {
			try {
				await endVisit(JWT || "");
			} catch {
				dispatch(alertActions.postGenericAlert(strings[TokenNames.errorMessage]));
			}
		}

		void endCallForAll();
		dispatch(alertActions.clearAlert());
	}, [JWT, dispatch, strings]);

	const cancel = useCallback(() => {
		dispatch(alertActions.clearAlert());
	}, [dispatch]);

	return (
		<ChoiceAlertBase
			data-testid={EndVisitConfirmationAlertTestIds.self}
			alert={alert}
			confirm={endCall}
			cancel={cancel}
		/>
	);
};

function endVisit(jwt: string): Promise<void> {
	return makeRequest("/api/VideoCall/EndVisit", "POST", jwt);
}

EndVisitConfirmationAlert.displayName = "EndVisitConfirmationAlert";

export default EndVisitConfirmationAlert;
