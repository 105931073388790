/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Example grid or brady bunch view of other participants
 * @author Will Cooper
 * @module Epic.VideoApp.Components.VideoCall.Grid.VideoGrid
 */

import React, { FC, useContext } from "react";
import CallParticipant from "~/components/Participants/CallParticipant";
import { useUIState } from "~/state";
import { VideoCallContext, VideoContext } from "~/web-core/components";
import DominantSpeakerAlert from "./DominantSpeakerAlert";
import styles from "./VideoGrid.module.scss";
import {
	useGridPageInformation,
	useScaleVideoGrid,
	useStaticVideoFeeds,
	useVisibleGridParticipants,
} from "./hooks";

interface IGridProps {
	gridHeight: number;
	gridWidth: number;
}

/**
 * The VideoGrid component
 */
const VideoGrid: FC<IGridProps> = (props: IGridProps) => {
	const { gridHeight, gridWidth } = props;
	const { screenShareParticipant, participants } = useContext(VideoCallContext);
	const { session } = useContext(VideoContext);
	const currentGridPage = useUIState((selectors) => selectors.getCurrentPage(), []);

	const staticFeeds = useStaticVideoFeeds(session, screenShareParticipant ?? null);

	// Determine how the current screen size and participant counts affect whether or not paging will be involved on the current device
	// Return how many remote participants we can comfortably fit on screen

	const remoteFeedLimit = useGridPageInformation(
		gridHeight,
		gridWidth,
		staticFeeds.length,
		participants.length,
	);

	// Select the participants to show based on the number we can show per page and the current page we are on
	const remoteParticipantsToRender = useVisibleGridParticipants(
		currentGridPage,
		remoteFeedLimit,
		participants,
	);

	// Now that we know the on-screen participants, scale them appropriately within the view space
	const videoSize = useScaleVideoGrid(
		gridHeight,
		gridWidth,
		remoteParticipantsToRender.length + staticFeeds.length,
	);

	if (remoteParticipantsToRender.length === 0 && staticFeeds.length === 0) {
		return null;
	}

	return (
		<div className={styles["videoGrid"]}>
			<DominantSpeakerAlert
				visibleParticipants={remoteParticipantsToRender.map((p) => p.participant)}
			/>
			{remoteParticipantsToRender.map((current) => {
				const currentParticipant = current;
				const displayName = currentParticipant.displayName;
				return (
					<CallParticipant
						key={currentParticipant.participant.getUserIdentity()}
						participant={currentParticipant.participant}
						displayName={displayName}
						videoSize={videoSize}
						inGrid
					/>
				);
			})}
			{staticFeeds.map((current) => {
				const videoType = current.isScreenShare ? "screen" : "camera";
				const isLocal = current.participant === session?.localUser;
				return (
					<CallParticipant
						key={current.participant.getUserIdentity().concat(videoType)}
						isLocal={isLocal}
						participant={current.participant}
						screenOnly={videoType === "screen"}
						videoSize={videoSize}
						inGrid
					/>
				);
			})}
		</div>
	);
};

VideoGrid.displayName = "VideoGrid";

export default VideoGrid;
