/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file File to watch for changes to the number of waiting participants and post a toast to those with the ability to let them into the call
 * @author Will Cooper
 * @module Epic.VideoApp.Components.VideoCall.WaitingParticipantAlert
 */

import { useDispatch } from "@epic/react-redux-booster";
import { FC, useContext, useEffect } from "react";
import { alertActions } from "~/state";
import { Toast } from "~/types";
import { VideoCallContext } from "~/web-core/components";
import { useParticipantName } from "../Participants/hooks/useParticipantName";

enum TokenNames {
	userWaiting = "UserWaiting",
	usersWaiting = "UsersWaiting",
}

/**
 * The WaitingParticipantAlert component
 */
const WaitingParticipantAlert: FC = () => {
	const { waitingParticipants } = useContext(VideoCallContext);

	const dispatch = useDispatch();
	const firstParticipant = waitingParticipants?.[0] || null;
	const initialName = useParticipantName(firstParticipant);
	useEffect(() => {
		// Have moderators watch for waiting participants
		const length = waitingParticipants.length;
		if (length > 0) {
			// Setup a new toast
			if (length === 1 && !!firstParticipant) {
				// Post a toast letting the moderator know a specific user is waiting
				const newToast: Toast = {
					type: "single-participant-waiting",
					messageToken: TokenNames.userWaiting,
					messageArgs: [initialName],
					extraInfo: {
						identity: firstParticipant.getUserIdentity(),
					},
				};
				dispatch(alertActions.replaceToast({ newToast, replaceType: "multi-participant-waiting" }));
			} else {
				const newToast: Toast = {
					type: "multi-participant-waiting",
					messageToken: TokenNames.usersWaiting,
					messageArgs: [length.toString()],
				};
				// Post a toast letting the moderator know multiple users are waiting
				dispatch(alertActions.replaceToast({ newToast, replaceType: "single-participant-waiting" }));
			}
		} else {
			// Clear any potential toasts
			dispatch(
				alertActions.clearMultipleToasts(["single-participant-waiting", "multi-participant-waiting"]),
			);
		}
	}, [dispatch, firstParticipant, initialName, waitingParticipants]);

	return null;
};

WaitingParticipantAlert.displayName = "WaitingParticipantAlert";

export default WaitingParticipantAlert;
