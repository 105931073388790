/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Centralized handler to reinit audio for iOS backgrounding/Siri interruptions
 * @author Gavin Lefebvre
 * @module Epic.VideoApp.Hooks.LocalTracks.UseLocalAudioRecovery
 */

import { useCallback, useRef } from "react";
import { iOSVerRenderingWorkarounds } from "~/utils/os";
import { useAudioTrackActions } from ".";
import useHandleAudioContext from "../useHandleAudioContext";

export function useLocalAudioRecovery(): (shouldReEnable?: boolean) => void {
	const { restartLocalAudioTrack } = useAudioTrackActions();
	const tryHandleAudioContext = useHandleAudioContext();
	const isActiveLockoutRef = useRef(false);

	const tryLocalAudioRecovery = useCallback(
		(tryEnable?: boolean): void => {
			if (isActiveLockoutRef.current) {
				return;
			}
			isActiveLockoutRef.current = true;

			/**	restart the LocalAudioTrack via new function from useAudioTrackActions so that the existing
			 * 	device can be requested, and all related logic in that hook can execute. That hook will set
			 * 	the enabled property of the "new" LocalAudioTrack (which is really the same LocalAudioTrack
			 *  object, but with a new MediaStreamTrack)
			 */
			void restartLocalAudioTrack(tryEnable)
				/** Call our helper function to massage the AudioContext into the proper state, checking its state,
				 * sampleRate, etc and calling close() on it to generate a replacement if needed
				 */
				.then(() => tryHandleAudioContext(iOSVerRenderingWorkarounds))
				.finally(() => (isActiveLockoutRef.current = false));
		},

		[tryHandleAudioContext, restartLocalAudioTrack],
	);

	return tryLocalAudioRecovery;
}
