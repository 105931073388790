/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Types for Local Tracks
 * @author Trevor Roussel
 * @module Epic.VideoApp.Types.LocalTracks
 */

import { LocalAudioTrack, LocalVideoTrack } from "twilio-video";

export interface IScreenShareState {
	track: LocalVideoTrack;
	audioTrack: LocalAudioTrack | undefined;
}
