/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file types for the core session interface
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Types.Session
 */

export type SessionConnectionStatus = "connected" | "disconnected" | "reconnecting" | "not-connected";

export enum VideoVendor {
	unknown = 0,
	twilio = 1,
}
