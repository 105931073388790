/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file Shared state for the call room info
 * @author Spencer Eanes
 * @module Epic.VideoApp.State.Room
 */

import { buildSharedState } from "@epic/react-redux-booster";
import { Room } from "twilio-video";
import store from "~/app/store";
import { DisplayNameInputMaxLength, EpicUserType, ICallRoomInfo, IParticipantInfo } from "~/types";
import { sanitizeInput } from "~/utils/text";
import { VideoVendor } from "~/web-core/types";

/// TYPES ///

export interface IParticipantRequest {
	identity: string;
	audio: boolean;
	video: boolean;
	screenShare: boolean;
}

export interface IRoomState {
	readonly roomInfo: ICallRoomInfo | null;
	readonly canConnect: boolean;
	readonly isConnecting: boolean;
	readonly isDisconnecting: boolean;
	readonly participantInfo: IParticipantInfo[];
	readonly localDisplayName: string;
	readonly lowBandwidthMode: boolean;
	readonly waitingRoomUrl: string;
	readonly clientLoggingInterval: number; //interval in seconds
	readonly debuggingLogLevel: DebuggingLogLevel;
	readonly shouldCheckBrowserCompatibility: boolean; //if we should disable virtual backgrounds depending on browser version
	readonly vendor: VideoVendor;
}

export enum DebuggingLogLevel {
	none = 0,
	low = 1,
	verbose = 2,
}

/// INIT ///

export function getInitialState(isDisconnecting: boolean = false, state?: IRoomState): IRoomState {
	return {
		roomInfo: null,
		canConnect: false,
		isConnecting: false,
		isDisconnecting,
		participantInfo: [],
		localDisplayName: "",
		lowBandwidthMode: false,
		waitingRoomUrl: "",
		clientLoggingInterval: -1,
		debuggingLogLevel: DebuggingLogLevel.none,
		shouldCheckBrowserCompatibility: false,
		// Preserve the vendor when disconnecting to drive certain post-call workflows (like user feedback survey)
		vendor: isDisconnecting && state ? state.vendor : VideoVendor.unknown,
	};
}

/// REDUCERS ///

function setRoomInfo(state: IRoomState, updateState: ICallRoomInfo): IRoomState {
	const newState = {
		...state,
		roomInfo: updateState,
	};
	return newState;
}

function setConferenceDateIso(state: IRoomState, conferenceDateIso: string): IRoomState {
	const oldRoomInfo = state.roomInfo ?? { token: null, roomID: null, userID: null, patientName: null };
	const newRoomInfo = { ...oldRoomInfo, conferenceDateIso: conferenceDateIso };

	return { ...state, roomInfo: newRoomInfo };
}

function setRoom(state: IRoomState, newRoom: Room): IRoomState {
	const newState = {
		...state,
		room: newRoom,
	};
	return newState;
}

export function setParticipantInfo(state: IRoomState, info: IParticipantInfo): IRoomState {
	if (!info.identity) {
		return state;
	}
	// Attempt to remove any previous participant info for the current user before adding info
	const newState = clearParticipantInfo(state, info.identity);
	newState.participantInfo.push(info);

	return newState;
}

export function clearParticipantInfo(state: IRoomState, identity: string): IRoomState {
	const newInfo = state.participantInfo.filter((entry: IParticipantInfo) => entry.identity !== identity);
	return { ...state, participantInfo: newInfo };
}

export function setCanConnect(state: IRoomState): IRoomState {
	return { ...state, canConnect: true };
}

function setLocalDisplayName(state: IRoomState, name: string | null): IRoomState {
	const newState = {
		...state,
		localDisplayName: sanitizeInput(name?.trim() ?? "", DisplayNameInputMaxLength),
	};
	return newState;
}

function setIsConnecting(state: IRoomState, connecting: boolean): IRoomState {
	const newState = {
		...state,
		isConnecting: connecting,
	};
	return newState;
}

function setIsDisconnecting(state: IRoomState): IRoomState {
	const newState = {
		...state,
		isDisconnecting: true,
	};
	return newState;
}

export function setLowBandwidth(state: IRoomState, newValue: boolean): IRoomState {
	return { ...state, lowBandwidthMode: newValue };
}

export function setShouldCheckBrowserCompatibility(state: IRoomState, newValue: boolean): IRoomState {
	return { ...state, shouldCheckBrowserCompatibility: newValue };
}

export function setWaitingRoomUrl(state: IRoomState, newValue: string): IRoomState {
	return { ...state, waitingRoomUrl: newValue };
}

function setClientLoggingInterval(state: IRoomState, interval: number): IRoomState {
	return { ...state, clientLoggingInterval: interval };
}

function setDebuggingLogLevel(state: IRoomState, logLevel: DebuggingLogLevel): IRoomState {
	return { ...state, debuggingLogLevel: logLevel };
}

export function setVendor(state: IRoomState, vendor: VideoVendor): IRoomState {
	return { ...state, vendor: vendor };
}

/// SELECTORS ///

function getPatientName(state: IRoomState): string | null {
	return state.roomInfo?.patientName ?? null;
}

function getConferenceDateIso(state: IRoomState): string | null {
	return state.roomInfo?.conferenceDateIso ?? null;
}

function getCanConnect(state: IRoomState): boolean {
	return state.canConnect;
}

function getIsConnecting(state: IRoomState): boolean {
	return state.isConnecting;
}

function getIsDisconnecting(state: IRoomState): boolean {
	return state.isDisconnecting;
}

function getParticipantInfo(state: IRoomState, identity: string): IParticipantInfo | undefined {
	return state.participantInfo.find((participant) => participant.identity === identity);
}

function getParticipantsInWaitingRoom(state: IRoomState): string[] {
	return state.participantInfo.filter((participant) => participant.inWaitingRoom).map((p) => p.identity);
}

function getParticipantModerationLocks(
	state: IRoomState,
	identity: string,
): { audio: boolean; video: boolean; screenShare: boolean } {
	const current = state.participantInfo.find((participant) => participant.identity === identity);
	return {
		video: current?.cameraLocked || false,
		audio: current?.micLocked || false,
		screenShare: current?.screenShareLocked || false,
	};
}

function getAllParticipantNames(state: IRoomState): Record<string, string> {
	const names: Record<string, string> = {};
	state.participantInfo.forEach((info) => {
		names[info.identity] = info.displayName;
	});
	return names;
}

function getAllParticipantsByType(state: IRoomState, types: EpicUserType[]): string[] {
	if (!types || types.length < 1) {
		return [];
	}

	return state.participantInfo
		.filter((current) => types.includes(current.userType))
		.map((user) => user.identity);
}

function getAllParticipantInfo(state: IRoomState): IParticipantInfo[] {
	return state.participantInfo;
}

function getLocalDisplayName(state: IRoomState): string {
	return state.localDisplayName;
}

function getIsLowBandwidthMode(state: IRoomState): boolean {
	return state.lowBandwidthMode;
}

function getShouldCheckBrowserCompatibility(state: IRoomState): boolean {
	return state.shouldCheckBrowserCompatibility;
}

function getWaitingRoomUrl(state: IRoomState): string {
	return state.waitingRoomUrl;
}

function getClientLoggingInterval(state: IRoomState): number {
	return state.clientLoggingInterval;
}

function getDebuggingLogLevel(state: IRoomState): DebuggingLogLevel {
	return state.debuggingLogLevel;
}

function getRemoteUserType(state: IRoomState, identity: string): EpicUserType {
	return (
		state.participantInfo.find((participant) => participant.identity === identity)?.userType ||
		EpicUserType.unknown
	);
}

function getVendor(state: IRoomState): VideoVendor {
	return state.vendor;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		setConferenceDateIso,
		setRoomInfo,
		setRoom,
		setIsConnecting,
		setIsDisconnecting,
		setLocalDisplayName,
		setClientLoggingInterval,
		setDebuggingLogLevel,
		setLowBandwidth,
		setWaitingRoomUrl,
		setShouldCheckBrowserCompatibility,
		clearParticipantInfo,
	},
	selectors: {
		getPatientName,
		getConferenceDateIso,
		getCanConnect,
		getIsConnecting,
		getIsDisconnecting,
		getParticipantInfo,
		getAllParticipantNames,
		getLocalDisplayName,
		getAllParticipantsByType,
		getAllParticipantInfo,
		getParticipantModerationLocks,
		getIsLowBandwidthMode,
		getWaitingRoomUrl,
		getClientLoggingInterval,
		getDebuggingLogLevel,
		getRemoteUserType,
		getShouldCheckBrowserCompatibility,
		getParticipantsInWaitingRoom,
		getVendor,
	},
});

store.addSharedState(builtState.sharedState, "room");

export const { actionCreators: roomActions, useSharedState: useRoomState, sharedState: state } = builtState;
