/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Utility to detect the operating system from the user agent
 * @author Matt Panico
 * @module Epic.VideoApp.Utils.Os
 */

import {
	clientSupportsUAHints,
	isSafari,
	SafariDetectableVersions,
	safariDetectedAtVersion,
} from "./browser";

export enum OS {
	ios,
	mac,
	android,
	windows,
	chromeOS,
	linux,
	other,
}

export function isIOS(): boolean {
	// definitely iOS
	if (/iPad|iPhone|iPod|iOS/i.test(navigator.platform)) {
		return true;
	}

	// iPad pretending to be a mac
	if (/mac/i.test(navigator.platform) && navigator.maxTouchPoints > 1) {
		return true;
	}

	return false;
}

function isMac(): boolean {
	return /mac/i.test(navigator.platform) && !isIOS();
}

function isAndroid(): boolean {
	return /android/i.test(navigator.platform) || /android/i.test(navigator.userAgent);
}

function isWindows(): boolean {
	return /win/i.test(navigator.platform);
}

function isChromeOS(): boolean {
	return /cros/i.test(navigator.platform);
}

function isLinux(): boolean {
	return /ubuntu|linux/i.test(navigator.platform);
}

export function detectOS(): OS {
	if (isIOS()) {
		return OS.ios;
	}

	if (isAndroid()) {
		return OS.android;
	}

	if (isWindows()) {
		return OS.windows;
	}

	// make sure this comes after isIOS, some iPads like to play pretend
	if (isMac()) {
		return OS.mac;
	}

	if (isLinux()) {
		return OS.linux;
	}

	if (isChromeOS()) {
		return OS.chromeOS;
	}

	return OS.other;
}

export function isMobile(): boolean {
	const myOS = detectOS();
	return [OS.android, OS.ios].includes(myOS);
}

export type UserAgentDevice = "iPhone" | "default";

/**
 * Checks whether any of an array of substrings is present in the UserAgent string.
 * Case insensitive. Validated on iOS 14.2 - 15.0 for iPhone / iPad (when requesting mobile site)
 *
 * @param devices array of strings to test for userAgent presence
 * @returns true if found
 */
export function isDeviceNameInUA(devices: UserAgentDevice[]): boolean {
	return devices.some((dev) => navigator.userAgent.toLowerCase().includes(dev.toLowerCase()));
}

/**
 * Checks the current user agent string to determine if we are on a specific iOS version.
 * Validated for iOS 14.2 -> 15.0
 *
 * @param version the version to check. Should be formatted as X_x rather than X.x
 * @returns whether or not the current device is on the requested iOS version
 * */
export function isOnIOSVersion(version: string): boolean {
	if (!isIOS()) {
		return false;
	}

	//Try to clean up the version tag
	version = version.replace(".", "_");
	// if someone tried "14." or "14_" remove the trailing delimiter
	if (version.charAt(version.length - 1) === "_") {
		version = version.slice(0, -1);
	}

	// These cases cover iOS 14.2 and later versions being run with "Request Mobile Site"
	const iPad = new RegExp("iPad; CPU OS " + version, "i");
	const iPhone = new RegExp("CPU iPhone OS " + version, "i");
	const iPod = new RegExp("CPU iPhone " + version, "i");

	// Some non-Apple browsers (Firefox) will report a platform of iPad but a UA of iPhone.
	// navigator.platform is deprecated, so only check against the userAgent
	const ua = navigator.userAgent;
	return iPad.test(ua) || iPhone.test(ua) || iPod.test(ua);
}

export const iOSWebAudioWorkarounds = iOSDetectedAtVersion("14+") && !iOSDetectedAtVersion("15.4+");
export const iOSVerAudioGainWorkarounds = iOSDetectedAtVersion("15+") && !iOSDetectedAtVersion("15.4+");
export const iOSVerRenderingWorkarounds = iOSDetectedAtVersion("15+") && !iOSDetectedAtVersion("15.2+");
export const isWkWebView = isIOS() && !isSafari();
export const isMacSafari = isMac() && isSafari();

/**
 * Perform feature detection in the browser environment at various inflection points where the
 * version is expected to be the earliest implementation of some feature (and prior versions will
 * be undefined for such behavior)
 * @param version a SafariDetectableVersion that's been added to the type
 * @returns true if the device is IOS and is able to meet the feature detection; false otherwise
 */
export function iOSDetectedAtVersion(version: SafariDetectableVersions): boolean {
	// can't detect iOS at version if not iOS
	return isIOS() && safariDetectedAtVersion(version);
}

/**
 * Checks if a browser that supports client hints is on android 12
 *
 * @returns whether or not a client is on android 12
 * */
async function isAndroid12ClientHints(): Promise<boolean> {
	const { userAgentData } = navigator;
	// detect android 12 using user agent client hints
	if (userAgentData) {
		if (userAgentData.mobile && userAgentData.platform === "Android") {
			const { platformVersion } = await userAgentData.getHighEntropyValues(["platformVersion"]);
			return !!platformVersion?.startsWith("12");
		}
	}

	return false;
}

/**
 * Checks if a browser is on android 12, using client hints if available, otherwise falling back to user agent string regex
 *
 * @returns whether or not a client is on android 12
 * */
export async function isAndroid12(): Promise<boolean> {
	if (clientSupportsUAHints()) {
		return isAndroid12ClientHints();
	}

	const ua = navigator.userAgent;
	const android12Regexp = new RegExp("Android 12");
	return detectOS() === OS.android && android12Regexp.test(ua);
}
