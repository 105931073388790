/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Chat button for the header
 * @author Noah Allen
 * @module Epic.VideoApp.Components.Header.Buttons.ChatButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useStrings } from "~/hooks";
import { useGetFormattedHotkeyString } from "~/hooks/useGetFormattedHotkeyString";
import { combinedActions, uiActions, useMessageState, useUIState, useUserState } from "~/state";
import { resolveClassName } from "~/utils/className";
import Chat from "../../../icons/chat";
import styles from "../ControlsHeader.module.scss";
import ControlButton from "./ControlButton";

interface IProps {
	noLabel?: boolean;
	noTooltip?: boolean;
}

export enum ChatButtonTestIds {
	self = "ChatButton",
	ariaAlert = "ChatButtonAriaAlert",
}

enum TokenNames {
	newMessageAriaAlert = "NewMessageAriaAlertText",
	chatEnabledTooltip = "ChatEnabledTooltip",
	chatNotEnabledHover = "ChatNotEnabledHoverText",
	chatEnabledHoverNoHotkeyText = "ChatEnabledHoverNoHotkeyText",
}

const ChatButton: FC<IProps> = (props: IProps) => {
	const { noTooltip } = props;
	const isChatOpen = useUIState((selectors) => selectors.getSidebarStatus(), []) === "open";
	const dispatch = useDispatch();
	const inWaitingRoom = useUserState((selectors) => selectors.getIsUserInWaitingRoom(), []);
	const hasNotification = useMessageState((selectors) => selectors.getNumUnreadMessages() > 0, []);
	const [shouldShowNotification, setShouldShowNotification] = useState(false);
	const strings = useStrings("ChatButton", Object.values(TokenNames));

	const handleChatToggle = useCallback(() => {
		if (inWaitingRoom) {
			return;
		}
		dispatch(uiActions.setSidebarStatus(isChatOpen ? "closed" : "open"));
		dispatch(combinedActions.clearToast());
	}, [dispatch, inWaitingRoom, isChatOpen]);

	const chatKeyboardShortcut = ["alt", "c"];
	const chatEnabledTooltipFormatted = useGetFormattedHotkeyString(
		strings[TokenNames.chatEnabledTooltip],
		chatKeyboardShortcut,
	);

	const label = inWaitingRoom ? strings[TokenNames.chatNotEnabledHover] : chatEnabledTooltipFormatted;

	useEffect(() => {
		if (hasNotification && !inWaitingRoom && !isChatOpen) {
			setShouldShowNotification(true);
		} else {
			setShouldShowNotification(false);
		}
	}, [hasNotification, inWaitingRoom, isChatOpen]);

	const buttonAriaLabel =
		label + (shouldShowNotification ? " " + strings[TokenNames.newMessageAriaAlert] : "");

	const className = resolveClassName(styles, {
		buttonActive: isChatOpen,
		notification: shouldShowNotification,
		lockedAndDisabled: inWaitingRoom,
	});

	return (
		<ControlButton
			icon={Chat}
			ariaLabel={buttonAriaLabel}
			ariaExpanded={isChatOpen}
			onClick={handleChatToggle}
			buttonClassName={className}
			keyboardShortcut={chatKeyboardShortcut}
			tooltipText={!noTooltip ? label : undefined}
			data-testid={ChatButtonTestIds.self}
		/>
	);
};

export default ChatButton;
