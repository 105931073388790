/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Alert that confirms whether the moderator will remove a user from the visit or not
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Alerts.RemoveParticipantChoiceAlert
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import { useStrings } from "~/hooks";
import { alertActions, useAuthState } from "~/state";
import { IRemoveParticipantAlert } from "~/types";
import { makeRequest } from "~/utils/request";
import ChoiceAlertBase from "./ChoiceAlertBase";

interface IProps {
	alert: IRemoveParticipantAlert;
}

enum TokenNames {
	errorMessage = "ErrorMessage",
}

export enum RemoveParticipantChoiceAlertTestIds {
	self = "RemoveParticipantChoiceAlert",
}

/**
 * RemoveParticipant Choice alert component
 *
 * Renders a confirmation message that the user intends to remove a remote participant.
 */
const RemoveParticipantChoiceAlert: FC<IProps> = (props) => {
	const { alert } = props;

	const dispatch = useDispatch();
	const identity = alert.alertData.identity;
	const JWT = useAuthState((selectors) => selectors.getJWT(), []);
	const strings = useStrings("RemoveParticipantChoiceAlert", Object.values(TokenNames));

	const confirm = useCallback(() => {
		async function removeFromCall(): Promise<void> {
			try {
				await removeParticipant(JWT || "", identity);
			} catch {
				dispatch(alertActions.postGenericAlert(strings[TokenNames.errorMessage]));
			}
		}

		if (identity) {
			void removeFromCall();
			dispatch(alertActions.clearAlert());
		} else {
			dispatch(alertActions.clearAlert());
		}
	}, [identity, JWT, dispatch, strings]);

	const cancel = useCallback(() => {
		dispatch(alertActions.clearAlert());
	}, [dispatch]);

	return (
		<ChoiceAlertBase
			data-testid={RemoveParticipantChoiceAlertTestIds.self}
			alert={alert}
			confirm={confirm}
			cancel={cancel}
		/>
	);
};

function removeParticipant(jwt: string, id: string): Promise<void> {
	return makeRequest("/api/VideoCall/Remove", "POST", jwt, { identity: id });
}

RemoveParticipantChoiceAlert.displayName = "RemoveParticipantChoiceAlert";

export default RemoveParticipantChoiceAlert;
