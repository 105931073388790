/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Definitions for user events
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Events.UserEvents
 */

import { IRemoteUser, IUser } from "../interfaces";
import { VideoType } from "../types";
import { IEVCEvent } from "./interfaces/evcEmitter";

export interface IEVCParticipantNetworkQualityChangedEvent extends IEVCEvent<"networkQualityLevelChanged"> {
	newValue: number;
}

export interface IEVCParticipantUpdatedEvent extends IEVCEvent<"participantUpdated"> {
	participant: IUser;
	videoType: VideoType;
}

export interface IEVCScreenShareEvent<T extends "screenShareStarted" | "screenShareStopped">
	extends IEVCEvent<T> {
	participant: IRemoteUser;
}

export type BackgroundProcessorStatus = "finished" | "failed";

export interface IEVCBackgroundProcessorsDoneEvent extends IEVCEvent<"backgroundProcessorsDone"> {
	status: BackgroundProcessorStatus;
	successfulProcessors: string[];
}

export interface IEVCUserEventMap {
	networkQualityLevelChanged: IEVCParticipantNetworkQualityChangedEvent;
	participantUpdated: IEVCParticipantUpdatedEvent;
	screenShareStarted: IEVCScreenShareEvent<"screenShareStarted">;
	screenShareStopped: IEVCScreenShareEvent<"screenShareStopped">;
	backgroundProcessorsDone: IEVCBackgroundProcessorsDoneEvent;
}
