/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file hook to toggle whether or not the user is sharing their screen
 * @author Colin Walters
 * @module Epic.VideoApp.Hooks.UseScreenShareToggle
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useContext, useRef } from "react";
import { ShareContext } from "~/components/ScreenShareContext";
import { alertActions, useUserState } from "~/state";
import { EpicUserType, ToggleState } from "~/types";
import { boolMatchesToggleState } from "~/utils/general";
import { VideoCallContext, VideoContext } from "~/web-core/components";
import { useStream } from "~/web-core/hooks/useStream";

/**
 * Hook to get the status of whether or not the user's screen is being shared and toggle screen sharing
 * @returns tuple with the status of whether or not screen sharing is active and function to toggle that status
 */
export function useScreenShareToggle(): [boolean, (forceState?: ToggleState) => void] {
	const { session } = useContext(VideoContext);
	const localScreenShareStream = useStream(session?.localUser, "screen");
	const localShareExists = !!localScreenShareStream;

	const guardOptions = useUserState((selectors) => selectors.getGuardOptions(), []);
	const userType = useUserState((selectors) => selectors.getUserType(), []);
	const { requestScreenShareTrack, removeScreenShareTrack } = useContext(ShareContext);
	const { screenShareParticipant } = useContext(VideoCallContext);
	const isToggling = useRef(false);
	const dispatch = useDispatch();

	const toggleScreenSharing = useCallback(
		(forceState?: ToggleState) => {
			if (isToggling.current || boolMatchesToggleState(localShareExists, forceState)) {
				return;
			}
			if (localShareExists) {
				removeScreenShareTrack?.();
				isToggling.current = false;
			} else {
				// Within the alert we also check userType and only show the dismissal option to EMP users
				// We still check here because patients should still see the warning if an org enabled HideGuardrails
				if (userType === EpicUserType.emp && guardOptions.dismissedScreenShareWarning) {
					isToggling.current = true;
					requestScreenShareTrack?.(false).finally(() => {
						isToggling.current = false;
					});
				} else {
					dispatch(alertActions.postScreenShareWarningAlert(screenShareParticipant ?? null));
				}
			}
		},
		[
			dispatch,
			guardOptions.dismissedScreenShareWarning,
			localShareExists,
			removeScreenShareTrack,
			requestScreenShareTrack,
			screenShareParticipant,
			userType,
		],
	);

	return [localShareExists, toggleScreenSharing];
}
