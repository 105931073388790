/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Context for in-call context
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Components.VideoCallProvider
 */

import React, { FC, useMemo } from "react";
import { useDominantSpeaker } from "~/components/VideoCall/hooks/useDominantSpeaker";
import {
	INamelessParticipantData,
	useNamelessParticipantData,
} from "~/components/VideoCall/hooks/useNamelessParticipantData";
import { usePinnedParticipant } from "~/components/VideoCall/hooks/usePinnedParticipant";
import { useScreenShareParticipant } from "~/components/VideoCall/hooks/useScreenShareParticipant";
import { useModerationState, usePictureInPictureState, useRoomState } from "~/state";
import { CallLayout } from "~/types";
import { useHiddenUsers } from "../../hooks/useHiddenUsers";
import { determineCallLayout } from "../functions/determineCallLayout";
import { bucketWaitingParticipants } from "../helpers/bucketWaitingParticipants";
import { useMainParticipant } from "../hooks/useMainParticipant";
import { useRemoteUsers } from "../hooks/useRemoteUsers";
import { useStream } from "../hooks/useStream";
import { IRemoteUser } from "../interfaces/remoteUser";
import { ISession } from "../interfaces/session";
import { IUser } from "../interfaces/user";
import { usePeerConnectionStats } from "../vendor/twilio/hooks/usePeerConnectionStats";
import PeerSignaler from "./PeerSignaler";

export interface IVideoCallContext {
	call: ISession | null;
	callLayout: CallLayout;
	participants: IRemoteUser[];
	hiddenParticipants: string[];
	mainParticipant: IUser | null;
	dominantSpeaker: IRemoteUser | null;
	screenShareParticipant: IUser | null;
	namelessParticipantData: INamelessParticipantData;
	waitingParticipants: IRemoteUser[];
}

export const VideoCallContext = React.createContext<IVideoCallContext>({
	call: null,
	callLayout: "two-feeds",
	participants: [],
	hiddenParticipants: [],
	mainParticipant: null,
	dominantSpeaker: null,
	screenShareParticipant: null,
	namelessParticipantData: {
		timestamp: 0,
		dataIsShared: false,
		idList: [],
	},
	waitingParticipants: [],
});

interface IProps {
	session: ISession;
}

/**
 * Context provider to surface information about the video call to app components
 * @param session the session
 */
export const VideoCallProvider: FC<IProps> = (props) => {
	const { children, session } = props;

	const allParticipantInfo = useRoomState((selectors) => selectors.getAllParticipantInfo(), []);

	// State to clean-up on disconnection
	const requiresAccess = useModerationState((selectors) => selectors.getDoesVisitRequireAccess(), []);

	const allParticipants = useRemoteUsers(session);
	const [participants, waitingParticipants] = useMemo(() => {
		return bucketWaitingParticipants(allParticipants, allParticipantInfo, requiresAccess);
	}, [allParticipantInfo, allParticipants, requiresAccess]);

	const dominantSpeaker = useDominantSpeaker(session) ?? null;
	const localUser = session.getLocalParticipant();
	const pinnedParticipant = usePinnedParticipant(
		session.localUser ? [session.localUser, ...allParticipants] : allParticipants,
	);

	const screenShareParticipant = useScreenShareParticipant(session);
	const inPiPMode = usePictureInPictureState((selectors) => selectors.getPipStatus(), []);
	const pipParticipant = usePictureInPictureState((selectors) => selectors.getPipVideoID(), []);
	const localScreenShareStream = useStream(session.localUser, "screen");
	const localShareExists = !!localScreenShareStream;

	const hiddenParticipants = useHiddenUsers(
		participants,
		pinnedParticipant,
		inPiPMode ? pipParticipant : null,
		screenShareParticipant,
		dominantSpeaker,
		localUser,
	);

	const localIdentity = localUser?.getUserIdentity() ?? "";

	// Only use this hook once to ensure one copy of the nameless participant data exists
	// Explicitly return message handlers to attach with to the data track listener
	const [namelessParticipantData, handleNamelessParticipantRequest, handleNamelessParticipantResponse] =
		useNamelessParticipantData(localIdentity);

	usePeerConnectionStats(session);

	const mainParticipant = useMainParticipant(participants, pinnedParticipant, dominantSpeaker);

	const callLayout = determineCallLayout(participants, screenShareParticipant, localShareExists);

	return (
		<VideoCallContext.Provider
			value={{
				call: session,
				callLayout,
				participants,
				hiddenParticipants,
				mainParticipant,
				dominantSpeaker,
				screenShareParticipant,
				namelessParticipantData,
				waitingParticipants,
			}}
		>
			{children}
			<PeerSignaler
				handleNamelessRequest={handleNamelessParticipantRequest}
				handleNamelessResponse={handleNamelessParticipantResponse}
				localUserIdentity={localIdentity}
				participants={allParticipants}
				session={session}
			/>
		</VideoCallContext.Provider>
	);
};

VideoCallProvider.displayName = "VideoCallProvider";
