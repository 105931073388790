/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Interface for Twilio based remote media
 * @author Will Cooper
 * @module Epic.VideoApp.WebCore.Vendor.Twilio.Implementations.TwilioRemoteStream
 */

import { RemoteAudioTrack, RemoteTrackPublication, RemoteVideoTrack, Track } from "twilio-video";
import { IDimensions } from "~/types";
import { captureImage } from "~/utils/imageCapture";
import { EVCEmitter, IEVCStreamEventMap } from "~/web-core/events";
import { DeviceKind } from "~/web-core/types";
import { IRemoteStream } from "../../../interfaces/remoteStream";
export class TwilioRemoteStream extends EVCEmitter<IEVCStreamEventMap> implements IRemoteStream {
	remoteAudioTrack?: RemoteAudioTrack;
	remoteVideoTrack?: RemoteVideoTrack;
	cleanupFunction?: () => void;

	constructor(publications: RemoteTrackPublication[]) {
		super();
		this.remoteAudioTrack = publications.find((current) => current.kind === "audio")
			?.track as RemoteAudioTrack;
		this.remoteVideoTrack = publications.find((current) => current.kind === "video")
			?.track as RemoteVideoTrack;
	}

	isEnabled(kind: DeviceKind): boolean {
		if (kind === "audio") {
			return this.remoteAudioTrack?.isEnabled ?? false;
		}

		if (kind === "video") {
			return this.remoteVideoTrack?.isEnabled ?? false;
		}

		return false;
	}

	hasAudio(): boolean {
		return this.remoteAudioTrack !== undefined;
	}

	getVideoDimensions(): IDimensions | undefined {
		return this.remoteVideoTrack?.dimensions as IDimensions;
	}

	async captureImage(): Promise<string | null> {
		if (this.remoteVideoTrack) {
			return captureImage(this.remoteVideoTrack);
		}

		return null;
	}

	renderVideo(element: HTMLVideoElement): HTMLVideoElement | null {
		const output = this.remoteVideoTrack?.attach(element) as HTMLVideoElement;
		return output ?? null;
	}

	cleanupVideo(element: HTMLVideoElement): HTMLVideoElement | null {
		const output = this.remoteVideoTrack?.detach(element) as HTMLVideoElement;
		return output ?? null;
	}

	isBandwidthLimited(): boolean {
		return this.remoteVideoTrack?.isSwitchedOff ?? false;
	}

	getMediaStreamTrack(kind: DeviceKind): MediaStreamTrack | undefined {
		if (kind === "audio") {
			return this.remoteAudioTrack?.mediaStreamTrack ?? undefined;
		}

		if (kind === "video") {
			return this.remoteVideoTrack?.mediaStreamTrack ?? undefined;
		}

		return undefined;
	}

	/**
	 * Semi-private method not exposed on the remoteStream interface.
	 * Used to update a remote stream when handling certain track events.
	 * Should only be called by the TwilioRemoteUser class.
	 * @param kind Specifies whether we need to update the audio or video track
	 * @param track Either a new track to replace the existing one, or null to remove it
	 */
	updateTrack(kind: DeviceKind, track: Track | null): void {
		if (kind === "audio") {
			if (track === null) {
				if (this.remoteAudioTrack) {
					this.audioTrackCleanup(this.remoteAudioTrack);
					this.remoteAudioTrack = undefined;
				}
				return;
			}

			this.remoteAudioTrack = track as RemoteAudioTrack;
			this.audioTrackListener(this.remoteAudioTrack);
			const enabled = this.remoteAudioTrack.isEnabled;
			if (enabled) {
				this.emit("audioEnabled", { type: "audioEnabled" });
			} else {
				this.emit("audioDisabled", { type: "audioDisabled" });
			}
		}

		if (kind === "video") {
			if (track === null) {
				if (this.remoteVideoTrack) {
					this.videoTrackCleanup(this.remoteVideoTrack);
					this.remoteVideoTrack = undefined;
				}
				return;
			}

			this.remoteVideoTrack = track as RemoteVideoTrack;
			this.videoTrackListener(this.remoteVideoTrack);
			const enabled = this.remoteVideoTrack.isEnabled;
			if (enabled) {
				this.emit("videoEnabled", { type: "videoEnabled" });
			} else {
				this.emit("videoDisabled", { type: "videoDisabled" });
			}
		}
	}

	/**
	 * Given a video track, sets up handlers for each relevant Twilio event and emits
	 * the corresponding event from EVCEvents
	 * @param track The new remote track to set up
	 */
	private videoTrackListener(track: RemoteVideoTrack): void {
		track.on("enabled", (track) => {
			this.remoteVideoTrack = track;
			this.emit("videoEnabled", { type: "videoEnabled" });
		});

		track.on("disabled", (track) => {
			this.remoteVideoTrack = track;
			this.emit("videoDisabled", { type: "videoDisabled" });
		});

		track.on("dimensionsChanged", (track: RemoteVideoTrack) => {
			this.remoteVideoTrack = track;
			this.emit("videoDimensionsChanged", {
				type: "videoDimensionsChanged",
				newDim: { height: track.dimensions.height ?? 0, width: track.dimensions.width ?? 0 },
			});
		});

		track.on("switchedOff", (track: RemoteVideoTrack) => {
			this.remoteVideoTrack = track;
			this.emit("videoBandwidthLow", { type: "videoBandwidthLow" });
		});

		track.on("switchedOn", (track: RemoteVideoTrack) => {
			this.remoteVideoTrack = track;
			this.emit("videoBandwidthNormal", { type: "videoBandwidthNormal" });
		});
	}

	/**
	 * Emits a signal that a video track has been disabled and removes all event
	 * listeners
	 * @param track Track to clean up
	 */
	private videoTrackCleanup(track: RemoteVideoTrack): void {
		this.emit("videoDisabled", { type: "videoDisabled" });
		track.removeAllListeners();
	}

	/**
	 * Given an audio track, sets up handlers for each relevant Twilio event
	 * and emits the corresponding event from EVCEvents
	 * @param track The new remote track to set up
	 */
	private audioTrackListener(track: RemoteAudioTrack): void {
		track.on("enabled", (track) => {
			this.remoteAudioTrack = track;
			this.emit("audioEnabled", { type: "audioEnabled" });
		});

		track.on("disabled", (track) => {
			this.remoteAudioTrack = track;
			this.emit("audioDisabled", { type: "audioDisabled" });
		});

		track.on("started", (track) => {
			this.remoteAudioTrack = track;
			this.emit("audioReady", { type: "audioReady", track: track.mediaStreamTrack });
		});
	}

	/**
	 * Emits a signal that an audio track has been disabled and removes all event
	 * listeners
	 * @param track Track to clean up
	 */
	private audioTrackCleanup(track: RemoteAudioTrack): void {
		this.emit("audioDisabled", { type: "audioDisabled" });
		track.removeAllListeners();
	}
}
