/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Twilio Room Info Types
 * @author Will Cooper
 * @module Epic.VideoApp.Types.Room
 */

import { Log } from "twilio-video";
import { secondsToMs } from "~/utils/dateTime";
import { IUser } from "~/web-core/interfaces";
import { VideoType } from "~/web-core/types";
import { EpicUserType } from "./user";

export type CallLayout = "two-feeds" | "multi-feed";

// the only two that we currently use, other options are "au1", "br1", "ie1", "de1", "jp1", "sg1", "gll"
type TwilioRegion = "us1" | "us2";

// Number of milliseconds to wait before attempting to disconnect audio and video tracks for a second time after the call
export const disconnectTrackCallbackMs = secondsToMs(3);

// Common Twilio room error codes. See node_modules/twilio-video/lib/util/twilio-video-errors.js for codes
export enum RoomErrorCodes {
	participantDuplicateIdentityError = 53205,
	roomCompletedError = 53118,
	roomMaxParticipantsExceededError = 53105,
}

// the regionID typing is done to match some twilio connect option typing
export interface ICallRoomInfo {
	token: string | null;
	roomID: string | null;
	userID: string | null;
	patientName: string | null;
	conferenceDateIso: string | null;
	regionID?: TwilioRegion;
}

export interface IParticipantVideo {
	/** SID of the participant that owns the video feed */
	readonly identity: string;

	/** the type of the video feed */
	readonly videoType: VideoType;
}

export interface IParticipantWithName {
	/** Room participant */
	participant: IUser;

	/** Display name of the participant */
	displayName: string;
}

export interface IPinningState {
	/** last time that pinning was updated (from Date.now()) */
	readonly lastUpdate: number;

	/** the original pin source for the current participant */
	readonly updateSource: IPinSource;

	/** video that is currently pinned, null for no video pinned */
	readonly pinnedVideo: IParticipantVideo | null;
}

export type IPinSource = "Default" | "ImageCapture";

export interface IParticipantInfo {
	userType: EpicUserType;
	displayName: string;
	identity: string;
	micLocked: boolean;
	cameraLocked: boolean;
	screenShareLocked: boolean;
	screenShareAllowed: boolean;
	deviceSupportsScreenShare: boolean;
	inWaitingRoom: boolean;
}

export interface IStreamStatus {
	enabled: boolean;
	locked: boolean;
}

export interface ISupportedLocales {
	locales: Record<string, string>;
}

export interface IClientLoggingConfig {
	logLevel: Log.LogLevelDesc | null;
	interval: number; //in seconds
}
