/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file image Capture pane retake discard button group
 * @author Spencer Eanes
 * @module Epic.VideoApp.Components.ImageCapture.Controls.RetakeButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback, useContext } from "react";
import ActionButton from "~/components/Utilities/ActionButton";
import { useStrings } from "~/hooks";
import { combinedActions, imageActions } from "~/state";
import { VideoCallContext } from "~/web-core/components";

enum TokenNames {
	retakeButton = "RetakeButton",
	retakeButtonAria = "RetakeButtonAria",
}

export enum RetakeButtonTestIds {
	self = "RetakeButton",
}

/**
 * The ExitButton component
 * @param props - the props
 */
const RetakeButton: FC = () => {
	const dispatch = useDispatch();
	const { participants } = useContext(VideoCallContext);
	const canCapture = participants.length > 0;

	const strings = useStrings("RetakeButton", Object.values(TokenNames));

	const retake = useCallback(() => {
		dispatch(imageActions.setImageData(null));
		// Resume image capture mode if possible, do not update pinning statue
		if (canCapture) {
			dispatch(
				combinedActions.setImageCaptureActive({
					newPin: null,
				}),
			);
		}
	}, [canCapture, dispatch]);

	return (
		<ActionButton
			aria-label={strings[TokenNames.retakeButtonAria]}
			tone="neutral"
			priority="secondary"
			onClick={retake}
			keyboardShortcut={["alt", "r"]}
			text={strings[TokenNames.retakeButton]}
			forImagePreviewPane
			data-testid={RetakeButtonTestIds.self}
		/>
	);
};

RetakeButton.displayName = "RetakeButton";

export default RetakeButton;
