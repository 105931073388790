/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Types related to device status and overall hardware test status
 * @author Will Cooper
 * @module Epic.VideoApp.Types.Hardware
 */

import { BannerType } from "~/components/Utilities/BubbleBanner";

export interface IDeviceState {
	readonly status: DeviceStatus;
	readonly errorType: DeviceStatusSubtype;
}

export interface IHardwareTestResult {
	success: boolean;
	camera: IDeviceStatus;
	microphone: IDeviceStatus;
	speaker: IDeviceStatus;
	errorCode: string;
	errorMessage: string;
	userLanguage: string;
	shouldLogToEpic: boolean;
}

export interface IDeviceStatus {
	failure: boolean;
	label: string;
}

// Status for a specific device
export enum DeviceStatus {
	success,
	warning,
	error,
	testing,
	unknown,
}

export enum DeviceStatusSubtype {
	none,
	general = "Generic error", // Browser reported a generic error that may or may not be for this device
	hardwareError = "Unable to acquire device", // Browser was unable to acquire the device
	permissionsError = "Browser permissions denied", // User denied permissions in the browser
	unknown = "Unspecified error", // Our parsing didn't recognize the error
	noDevice = "Unable to detect device", // No device selected error, selectors should only allow this when no devices

	neverStarted = "Microphone failed to produce audio", // Mic warning - never produced audio
	stoppedWorking = "Microphone stopped producing audio", // Mic warning - stopped producing audio
	testInterrupted = "Microphone test interrupted", // Mic warning - mic got disabled before we could finish checking for audio
}

// Overall hardware test status
export enum HardwareTestStatus {
	testing,
	passed,
	failed,
}

export enum HardwareTestTab {
	devices,
	backgrounds,
	closed,
}

export interface IStatusBannerInfo {
	message: string;
	type: BannerType;
	additionalDetails?: string;
}

export interface IHardwareTestCurrentTab {
	tab: HardwareTestTab;
	isAutoSelected: boolean;
}

// Error type with its own message on the main card
export enum HardwareTestError {
	none,
	generalError,
	microphoneError,
	cameraError,
	permissionsError,
	unknown,
}

export interface IStreamDevices {
	isAudioEnabled: boolean;
	isVideoEnabled: boolean;
}

export type TrackAcquisitionStatus = "notStarted" | "finished" | "showingWarning";

export const volumeDetectionThreshold = 6;

export const DisplayNameInputMaxLength = 32;
