/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Focus trap for modals
 * @author Tara Feldstein
 * @module Epic.VideoApp.Components.Alerts.FocusTrap
 */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { uiActions, useUIState } from "~/state";
import { getPseudoRandomId } from "~/utils/general";
import styles from "./FocusTrap.module.scss";

interface IProps {
	firstElement: RefObject<HTMLElement>;
	lastElement?: RefObject<HTMLElement>;
	defaultElement?: RefObject<HTMLElement>;
}

export enum FocusTrapTestIds {
	self = "FocusTrap",
}

/**
 * Focus trap component
 *
 * Wraps its children in a focus trap, to keep the user from tabbing out of the section
 */
const FocusTrap: FC<IProps> = (props) => {
	const { firstElement, children } = props;
	const previousFocus = useRef<HTMLElement>();

	const [id] = useState(getPseudoRandomId());
	const dispatch = useDispatch();

	// only generate an id and add it to the list once
	useEffect(() => {
		dispatch(uiActions.addFocusTrap(id));
		return () => {
			dispatch(uiActions.removeFocusTrap(id));
		};
	}, [dispatch, id]);

	const activeFocusTrapId = useUIState((selectors) => selectors.getActiveFocusTrap(), []);

	// These two are optional, and default to firstElement if unspecified
	const lastElement = props.lastElement ?? firstElement;
	const defaultElement = props.defaultElement ?? firstElement;

	// Re-focus the appropriate button when tabbing away
	const focusFirst = useCallback(() => {
		firstElement.current?.focus();
	}, [firstElement]);

	const focusLast = useCallback(() => {
		lastElement.current?.focus();
	}, [lastElement]);

	useEffect(() => {
		const onKeyDown = (event: KeyboardEvent): void => {
			if (event.key.toLowerCase() !== "tab") {
				return;
			}
			if (activeFocusTrapId !== id) {
				return;
			}
			// Check the children to see if any match the document's active element
			const childNodes = Array.from(childRef.current?.querySelectorAll("*") ?? []);
			const isFocusingChildren = childNodes.some((node) =>
				(node as Node)?.isEqualNode(document.activeElement),
			);

			// If the active element is not in the focus trap's children, focus the default button
			if (!isFocusingChildren) {
				event.preventDefault();
				defaultElement.current?.focus();
			}
		};

		window.addEventListener("keydown", onKeyDown, true);
		return () => window.removeEventListener("keydown", onKeyDown, true);
	}, [defaultElement, children, activeFocusTrapId, id]);

	useEffect(() => {
		// Focus the default button
		previousFocus.current = document.activeElement as HTMLElement;
		defaultElement.current?.focus();

		return () => {
			// Restore focus on unload
			previousFocus.current?.focus();
		};
	}, [defaultElement]);

	const childRef = useRef<HTMLDivElement>(null);

	return (
		<>
			<div tabIndex={0} onFocus={focusLast} className={styles["noFocusDiv"]} />
			<div ref={childRef} className={styles["childWrapper"]} data-testid={FocusTrapTestIds.self}>
				{children}
			</div>
			<div tabIndex={0} onFocus={focusFirst} className={styles["noFocusDiv"]} />
		</>
	);
};

FocusTrap.displayName = "FocusTrap";

export default FocusTrap;
