/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Component to facilitate peer-to-peer communication
 * @author Max Harkins
 * @module Epic.VideoApp.WebCore.Components.PeerSignaler
 */

import React, { FC } from "react";
import DataTracksListener from "~/components/VideoCall/DataTracks/DataTracksListener";
import { useReceivedDataTrackMessage } from "~/hooks/messaging";
import { INamelessParticipantSyncRequestPayload, INamelessParticipantSyncResponsePayload } from "~/types";
import { ISession, IUser } from "../interfaces";
import { TwilioRemoteUser, TwilioSession } from "../vendor/twilio/implementations";

interface IProps {
	handleNamelessRequest: (payload: INamelessParticipantSyncRequestPayload) => void;
	handleNamelessResponse: (payload: INamelessParticipantSyncResponsePayload) => void;
	localUserIdentity: string;
	participants: IUser[];
	session: ISession;
}

/**
 * The PeerSignaler component
 */
const PeerSignaler: FC<IProps> = (props: IProps) => {
	const { handleNamelessRequest, handleNamelessResponse, localUserIdentity, participants, session } = props;

	// Create one interface so we only ever process one "message received event"
	const messageHandler = useReceivedDataTrackMessage(
		localUserIdentity,
		handleNamelessRequest,
		handleNamelessResponse,
	);

	return session instanceof TwilioSession ? (
		<DataTracksListener users={participants as TwilioRemoteUser[]} handleMessage={messageHandler} />
	) : null;
};

PeerSignaler.displayName = "PeerSignaler";

export default PeerSignaler;
