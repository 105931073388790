/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file call duration timer component
 * @author Colin Walters
 * @module Epic.VideoApp.Components.CallDurationTimer.CallDurationTimer
 */

import React, { FC, useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { getFormattedTime } from "~/utils/dateTime";
import { VideoCallContext, VideoContext } from "~/web-core/components";
import { destinationElementId } from "./CallDurationTimerDestination";

/**
 * The CallDurationTimer component
 */
const CallDurationTimer: FC = () => {
	const { participants } = useContext(VideoCallContext);
	const { session } = useContext(VideoContext);
	const lastUpdateInstant = useRef(-1);
	const [connectedTime, setConnectedTime] = useState(0);

	// get the element that the timer should be dropped into
	const el = document.getElementById(destinationElementId);

	const hasTwoWayConnection = !!session?.localUser && participants && participants.length > 0;

	useEffect(() => {
		// reset the last update instant when there is not a two way connection
		if (!hasTwoWayConnection) {
			lastUpdateInstant.current = -1;
			return;
		}

		if (lastUpdateInstant.current < 0) {
			lastUpdateInstant.current = Date.now();

			const onTick = (): void => {
				const now = Date.now();
				const msDiff = now - lastUpdateInstant.current;
				lastUpdateInstant.current = now;

				setConnectedTime((prevVal) => prevVal + msDiff);
			};

			const interval = setInterval(onTick, 1000);
			return () => {
				clearInterval(interval);
			};
		}
	}, [hasTwoWayConnection]);

	if (!el || (!hasTwoWayConnection && connectedTime === 0)) {
		return null;
	}

	return ReactDOM.createPortal(<>{getFormattedTime(connectedTime)}</>, el);
};

CallDurationTimer.displayName = "CallDurationTimer";

export default CallDurationTimer;
