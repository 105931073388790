/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file A warning overlay before we request device permissions
 * @author Tara Feldstein
 * @module Epic.VideoApp.WebCore.Components.DevicePermissionWarningOverlay
 */
import React, { FC, useCallback, useState } from "react";
import { useStrings } from "~/hooks";
import { isFirefox, isSafari, isSamsungInternet } from "~/utils/browser";
import { resolveClassName } from "~/utils/className";
import { isIOS, isMobile } from "~/utils/os";
import styles from "./DevicePermissionWarningOverlay.module.scss";

interface IProps {
	onContinue: () => Promise<void>;
}

/**
 * The fake-modal warning overlay component
 * Mimics modal alert visuals, but doesn't cover title bar or use a focus-trap
 * @param _props The props ;)
 */
const DevicePermissionWarningOverlay: FC<IProps> = (props: IProps) => {
	const { onContinue } = props;

	const [showButton, setShowButton] = useState<boolean>(true);
	const onClick = useCallback(async () => {
		// Swap off the button
		setShowButton(false);
		// Perform callback
		await onContinue();
	}, [onContinue]);

	// Auto-focus the button
	const focusButton = useCallback((button: HTMLButtonElement) => {
		if (button) {
			button.focus();
			// Focus the button again after 50ms to work around a Jaws issue where the button sometimes doesn't receive focus
			// 50ms is too fast for the user to navigate away before this triggers
			setTimeout(() => {
				button.focus();
			}, 50);
		}
	}, []);

	const strings = useStrings("DevicePermissionWarning", [
		"AllowDevices",
		"Connecting",
		"WeNeedDevices",
		"Continue",
		"AccessibleInstruction",
	]);
	// Main status text, either explains that we need devices, or says that we're connecting
	const statusText = showButton
		? `${strings["WeNeedDevices"]} ${strings["AllowDevices"]}`
		: strings["Connecting"];
	// Screen readers may not make it obvious the popup comes from the browser, so they get extra details
	const accessibleLabel = `${strings["WeNeedDevices"]} ${strings["AccessibleInstruction"]}`;

	// Repeat checks
	const mobile = isMobile();
	const iOS = isIOS();

	// Device+Browser pairs where we need to handle differently
	const isAndroidFirefox = !iOS && isFirefox(); // Permissions popup is on the bottom of the screen on Android Firefox
	const isAndroidSamsung = !iOS && isSamsungInternet(); // Permissions popup is low down but can overlap the center
	const isMacSafari = !iOS && isSafari(); // On desktop safari, popup is centered and very large

	// Decide on what shifts to do, if any
	const shiftDown = !showButton && mobile && !isAndroidFirefox && !isAndroidSamsung; // Shift the card down for the second screen on most mobile situations
	const shiftUp = !showButton && mobile && isAndroidSamsung; // Shift the card up slightly for Samsung Internet
	const bottomAlign = !showButton && !mobile && isMacSafari; // Align the card near the bottom for Mac Safari, which gets a very large popup
	const cardClass = resolveClassName(styles, { modalCard: true, shiftDown, shiftUp, bottomAlign });

	return (
		<div className={styles["modalContainer"]}>
			<div className={cardClass} role="dialog" aria-modal="true" aria-label={accessibleLabel}>
				<span>{statusText}</span>
				{showButton && (
					<button onClick={onClick} type="button" ref={focusButton}>
						{strings["Continue"]}
					</button>
				)}
			</div>
		</div>
	);
};

DevicePermissionWarningOverlay.displayName = "DevicePermissionWarningOverlay";

export default DevicePermissionWarningOverlay;
