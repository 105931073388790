/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file Hook to build a representation of a single hardware test state (pass or fail)
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.UseHardwareTestResult
 */
import { useContext, useEffect, useState } from "react";
import { useHardwareTestState, useLocalTrackState, useSpeakerState } from "~/state";
import { DeviceStatus, HardwareTestStatus, IHardwareTestResult } from "~/types";
import { constructErrorMessageForHWTest, shouldLogToEpic } from "~/utils/hardwareTest";
import { VideoContext } from "~/web-core/components/VideoSessionProvider";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { useMediaTrack } from "~/web-core/hooks/useMediaTrack";

/**
 * Makes a server request with local track data from redux store
 * @param isVideoCall flag to indicate that we are getting the result for the video call
 */
export function useHardwareTestResult(isVideoCall: boolean): IHardwareTestResult | null {
	const [hardwareTest, setHardwareTest] = useState<IHardwareTestResult | null>(null);
	const { stream } = useContext(VideoContext);
	const isVideoEnabled = useIsStreamEnabled("video", stream);
	const videoMediaStreamTrack = useMediaTrack(stream, "video");
	const audioMediaStreamTrack = useMediaTrack(stream, "audio");
	const hasAutoSelected = useLocalTrackState((selectors) => selectors.getHasAutoSelected(), []);
	const selectedSpeakerLabel = useSpeakerState((selectors) => selectors.getSelectedSpeakerLabel(), []);
	const status = useHardwareTestState(
		(selectors) => selectors.getTestStatus({ allowOneError: isVideoCall, isStandalone: !isVideoCall }),
		[isVideoCall],
	);
	const cameraFailure = [DeviceStatus.error, DeviceStatus.warning].includes(
		useHardwareTestState((selectors) => selectors.getCameraStatus(isVideoCall), [isVideoCall]),
	);
	const cameraError = useHardwareTestState((selectors) => selectors.getCameraError(), []);
	const micFailure = [DeviceStatus.error, DeviceStatus.warning].includes(
		useHardwareTestState((selectors) => selectors.getMicrophoneStatus(isVideoCall), [isVideoCall]),
	);
	const micError = useHardwareTestState((selectors) => selectors.getMicrophoneError(), []);
	const speakerFailure =
		useHardwareTestState((selectors) => selectors.getSpeakerStatus(), []) === DeviceStatus.error;
	const speakerError = useHardwareTestState((selectors) => selectors.getSpeakerError(), []);
	const twilioError = useHardwareTestState((selectors) => selectors.getTwilioError(), []);

	// With the vendor abstraction layer refactor, the new useMediaTrack hook only updates the media stream track when a
	// device becomes ready (like switching to a new device). Because of that, only device updates that affect
	// the hardware test result are logged to the portal. We use the useMediaTrack hook to know when the device
	// has changed, and the useIsStreamEnabled hook to know when the device is enabled/disabled. Note: we do not clear the mic
	// from the portal when it is muted, which we do for when the camera is disabled.

	useEffect(() => {
		// Verify we are in a valid state when putting together a hardware test result
		if (status === HardwareTestStatus.testing || !hasAutoSelected) {
			return;
		}

		const cameraName = stream?.getDeviceName("video");
		const cameraLabel = isVideoEnabled && cameraName ? cameraName : "";
		const micLabel = stream?.getDeviceName("audio") ?? "";
		const speakerLabel = selectedSpeakerLabel ?? "";
		const isSuccess = status === HardwareTestStatus.passed;

		setHardwareTest((prevTest) => {
			const currentTest = {
				success: isSuccess,
				camera: {
					failure: cameraFailure,
					label: cameraLabel,
				},
				microphone: {
					failure: micFailure,
					label: micLabel,
				},
				speaker: {
					failure: speakerFailure,
					label: speakerLabel,
				},
				errorCode:
					twilioError?.code?.toString() ??
					(cameraFailure || micFailure || speakerFailure ? "11" : ""),
				errorMessage:
					twilioError?.message ??
					constructErrorMessageForHWTest(cameraError, micError, speakerError),
				userLanguage: navigator.language,
				// this will be overridden, by our calculation in the next step, we don't have to worry about it
				shouldLogToEpic: !prevTest?.success || !isSuccess,
			};

			const shouldLog = shouldLogToEpic(prevTest, currentTest);

			return {
				...currentTest,
				shouldLogToEpic: shouldLog,
			};
		});
	}, [
		status,
		hasAutoSelected,
		cameraFailure,
		cameraError,
		micFailure,
		micError,
		selectedSpeakerLabel,
		speakerFailure,
		speakerError,
		twilioError,
		stream,
		isVideoEnabled,
		videoMediaStreamTrack,
		audioMediaStreamTrack,
	]);

	return hardwareTest;
}
