/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Definitions for session events
 * @author Trevor Roussel
 * @module Epic.VideoApp.WebCore.Events.SessionEvents
 */

import { IRemoteUser, ISession } from "../interfaces";
import { VendorError } from "../interfaces/vendorError";
import { IEVCEvent } from "./interfaces/evcEmitter";
import { IEVCScreenShareEvent } from "./userEvents";

export interface IEVCSessionConnectionEvent extends IEVCEvent<"sessionConnected"> {
	session: ISession;
}

export interface IEVCRemoteParticipantUpdatedEvent extends IEVCEvent<"remoteParticipantUpdated"> {
	participant: IRemoteUser;
}

export interface IEVCParticipantConnectionEvent<T extends "participantConnected" | "participantDisconnected">
	extends IEVCEvent<T> {
	participant: IRemoteUser;
}

export interface IEVCDominantSpeakerEvent extends IEVCEvent<"dominantSpeakerChanged"> {
	participant: IRemoteUser | null;
}

export interface IEVCSessionDisconnectionEvent extends IEVCEvent<"disconnected"> {
	error?: VendorError;
}

export interface IEVCSessionReconnectingEvent extends IEVCEvent<"reconnecting"> {
	error?: VendorError;
	wasDisconnected?: boolean;
}

export interface IEVCSessionEventMap {
	sessionConnected: IEVCSessionConnectionEvent;
	participantConnected: IEVCParticipantConnectionEvent<"participantConnected">;
	participantDisconnected: IEVCParticipantConnectionEvent<"participantDisconnected">;
	screenShareStarted: IEVCScreenShareEvent<"screenShareStarted">;
	screenShareStopped: IEVCScreenShareEvent<"screenShareStopped">;
	remoteParticipantUpdated: IEVCRemoteParticipantUpdatedEvent;
	dominantSpeakerChanged: IEVCDominantSpeakerEvent;
	disconnected: IEVCSessionDisconnectionEvent;
	reconnecting: IEVCSessionReconnectingEvent;
}
