/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file Data Track hook to send broadcast messages to all moderators when a patient wishes to "raise their hand" and request media access
 * @author Will Cooper
 * @module Epic.VideoApp.Hooks.Messaging.Moderation.UseBroadcastMediaAccessRequest
 */

import { useCallback, useContext } from "react";
import { IBasicAuditEvent, useAuditFeatureUse } from "~/hooks";
import { EventType, IMediaAccessRequestMessage, MessageActionType, MessageType } from "~/types";
import { VideoContext } from "~/web-core/components";
import { sendMessage } from "../../../utils/sendMessage";

interface IRequestOptions {
	audio?: boolean;
	video?: boolean;
	screenShare?: boolean;
}

export interface IBroadcastAccessRequest {
	(params: IRequestOptions): void;
}

export function useBroadcastMediaAccessRequest(): IBroadcastAccessRequest {
	const { session } = useContext(VideoContext);
	const auditFeatureUse = useAuditFeatureUse();

	const broadcastRequest = useCallback(
		(params: IRequestOptions) => {
			const events: IBasicAuditEvent[] = [];

			const requestAudio = params.audio;
			const requestVideo = params.video;
			const requestScreenShare = params.screenShare;

			if (requestVideo) {
				events.push({
					feature: EventType.participantVideoRequest,
				});
			}
			if (requestAudio) {
				events.push({
					feature: EventType.participantAudioRequest,
				});
			}
			if (requestScreenShare) {
				events.push({
					feature: EventType.participantScreenShareRequest,
				});
			}

			if (events.length > 0) {
				void auditFeatureUse(events);
			}
			const message: IMediaAccessRequestMessage = {
				action: MessageActionType.broadcast,
				payload: {
					audioRequest: requestAudio,
					videoRequest: requestVideo,
					screenShareRequest: requestScreenShare,
				},
				type: MessageType.accessRequest,
				needsACK: false,
			};

			sendMessage(session, message);
		},
		[auditFeatureUse, session],
	);

	return broadcastRequest;
}
