/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Invisible component responsible for managing the in-call audio of each remote participant
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.Audio.RemoteParticipantAudio
 */

import React, { FC, ReactElement, useContext } from "react";
import { RemoteAudioTrack, RemoteParticipant, RemoteTrackPublication } from "twilio-video";
import { DeviceContext } from "~/components/VideoCall/DeviceContext";
import { useIOSMutedWorkaround } from "~/hooks/localTracks";
import { useTrack } from "~/hooks/trackUtilities";
import { usePublications } from "~/hooks/usePublications";
import { iOSDetectedAtVersion, iOSWebAudioWorkarounds } from "~/utils/os";
import { VideoCallContext, VideoContext } from "~/web-core/components";
import { TwilioLocalStream, TwilioRemoteUser } from "~/web-core/vendor/twilio/implementations";
import AudioOutput from "./AudioOutput";
import WebAudioOutput from "./WebAudioOutput";
import useShouldMuteHTML from "./hooks/useShouldMuteHTML";

/**
 * The RemoteParticipantAudio component
 * Attaches each participant's audio into the DOM by stepping through the Twilio publication -> track architecture
 */

const RemoteParticipantAudio = (): ReactElement => {
	const { participants } = useContext(VideoCallContext);

	/**	on iOS15, provide the localAudioTrack to a hook that will listen for 'mute'/'unmute' events and restart
	 * 	set the track's 'enabled' property to the inverse of 'muted' and restart the track in response to the 'unmute'
	 *  to ensure proper audio functionality in response to external interruptions */
	const { stream } = useContext(VideoContext);
	const localAudioTrack = stream instanceof TwilioLocalStream ? stream.localAudioTrack : null;
	useIOSMutedWorkaround(iOSDetectedAtVersion("15+") ? localAudioTrack ?? null : null);

	/*
		Because there's potentially multiple participants, and potentially multiple tracks per participant,
		  and we need the usePublications and useTrack hooks (which can't go within a loop),
		  we'll use three levels of nested components to create all the instances of the necessary hooks

		In this first level, we grab the publications for each Participant
 	*/
	return (
		<div>
			{participants.map((participant) => {
				if (participant instanceof TwilioRemoteUser) {
					return (
						<AudioPublications
							key={participant.getUserGuid()}
							participant={participant.participant}
						/>
					);
				}
				// Only render remote audio for Twilio users for now
				return null;
			})}
		</div>
	);
};

RemoteParticipantAudio.displayName = "RemoteParticipantAudio";

export default RemoteParticipantAudio;

// Private/Internal use components

interface IPublicationProps {
	participant: RemoteParticipant;
}

/**
 * The Audio listener
 * Extracts audio track publications for a given participant, and sets up each track in the DOM.
 * In theory, one participant could have multiple audio tracks, although this shouldn't actually happen
 */
const AudioPublications: FC<IPublicationProps> = (props) => {
	const { participant } = props;
	const publications = usePublications(participant);
	const audioPublications = publications.filter(
		(publication) => publication.kind === "audio",
	) as RemoteTrackPublication[];

	return (
		<>
			{audioPublications.map((publication) => (
				<AudioTrack key={publication.trackSid} publication={publication} />
			))}
		</>
	);
};

AudioPublications.displayName = "AudioPublications";

interface ITrackProps {
	publication: RemoteTrackPublication;
}

/**
 * The AudioTrack
 * Extracts the actual track from a single publication and determines if its HTMLElement should be muted (for iOS workarounds)
 */
const AudioTrack: FC<ITrackProps> = (props) => {
	const { publication } = props;
	const track = useTrack(publication) as RemoteAudioTrack;
	const shouldMuteHTML = useShouldMuteHTML(track);
	return <AudioRouter track={track} shouldMuteHTML={shouldMuteHTML} />;
};
AudioTrack.displayName = "AudioTrack";

interface IRouterProps {
	track: RemoteAudioTrack;
	shouldMuteHTML: boolean;
}

/**
 * Component to apply logic to the audio output schema to assist with iOS workarounds
 * @param props.track the RemoteAudioTrack
 * @param props.shouldMuteHTML flag used to indicate HTML fallback for iOS workarounds
 * @returns null or AudioOutput (plus WebAudioOutput for iOS Workarounds)
 */
const AudioRouter: FC<IRouterProps> = (props) => {
	const { track, shouldMuteHTML } = props;
	const { overrideIOSRoute } = useContext(DeviceContext);

	if (!track) {
		return null;
	}

	// iOS 14.0 - 15.3 Workaround - overrideIOSRoute needs new <audio> element
	// (via default return) for graceful fallback
	if (iOSWebAudioWorkarounds && !overrideIOSRoute) {
		return (
			<>
				<WebAudioOutput track={track} enabled={shouldMuteHTML} />
				<AudioOutput track={track} muted={shouldMuteHTML} />
			</>
		);
	}

	return <AudioOutput track={track} muted={shouldMuteHTML} />;
};

AudioRouter.displayName = "AudioRouter";
