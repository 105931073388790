/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Mobile-specific handling of visibilitychange events (i.e. backgrounding & returning)
 * Must be a descendant of the DeviceContext Provider for consumed hooks to access that Context
 * @author Gavin Lefebvre
 * @module Epic.VideoApp.Components.VideoCall.MobileBackgroundingHandler
 */

import React, { FC, useCallback, useContext, useEffect, useRef } from "react";
import { useLocalAudioToggle, useLocalVideoToggle } from "~/hooks";
import useHandleAudioContext from "~/hooks/useHandleAudioContext";
import { useRoomState } from "~/state";
import { iOSVerAudioGainWorkarounds, isWkWebView } from "~/utils/os";
import { VideoContext } from "~/web-core/components";
import { TwilioLocalStream } from "~/web-core/vendor/twilio/implementations";

interface IProps {
	isVisible: boolean;
}

/** This component must be an ancestor of the DeviceContext Provider in order for its hooks to be able to derive needed
 * 	objects from useContext(DeviceContext) - in this case, 'useHandleAudioContext' needs to access the 'audioContext'
 * 	from that Context Provider.	 */
const MobileBackgroundingHandler: FC<IProps> = (props) => {
	const { isVisible } = props;

	const isDisconnecting = useRoomState((selectors) => selectors.getIsDisconnecting(), []);
	const [, toggleCameraEnabled] = useLocalVideoToggle();
	const [isMicEnabled, toggleMicEnabled] = useLocalAudioToggle();

	const { stream } = useContext(VideoContext);
	const localVideoTrack = (stream as TwilioLocalStream)?.localVideoTrack ?? null;
	const localAudioTrack = (stream as TwilioLocalStream)?.localAudioTrack ?? null;

	const enableCameraOnVisible = useRef(false);
	const enableMicOnVisible = useRef(false);
	const tryHandleAudioContext = useHandleAudioContext();

	/** Handle document becoming hidden */
	const handleHidden = useCallback((): void => {
		// mark whether mic & camera were enabled when backgrounded
		enableCameraOnVisible.current = stream?.isEnabled("video") ?? false;
		enableMicOnVisible.current = isMicEnabled;

		if (isWkWebView) {
			/** iOS Workaround: run directly rather than getting caught up in recovery logic in the toggle hooks
			 * as we need to keep the camera published for WkWebView audio recovery */
			stream?.toggleState("video", false);
			stream?.toggleState("audio", false);
			return;
		}
		void toggleCameraEnabled("off");
	}, [isMicEnabled, toggleCameraEnabled, stream]);

	/** Handle camera when document becomes visible */
	const handleCameraOnVisible = useCallback(async (): Promise<void> => {
		// avoid potential race condition w/ twilio functionality by restarting while video is disabled:
		// https://github.com/twilio/twilio-video.js/blob/d97a4d91696bb79edb34716892c79f2c38707888/lib/media/track/localvideotrack.js#L289
		if (localVideoTrack) {
			await localVideoTrack.restart();
		}
		// handle camera (if it had been enabled previously)
		if (enableCameraOnVisible.current) {
			enableCameraOnVisible.current = false;
			await toggleCameraEnabled("on");
		}
	}, [localVideoTrack, toggleCameraEnabled]);

	/** Handle microphone when document becomes visible */
	const handleMicOnVisible = useCallback(async (): Promise<void> => {
		// handle mic (only on iOS non-Safari browsers) restart it to clear any muted/hardware access issue state
		if (isWkWebView) {
			await localAudioTrack?.restart();
			// if mic wasn't enabled, defer reacquiring until the user manually re-enables
			if (enableMicOnVisible.current) {
				enableMicOnVisible.current = false;
				await toggleMicEnabled("on");
			}
		}
	}, [localAudioTrack, toggleMicEnabled]);

	/** Promise chain when document becomes visible */
	const nowVisible = useCallback((): void => {
		void handleCameraOnVisible()
			.then(() => handleMicOnVisible())
			.then(() => tryHandleAudioContext(iOSVerAudioGainWorkarounds));
	}, [handleCameraOnVisible, handleMicOnVisible, tryHandleAudioContext]);

	/** Final useRef */
	const nowVisibleRef = useRef(nowVisible);
	const nowHiddenRef = useRef(handleHidden);
	const isDisconnectingRef = useRef(isDisconnecting);

	/** Keep useRefs updated */
	useEffect(() => {
		isDisconnectingRef.current = isDisconnecting;
		nowHiddenRef.current = handleHidden;
		nowVisibleRef.current = nowVisible;
	}, [handleHidden, isDisconnecting, nowVisible]);

	/** VisibilityChange event handling logic */
	useEffect(() => {
		/** From Twilio: When an application that is running on a mobile browser is backgrounded,
		 * 	it will not have access to the video feed from the camera until it is foregrounded.
		 * 	So, we recommend that you stop and unpublish the camera's LocalVideoTrack,
		 * 	and publish a new LocalVideoTrack once your application is foregrounded.
		 */

		// handle hidden
		if (!isVisible) {
			nowHiddenRef.current();
		}
		// handle visible
		else if (!isDisconnectingRef.current) {
			window.setTimeout(() => {
				nowVisibleRef.current();
			}, 500);
		}
	}, [isVisible]);

	return null;
};

MobileBackgroundingHandler.displayName = "MobileBackgroundingHandler";

/**
 * @description This component must be an ancestor of the DeviceContext Provider in order for its hooks to be able to derive needed
 * 	objects from useContext(DeviceContext) - in this case, 'useHandleAudioContext' needs to access the 'audioContext'
 * 	from that Context Provider.	 */
export default React.memo(MobileBackgroundingHandler);
