/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Wrapper component to render either a disabled camera icon or an avatar
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.Video.VideoPlaceholder
 */

import React, { FC, useContext } from "react";
import { useUserState } from "~/state";
import { resolveClassName } from "~/utils/className";
import { VideoCallContext } from "~/web-core/components";
import DisabledCameraIndicator from "./DisabledCameraIndicator";
import styles from "./VideoPlaceholder.module.scss";

interface IPlaceholderProps {
	// Only videos rendered within the VideoGrid will pass this property
	// If we ever wish to change the placeholder behavior for all videos we can update this
	displayName?: string;

	// This value could be sent for any user's video
	locked?: boolean;
}

/**
 * The VideoPlaceholder component
 * This component will show a disabled camera icon outside of grid mode
 * or if there is no display name available for the current participant
 * @param props The props ;)
 */
const VideoPlaceholder: FC<IPlaceholderProps> = (props) => {
	const { displayName, locked } = props;
	const { callLayout } = useContext(VideoCallContext);
	const canCaptureImage = useUserState((selectors) => selectors.getUserPermission("canCaptureImage"), []);

	const divClassName = resolveClassName(styles, {
		videoPlaceholder: true,
		videoPlaceholderWithImageCapture: canCaptureImage && callLayout !== "two-feeds",
	});

	return (
		<div className={divClassName}>
			{displayName ? (
				<div className={styles["iconWrapper"]}>
					<span className={styles["placeholderText"]}>{displayName}</span>
				</div>
			) : (
				<DisabledCameraIndicator locked={locked} />
			)}
		</div>
	);
};

VideoPlaceholder.displayName = "VideoPlaceholder";

export default VideoPlaceholder;
