/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Hook to handle picture-in-picture events
 * @author Arun Vasireddy
 * @module Epic.VideoApp.Hooks.UsePictureInPicture
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useContext, useEffect, useReducer } from "react";
import { pipActions, usePictureInPictureState } from "~/state";
import { EventType } from "~/types";
import {
	enterPictureInPictureMode,
	isElementInPiP,
	isPiPWindowOpen,
	isPictureInPictureSupported,
} from "~/utils/pictureInPicture";
import { VideoCallContext } from "~/web-core/components";
import { VideoType } from "~/web-core/types";
import { useAuditFeatureUse } from "./auditing";

export const METADATA_LOADED_READY_STATE = 1;

export const METADATA_LOADED_PROPERTY_NAME = "loadedmetadata";

/**
 * Manage picture-in-picture actions
 *
 * @param videoRef Reference to the HTMLVideoElement element for a given participant's video feed
 * @param participantSID Participant SID - used as a unique identifier for the video feed
 */
export function usePictureInPicture(
	videoRef: React.RefObject<HTMLVideoElement> | null,
	userId?: string,
	videoType?: VideoType,
): void {
	const participantInPiP = usePictureInPictureState((selectors) => selectors.getPipVideoID(), []);
	const pipVideoType = usePictureInPictureState((selectors) => selectors.getPipVideoType(), []);
	const isPipTrack = userId === participantInPiP && videoType === pipVideoType;
	const isPiPModeEnabled = usePictureInPictureState((selectors) => selectors.getPipStatus(), []);
	const { mainParticipant } = useContext(VideoCallContext);
	const isMainParticipant = userId === mainParticipant?.getUserIdentity() && videoType === pipVideoType;
	const videoElement = videoRef?.current;

	// Used to force a re-render when the video element is not available by updating state
	const [, forceUpdate] = useReducer((x: number) => x + 1, 0);

	const dispatch = useDispatch();
	const auditFeatureUse = useAuditFeatureUse();

	const enterPIPMode = useCallback(() => {
		if (userId && videoType) {
			dispatch(pipActions.enterPiPMode({ newVideoID: userId, newVideoType: videoType }));
		}
	}, [dispatch, userId, videoType]);

	const leavePIPMode = useCallback(() => {
		if (userId) {
			dispatch(
				pipActions.leavePiPMode({
					videoID: userId,
					videoType: videoType,
					isMainParticipant: isMainParticipant,
				}),
			);
		}
	}, [dispatch, isMainParticipant, userId, videoType]);

	// Update shared state when user manually enters/exits PiP
	useEffect(() => {
		if (!videoElement || !isPictureInPictureSupported()) {
			return;
		}
		videoElement.addEventListener("enterpictureinpicture", enterPIPMode);
		videoElement.addEventListener("leavepictureinpicture", leavePIPMode);

		return () => {
			videoElement.removeEventListener("enterpictureinpicture", enterPIPMode);
			videoElement.removeEventListener("leavepictureinpicture", leavePIPMode);
		};
	}, [videoElement, enterPIPMode, leavePIPMode]);

	//Enter PiP and update store if we failed to enter PiP
	const enterPictureInPicture = useCallback(async () => {
		const isPiPSuccessful = await enterPictureInPictureMode(videoElement);
		if (!isPiPSuccessful) {
			leavePIPMode();
		}
	}, [leavePIPMode, videoElement]);

	// Handle entering PiP based on state changes
	const pictureInPictureHandler = useCallback(() => {
		if (!videoElement) {
			// In certain cases, the pipped element will freeze. By forcing a re-render when that happens,
			// the pip handler code will re-run with the updated video element.
			if (document.pictureInPictureElement && !document.pictureInPictureElement.isConnected) {
				forceUpdate();
			}
			return;
		}
		if (isPiPModeEnabled && !isElementInPiP(videoElement)) {
			if (!isPiPWindowOpen()) {
				void auditFeatureUse([{ feature: EventType.launchedPictureInPicture }]);
			}
			if (videoElement.readyState >= METADATA_LOADED_READY_STATE) {
				void enterPictureInPicture();
			} else {
				videoElement.addEventListener(
					METADATA_LOADED_PROPERTY_NAME,
					function enterPictureInPictureHandler(): void {
						void enterPictureInPicture();
						videoElement.removeEventListener(
							METADATA_LOADED_PROPERTY_NAME,
							enterPictureInPictureHandler,
						);
					},
				);
			}
		}
	}, [auditFeatureUse, enterPictureInPicture, isPiPModeEnabled, videoElement]);

	// Handle entering PiP for the current participant
	useEffect(() => {
		if (!isPipTrack) {
			return;
		}
		void pictureInPictureHandler();
	}, [isPipTrack, pictureInPictureHandler]);
}
