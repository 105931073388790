/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Interfaces & Helpers for RTCStats logging
 * @author Gavin Lefebvre
 * @module Epic.VideoApp.Types.WebRTCStats
 */

import Video from "twilio-video";

/** Interfaces */

export interface ISignalingRoom extends Video.Room {
	_signaling: {
		_peerConnectionManager: {
			_peerConnections: Map<string, RTCPeerConnection>;
		};
	};
}

export interface IStatsCollector extends StringKeyObject {
	activeIceCandidatePair: StandardizedIceStatsArray;
	localAudioTrackStats: Record<string, StandardizedTrackStatsArray>;
	remoteAudioTrackStats: Record<string, StandardizedTrackStatsArray>;
	localVideoTrackStats: Record<string, StandardizedTrackStatsArray>;
	remoteVideoTrackStats: Record<string, StandardizedTrackStatsArray>;
}

export interface IGetStatsDto {
	roomSid: string;
	participantIdentity: string;
	timestamp: number;
	data: IStatsCollector;
}

/**
 * Target log file that data should be logged to (keep in sync with LogTargetFile in DebugQueryStringModel.cs)
 */
export enum LogTargetFile {
	clientLogs = 0,
	webRTCLogs = 1,
}

/** Helper functions */

export function getEmptyIceCandidatePairArray(): StandardizedIceStatsArray {
	return {
		availableIncomingBitrate: [],
		availableOutgoingBitrate: [],
		bytesReceived: [],
		bytesSent: [],
		consentRequestsSent: [],
		currentRoundTripTime: [],
		lastPacketReceivedTimestamp: [],
		lastPacketSentTimestamp: [],
		localCandidate: [],
		nominated: [],
		priority: [],
		readable: [],
		remoteCandidate: [],
		requestsReceived: [],
		requestsSent: [],
		responsesReceived: [],
		responsesSent: [],
		retransmissionsReceived: [],
		retransmissionsSent: [],
		state: [],
		totalRoundTripTime: [],
		transportId: [],
		writable: [],
	};
}

export function getEmptyTrackArray(): StandardizedTrackStatsArray {
	return {
		trackId: [],
		ssrc: [],
		timestamp: [],
		codecName: [],
		roundTripTime: [],
		jitter: [],
		frameWidthInput: [],
		frameHeightInput: [],
		frameWidthSent: [],
		frameHeightSent: [],
		frameWidthReceived: [],
		frameHeightReceived: [],
		frameRateInput: [],
		frameRateSent: [],
		frameRateReceived: [],
		bytesReceived: [],
		bytesSent: [],
		packetsLost: [],
		packetsReceived: [],
		packetsSent: [],
		totalPacketSendDelay: [],
		totalEncodeTime: [],
		framesEncoded: [],
		estimatedPlayoutTimestamp: [],
		totalDecodeTime: [],
		framesDecoded: [],
		jitterBufferDelay: [],
		jitterBufferEmittedCount: [],
		audioInputLevel: [],
		audioOutputLevel: [],
	};
}

export function getEmptyStats(): IStatsCollector {
	return {
		activeIceCandidatePair: getEmptyIceCandidatePairArray(),
		localAudioTrackStats: {},
		remoteAudioTrackStats: {},
		localVideoTrackStats: {},
		remoteVideoTrackStats: {},
	};
}
