/**
 * @copyright Copyright 2021-2023 Epic Systems Corporation
 * @file hook to get functions to manage screen sharing track
 * @author Colin Walters
 * @module Epic.VideoApp.Hooks.LocalTracks.UseScreenShareTrackActions
 */

import { useDispatch } from "@epic/react-redux-booster";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { IScreenShareAuditEvent, useAuditFeatureUse, useStrings } from "~/hooks";
import { alertActions, useUserState } from "~/state";
import { EventFlag, EventType } from "~/types";
import { getDisplayMedia } from "~/utils/screenSharing";
import { VideoContext } from "~/web-core/components";
import { useIsScreenShareSupported } from "~/web-core/hooks/useIsScreenShareSupported";
import { useStream } from "~/web-core/hooks/useStream";
import { ILocalUser } from "~/web-core/interfaces";

interface IScreenShareTrackActions {
	/** Present a dialog to the user to allow them to choose content to share with other participants and publish the selected track */
	requestScreenShareTrack: (replaceTrack?: boolean) => Promise<void>;

	/** Remove the user's screen sharing track */
	removeScreenShareTrack: () => Promise<void>;
}

/**
 * Get functions to manipulate screen sharing for the local participant
 * @returns an object containing a function to start and stop screen sharing
 */
export function useScreenShareTrackActions(): IScreenShareTrackActions {
	const screenShareLock = useUserState((selectors) => selectors.getScreenShareLock(), []);
	const { session } = useContext(VideoContext);
	const localUser = session?.localUser;
	const localShareStream = useStream<ILocalUser>(localUser, "screen");
	const auditFeatureUse = useAuditFeatureUse();
	const dispatch = useDispatch();
	const screenShareSupport = useIsScreenShareSupported();

	const tokenNames = ["ProblemSharingScreen"];
	const strings = useStrings("useScreenShareTrackActions", tokenNames);

	const screenSharingTrackConstraints = useMemo(() => {
		return { audio: true, video: { frameRate: 10 } };
	}, []);

	const removeScreenShareTrack = useCallback(async () => {
		if (!localShareStream || !session) {
			return;
		}

		const hasAudio = localShareStream.hasAudio();
		try {
			await session.removeScreenShare(localShareStream, hasAudio);
		} catch (error) {
			console.warn(error);
		} finally {
			session?.localUser.cleanupShareStream();
		}

		// remove the track from state
		dispatch(alertActions.setBanner(null));

		// audit that the user stopped sharing
		// Include a screen share audio flag when also unpublishing an audio track
		const screenShareFlags: EventFlag[] = hasAudio ? [EventFlag.hasScreenShareAudio] : [];
		const screenShareEvent: IScreenShareAuditEvent = {
			feature: EventType.screenSharingStopped,
			eventFlags: screenShareFlags,
		};
		void auditFeatureUse([screenShareEvent]);
	}, [session, localShareStream, dispatch, auditFeatureUse]);

	const lockRef = useRef(screenShareLock);
	useEffect(() => {
		lockRef.current = screenShareLock;
	}, [screenShareLock]);

	const removeTrackRef = useRef(removeScreenShareTrack);
	useEffect(() => {
		removeTrackRef.current = removeScreenShareTrack;
	}, [removeScreenShareTrack]);

	const requestScreenShareTrack = useCallback(
		async (replaceTrack: boolean = false) => {
			if (!session || (localShareStream && !replaceTrack) || !screenShareSupport) {
				return;
			}

			if (localShareStream && replaceTrack) {
				await removeScreenShareTrack();
			}

			try {
				const mediaStream = await getDisplayMedia(screenSharingTrackConstraints);
				const videoStream = mediaStream.getVideoTracks()[0];
				const audioStream = mediaStream.getAudioTracks()[0] ?? undefined;

				// If access was revoked between calling requestScreenShareTrack and getDisplayMedia return,
				// stop screen share track and do not publish
				if (lockRef.current) {
					videoStream.stop();
					audioStream?.stop();
					return;
				}

				const shareStream = await session.localUser.createShareStream(videoStream, audioStream);

				// Listen for user stopping screen sharing through the browser itself
				// Wrap in a ref to avoid stale track state
				videoStream.onended = () => removeTrackRef.current();

				try {
					await session.addScreenShare(shareStream);
				} catch (error) {
					// Do not audit that we published a track when it failed
					session.localUser.cleanupShareStream();
					videoStream.stop();
					audioStream?.stop();
					return;
				}
				// Once a user starts sharing their screen, dismiss the toast to let them know they have
				// screen share access if it still exists
				dispatch(alertActions.postScreenShareStartedAlerts());

				// If access was revoked while the track was being published to the room, the local track state will not
				// have been set yet so removeScreenShareTrack will return early and cause the moderation state to be
				// misaligned. This is less ideal than the earlier accessRef check because the user will still initially
				// publish to the room, but this will make sure the moderator action is respected if it called in the narrow
				// race condition window
				if (lockRef.current) {
					void removeTrackRef.current();
				}

				// audit that the user started sharing their screen
				// Include a screen share audio flag when an audio track is also being published
				const screenShareFlags: EventFlag[] = audioStream ? [EventFlag.hasScreenShareAudio] : [];
				const screenShareEvent: IScreenShareAuditEvent = {
					feature: EventType.screenSharingStarted,
					eventFlags: screenShareFlags,
				};
				await auditFeatureUse([screenShareEvent]);

				return;
			} catch (error) {
				const logError = error as Error;
				// ignore when the user manually cancels out of the dialog
				if (logError.name !== "AbortError" && logError.name !== "NotAllowedError") {
					dispatch(alertActions.postGenericAlert(strings["ProblemSharingScreen"]));
				}
				session.localUser.cleanupShareStream();
			}
		},
		[
			session,
			localShareStream,
			screenShareSupport,
			removeScreenShareTrack,
			screenSharingTrackConstraints,
			dispatch,
			auditFeatureUse,
			strings,
		],
	);

	return {
		requestScreenShareTrack,
		removeScreenShareTrack,
	};
}
