/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Moderator button to remove a user from the call
 * @author Will Cooper
 * @module Epic.VideoApp.Components.Participants.Moderation.ModerationButtons.RemoveParticipantButton
 */

import { useDispatch } from "@epic/react-redux-booster";
import React, { FC, useCallback } from "react";
import BaseButton from "~/components/Utilities/BaseButton";
import { useStrings } from "~/hooks";
import Error from "~/icons/error";
import { alertActions, useRoomState } from "~/state";
import { AlertType, EpicUserType } from "~/types";
import { stringFormat } from "~/utils/strings";

interface IRemoveProps {
	identity: string;
	className: string;
	displayName: string;
	labelClassName?: string;
	iconClassName?: string;
}

/** String tokens used by the ParticipantControlMenu -> RemoveParticipantButton component */
enum TokenNames {
	removeParticipant = "RemoveParticipant",
	removeAlertText = "RemoveAlertText",
	removeAlertTextCareTeam = "RemoveAlertTextCareTeam",
	remove = "Remove",
	cancel = "Cancel",
}

const RemoveParticipantButton: FC<IRemoveProps> = (props: IRemoveProps) => {
	const { identity, className, displayName, labelClassName, iconClassName } = props;

	const dispatch = useDispatch();
	const strings = useStrings("ParticipantControlMenu", Object.values(TokenNames));
	const remoteUserType = useRoomState((selectors) => selectors.getRemoteUserType(identity), [identity]);

	const onRemoveClick = useCallback(
		(event?: React.MouseEvent<HTMLButtonElement>) => {
			// generate alert pop-up to confirm that the participant should be removed. That will be the source of the web request to remove them
			const alertToken =
				remoteUserType === EpicUserType.emp
					? TokenNames.removeAlertTextCareTeam
					: TokenNames.removeAlertText;
			dispatch(
				alertActions.postChoiceAlert({
					alertData: { identity },
					type: AlertType.removeParticipant,
					message: stringFormat(strings[alertToken], displayName),
					confirmText: strings[TokenNames.remove],
					confirmHotkey: "r",
					cancelText: strings[TokenNames.cancel],
					cancelHotkey: "c",
				}),
			);

			// stop propagation to avoid also triggering the onClick
			event?.stopPropagation();
		},
		[dispatch, displayName, identity, remoteUserType, strings],
	);

	return (
		<BaseButton
			className={className}
			icon={Error}
			text={strings[TokenNames.removeParticipant]}
			onClick={onRemoveClick}
			labelClassName={labelClassName}
			iconClassName={iconClassName}
		/>
	);
};

RemoveParticipantButton.displayName = "RemoveParticipantButton";

export default RemoveParticipantButton;
