/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file hook to toggle local screen share audio track mute/unmute
 * @author Trevor Roussel
 * @module Epic.VideoApp.Hooks.UseLocalScreenShareAudioToggle
 */

import { useCallback, useContext } from "react";
import { ToggleState } from "~/types";
import { boolMatchesToggleState } from "~/utils/general";
import { VideoContext } from "~/web-core/components";
import { useIsStreamEnabled } from "~/web-core/hooks/useIsStreamEnabled";
import { useStream } from "~/web-core/hooks/useStream";
import { ILocalUser } from "~/web-core/interfaces";

/**
 * Hook to get the status of if the local screen share audio track is enabled and toggle enable/disable
 * @returns tuple with the current enabled/disabled status and function to toggle that status
 */
export function useLocalScreenShareAudioToggle(): [boolean, (forceState?: ToggleState) => void] {
	const { session } = useContext(VideoContext);
	const shareStream = useStream<ILocalUser>(session?.localUser, "screen");
	const isSharingAudio = useIsStreamEnabled("audio", shareStream);

	const toggleShareAudioEnabled = useCallback(
		(forceState?: ToggleState) => {
			if (boolMatchesToggleState(isSharingAudio, forceState)) {
				return;
			}
			if (isSharingAudio) {
				shareStream?.toggleState("audio", false);
			} else {
				shareStream?.toggleState("audio", true);
			}
		},
		[isSharingAudio, shareStream],
	);

	return [isSharingAudio, toggleShareAudioEnabled];
}
