/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Returns whether the current browser matches the vendor's recommendations
 * @author Chance Overberg
 * @module Epic.VideoApp.WebCore.Hooks.UseIsVideoSupported
 */

import { isSupported } from "twilio-video";
import { useRoomState } from "~/state";
import { VideoVendor } from "../types";

export function useIsVideoSupported(): boolean {
	const vendor = useRoomState((selectors) => selectors.getVendor(), []);

	switch (vendor) {
		case VideoVendor.twilio:
			return isSupported;
		default:
			return false;
	}
}
